import jwtDecode from "jwt-decode";
import { forIn, sample, set } from "lodash";
import { RootState } from "../../app/store";
import { USER_DETAILS_VERSION } from "../../constants/user";
import { IManualEntry } from "../common/components/ManualExerciseEntryForm";
import { IEntry } from "../common/exerciseHelper";
import { SuggestedExercise, UserDetails, UserSignIn, UserState } from "./userSlice";

export interface JWToken {
  firstName: string;
  lastName: string;
  exp: number;
  email: string;
  user_id: number;
}

export interface RefreshTokenPayload {
  access: string;
}

export interface AuthenticatePayload extends RefreshTokenPayload {
  refresh: string;
}

export const isTokenExpired = (exp: number | undefined): boolean => {
  return exp === undefined || Math.round(Date.now() / 1000) >= exp;
};

export const isUserDetailsExpired = (
  details: UserDetails | undefined
): boolean => {
  return (
    details === undefined ||
    details.exp === undefined ||
    Math.round(Date.now() / 1000) >= details.exp ||
    details.version === undefined ||
    details.version < USER_DETAILS_VERSION
  );
};

export const getAuthToken = (state: RootState): string | undefined => {
  return state.user.signIn?.authToken;
};

export const isUserSessionExpired = (
  signIn: UserSignIn | undefined
): boolean => {
  return signIn === undefined || isTokenExpired(signIn.refreshExp);
};

export const isUserRegistered = (details?: UserDetails): boolean => {
  return details !== undefined && !!details.registered
}

export const getUserSignIn = ({
  access,
  refresh,
}: AuthenticatePayload): UserSignIn => {
  const {
    firstName,
    lastName,
    exp: authExp,
    email,
    user_id: userID,
  } = jwtDecode(access) as JWToken;
  const { exp: refreshExp } = jwtDecode(refresh) as JWToken;
  return {
    firstName,
    lastName,
    email,
    authToken: access,
    authExp,
    refreshToken: refresh,
    refreshExp,
    userID,
  };
};

export const getImpersonateRefresh = (refresh: string): UserSignIn => {
  const { exp: refreshExp } = jwtDecode(refresh) as JWToken
  return {
    refreshToken: refresh,
    refreshExp
  }
}

export const getRefreshSignIn = (
  state: UserState,
  { access }: RefreshTokenPayload
): UserSignIn | undefined => {
  if (state.signIn !== undefined) {
    const {
      firstName,
      lastName,
      exp: authExp,
      email,
      user_id: userID,
    } = jwtDecode(access) as JWToken;
    const { refreshToken, refreshExp } = state.signIn;
    return {
      firstName,
      lastName,
      email,
      authToken: access,
      authExp,
      refreshToken,
      refreshExp,
      userID,
    };
  }
  return undefined;
};

export const restoreState = (state: UserState, updateState: UserState) => {
  forIn(updateState, (value, key) => {
    if (key !== "details" || !isUserDetailsExpired(value as UserDetails)) {
      set(state, key, value);
    }
  });
  if (state.signUp && state.signUp.error) {
    state.signUp.error.invalidToken = undefined;
  }
  state.registerError = undefined
  state.processing = false;
  state.processedPassword = false;
};

export const getMaxHeartRate = (age?: number | null): number | undefined => {
  if (age) {
    return 220 - age;
  }
};

export const getBulkUpdateEntries = (entries: Required<IManualEntry>[]): IEntry[] => 
  entries.map(({ date, minutes }) => ({
    startDate: Math.round(date.startOf('D').add(12, 'h').utc().valueOf() / 1000),
    duration: minutes * 60
  }))

export const getNextSuggestionDue = (state: UserState): number | undefined => {
  if (state.details && state.details.suggestionFrequency) {
    return Date.now() + state.details.suggestionFrequency * 1000
  }
}

export const isSuggestionDue = (suggestionDue?: number): boolean => {
  return suggestionDue !== undefined && suggestionDue <= Date.now()
} 

export const getSuggestedExercise = (details?: UserDetails): SuggestedExercise|undefined => {
  const { suggestedExercises } = details || {}
  if (suggestedExercises && suggestedExercises.length > 0) {
    return sample(suggestedExercises)
  }
}

export const hasSuggestedExercises = (details?: UserDetails): boolean => {
  const { suggestedExercises } = details || {}
  return suggestedExercises !== undefined && suggestedExercises.length > 0
}