import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import LoadStates from "./components/LoadStates"
import { restoreUser, UserState, checkUser } from "./userSlice"

const LoadStatesConnect = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state: RootState) => state.user)
  const { pathname } = useAppSelector((state: RootState) => state.router.location)

  return (
    <LoadStates 
      user={user} 
      pathname={pathname}
      restoreUser={(user: UserState) => dispatch(restoreUser(user))}
      checkUser={() => dispatch(checkUser())}
    />
  )
}

export default LoadStatesConnect