import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import { pages } from '../../../constants/pages'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import FooterButtons from '../../common/components/FooterButtons'
import { getCenteredBreathingText, getCognitiveFooterButtons, getTotalMinutes } from '../../common/exerciseHelper'
import { getLastSelectedLevelAndSample, getRandomSample, getCurrent, isReadyToStart, getResults } from '../ciHelper'
import { CiExercises as CiExercisesType, CiUserExercise } from '../ciSlice'
import CiInProgress from './CiInProgress'

export interface ICiExercisesProps {
  status: ExerciseStatus
  exercises?: CiExercisesType
  userExercises: CiUserExercise[]
  startTime?: number
  endTime?: number
  startExercise: (level: number, sample?: number) => void
  endExercise: () => void
  next: () => void
  backTo: (route: string) => void
}

const CiExercises = ({ status, exercises, userExercises, startTime, endTime, startExercise, endExercise, next, backTo }: ICiExercisesProps) => {
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>(undefined)
  const [selectedSample, setSelectedSample] = useState<number | undefined>(undefined)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    const levelAndSample = getLastSelectedLevelAndSample(userExercises) 
    if (exercises && levelAndSample) {
      const { level, sample } = levelAndSample
      const nextSample = getRandomSample(exercises, level, sample)
      setSelectedLevel(level)
      setSelectedSample(nextSample)
      startExercise(level, nextSample)
    }
  }

  const handlePickLevel = (level: number) => {
    if (exercises) {
      window.scrollTo(0, 0)
      setSelectedLevel(level)
      const sample = getRandomSample(exercises, level)
      setSelectedSample(sample)
      startExercise(level, sample)  
    }
  }

  const handleFinishExercise = () => {
    window.scrollTo(0, 0)
    setSelectedLevel(undefined)
    endExercise()
  }

  const { currentExercise } = getCurrent(exercises, userExercises)

  const totalMinutes = getTotalMinutes(startTime, endTime)

  const results = getResults(status, userExercises)

  const completedExercises = currentExercise - 1
  
  return (
    <>
      <ExerciseIntro status={status}>
        <h4>Instructions</h4>
        <p>Color Illusion exercises are fun to do and are a good workout for your brain. In each activity set, you will pay attention to one task while trying not to pay attention to a competing task. Easier said than done! The exercises are randomized so you are presented with a different activity every time. In addition to reducing stress, Color Illusion can strengthen brain skills such as attention and concentration abilities.</p>
      </ExerciseIntro>
      <CiInProgress
        status={status}
        currentExercise={currentExercise}
        levels={exercises?.levels || []}
        selectedLevel={selectedLevel}
        selectedSample={selectedSample}
        pickLevel={handlePickLevel}
        finishExercise={handleFinishExercise}
      />
      <ExerciseComplete status={status} category="cognitive" totalMinutes={totalMinutes} completedExercises={completedExercises}>
        <h4>Result</h4>
        {results.map((result, i) => (
          <div key={`sec-${i}`}>
            <h5>Exercise {results.length - i}</h5>
            <Table striped>
              <tbody>
                <tr><td>Level</td><td className="text-right">{result.level}</td></tr>
                <tr><td>Time</td><td className="text-right">{result.time}</td></tr>
              </tbody>
            </Table>
          </div>
        ))}
      </ExerciseComplete>
      <FooterButtons
        extraText={getCenteredBreathingText(status)}
        hidden={status === 'in-progress'}
        buttons={getCognitiveFooterButtons(
          status,
          pages.colorIllusion.title!,
          isReadyToStart(status, exercises),
          backTo,
          handleNext,
          handleRepeat,
          'Go to the next exercise',
          'Choose a different level'
        )}
      />
    </>
  )
}

export default CiExercises