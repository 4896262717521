import { CeState } from "./ceSlice"

export const moveNext = (state: CeState, repeat?: boolean) => {
  const { status, exercises } = state
  if (exercises.length) {
    if (status === 'start') {
      state.durations.push(0)
      state.status = 'in-progress'
      return
    }
    if (status === 'completed') {
      if (!repeat) {
        quitExercise(state)
      } else {
        state.durations.push(0)
        state.status = 'in-progress'    
        state.selectedExercise = undefined    
      }
      return
    }
    if (status === 'in-progress') {
      state.status = 'completed'
      return
    }
  }
}

export const quitExercise = (state: CeState) => {
  state.status = 'start'
  state.id = undefined
  state.exercises = []
  state.selectedExercise = undefined
  state.durations = []
  state.startDate = undefined
}

export const updateDuration = (state: CeState, value: number) => {
  if (state.durations.length) {
    state.durations[state.durations.length - 1] = value
  }
}
