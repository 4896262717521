import React, { useState } from 'react'
import { Button } from 'antd'
import { Col, Row, Container } from 'react-bootstrap'
import FooterButtons from '../../common/components/FooterButtons'

export interface ICiLevelChooserProps {
  hidden?: boolean
  levelCount: number
  pickLevel: (level: number) => void
}

const CiLevelChooser = ({ hidden, levelCount, pickLevel }: ICiLevelChooserProps) => {
  const [level, setLevel] = useState<number | undefined>(undefined)

  if (hidden) { return null }

  const readyToSubmit = level !== undefined

  const handleNext = () => {
    if (readyToSubmit) {
      pickLevel(level!)
      setLevel(undefined)
    }
  }

  const levelCols = Array.from(Array(levelCount).keys()).map(index => {
    const buttonType = index === level ? 'primary' : 'default'
    return (
      <Col xs={12} sm={6} md={4} lg={3} key={`level-${index}`}>
        <Button size="large" block type={buttonType} onClick={() => setLevel(index)}>Level {index + 1}</Button>
      </Col>
    )
  })

  return (
    <>
      <Container>
        <h5>Choose Level</h5>
        <p>Each level is progressively more challenging.  We suggest that you complete two levels each time you do Color Illusion.</p>
        <Row>
          {levelCols}
        </Row>
      </Container>
      <FooterButtons 
        buttons={[{ disabled: !readyToSubmit, label: "Next", action: handleNext, buttonType: "primary" }]}
      />
    </>
  )
}

export default CiLevelChooser