import React, { useState } from 'react'
import { Button, Modal } from 'antd'

interface ISpanishPopupProps {
  title: string
  children?: React.ReactNode 
}

const SpanishPopup = ({ title, children }: ISpanishPopupProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <>
      <Button type="default" block className="secondary" onClick={() => setShowModal(true)} >{title}</Button>
      <Modal 
        title={title}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={800}
      >
        {children}
      </Modal>
    </>
  )
}

export default SpanishPopup