import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Empty, Progress, Skeleton } from 'antd'
import { GoalDetails } from "../goalSlice"
import { getGoalAndArchived, hasGoal as hasGoalHelper } from '../goalHelper'
import VisibilitySensor from 'react-visibility-sensor'
import pluralize from 'pluralize'
export interface IGoalProgressProps {
  goalDetails?: GoalDetails | null;
}

const GoalProgress = ({ goalDetails }:IGoalProgressProps) => {
  const [showPercentage, setShowPercentage] = useState<number>(0)
  const { type, goal, archived, percentage } = getGoalAndArchived(goalDetails!)

  useEffect(() => {
    if (percentage !== undefined) {
      setTimeout(() => {
        setShowPercentage(percentage)
      }, 500)
    }
  }, [percentage])

  if (goalDetails === undefined) {
    return (<Skeleton active />)
  }

  const hasGoal = hasGoalHelper(goalDetails)

  if (!hasGoal) {
    return (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)
  }

  const label = pluralize(type === 'count' ? 'exercise' : 'minute', archived)


  const onChangeProgressVisible = (isVisible: boolean) => {
    if (isVisible && percentage) {
      setTimeout(() => {
        setShowPercentage(percentage)
      }, 500)
    }
  }

  return (
    <VisibilitySensor onChange={onChangeProgressVisible} >
      <Row className="my-4 align-items-center">
        <Col>
          <Progress className="float-right" type="circle" percent={showPercentage} format={percentage => `${percentage}%`} />
        </Col>
        <Col>
          <p className="my-4"><span className="h3">{archived}</span><span className="h5">/{goal}</span><br />{label} completed</p>
        </Col>
      </Row>
    </VisibilitySensor>
  ) 
}

export default GoalProgress