import React from 'react'
import { ExerciseStatus } from '../../../constants/exercises'
import { CeExercise } from '../ceSlice'
import CeExerciseChooser from './CeExerciseChooser'
import CeExerciseDetail from './CeExerciseDetail'

export interface ICeInProgressProps {
  status: ExerciseStatus
  exercises: CeExercise[]
  selectedExercise?: string
  pickExercise: (value?: string) => void
  finishExercise: (duration: number, startDate?: number) => void
  updateDuration: (duration: number) => void
  goToManualEntry: () => void
}

const CeInProgress = ({ status, exercises, selectedExercise, pickExercise, finishExercise, updateDuration, goToManualEntry }: ICeInProgressProps) => {

  if (status !== 'in-progress') { return null }

  const hasSelectedExercise = selectedExercise !== undefined

  const exercise = exercises.find(exercise => exercise.slug === selectedExercise)

  return (
    <>
      <CeExerciseChooser
        hidden={hasSelectedExercise}
        exercises={exercises}         
        pickExercise={pickExercise} 
        goToManualEntry={goToManualEntry}
      />
      <CeExerciseDetail 
        exercise={exercise} 
        goBack={() => pickExercise(undefined)}
        doneExercise={finishExercise} 
        updateDuration={updateDuration} 
      />
    </>
  )
}

export default CeInProgress