import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import FooterButtons from '../../common/components/FooterButtons'
import { CbExercise } from '../cbSlice'
import ChoiceButton from '../../common/components/ChoiceButton'
import { setCommonMaxHeightButtons } from '../../common/exerciseHelper'
import { Button, Radio } from 'antd'
import { IButton } from '../../../constants/pages'
import styled from 'styled-components'
import { getNumOfVariants } from '../cbHelper'

const ExerciseTitle = styled.h6`
  color: #1178ba;
  cursor: pointer;
`

interface ICbExerciseChooserProps {
  hidden: boolean
  exercises: CbExercise[]
  selectedExercise?: CbExercise
  pickExercise: (value: string) => void
  goBack: () => void
  goToManualEntry: () => void
}

const CbExerciseChooser = ({
  hidden,
  exercises,
  selectedExercise,
  pickExercise,
  goBack,
  goToManualEntry,
}: ICbExerciseChooserProps) => {
  const [exerciseSlug, setExerciseSlug] = useState<string | undefined>(undefined)
  const [variantIndex, setVariantIndex] = useState<number>(0)
  const choices = useRef(null)

  useEffect(() => {
    setCommonMaxHeightButtons(choices.current)
  }, [exercises, hidden])

  useEffect(() => {
    const exercise = exercises.find(exercise => exercise.slug === exerciseSlug)
    if (exercise) {
      const maxVariantIndex = getNumOfVariants(exercise) - 1
      if (maxVariantIndex >= 0 && maxVariantIndex < variantIndex) {
        setVariantIndex(maxVariantIndex)
      }
    }      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseSlug, exercises])

  if (hidden) {
    return null
  }

  const getExerciseName = (exercise: CbExercise): JSX.Element => {
    if (exercise.preview) {
      return (
        <h6><strong>{exercise.name}</strong><br />Coming soon!</h6>
      )
    } 
    return <ExerciseTitle onClick={() => setExerciseSlug(exercise.slug)}><strong>{exercise.name}</strong></ExerciseTitle>
  }

  const exerciseCols = exercises.map((exercise) => {
    const buttonType = exercise.slug === exerciseSlug ? 'primary' : 'default'
    const variantChildren = getNumOfVariants(exercise) > 0 ? exercise.children : undefined
    return (
      <Col xs={12} lg={6} key={exercise.slug} className="mb-4">
        <Row>
          <Col xs={5}>
            <ChoiceButton disabled={exercise.preview} className="py-3" size="large" block type={buttonType} onClick={() => setExerciseSlug(exercise.slug)}>
              {exercise.poster && <Image draggable={false} src={`${APIMEDIA_HOST}${exercise.poster}`} fluid />}
            </ChoiceButton>
          </Col>
          <Col xs={7}>
            {getExerciseName(exercise)}
            {exercise.blob && <p>{exercise.blob}</p>}
            {variantChildren && (
              <Radio.Group onChange={(e) => setVariantIndex(e.target.value)} value={variantIndex} buttonStyle="solid" disabled={exercise.slug !== exerciseSlug}>
                {variantChildren.map((variant, index) => (
                  <Radio.Button key={variant.slug} value={index}>{variant.variant}</Radio.Button>
                ))}
              </Radio.Group>
            )}
          </Col>
        </Row>
      </Col>
    )
  })

  const readyToSubmit = exerciseSlug !== undefined

  const handleNext = () => {
    if (readyToSubmit && exerciseSlug) {
      const exercise = exercises.find(exercise => exercise.slug === exerciseSlug)
      if (exercise) {
        if (getNumOfVariants(exercise) > 0) {
          const variant = exercise.children[variantIndex]
          pickExercise(variant.slug)          
        } else {
          pickExercise(exerciseSlug)
        }
        setExerciseSlug(undefined)
        setVariantIndex(0)
      }
    }
  }

  const handleGoBack = () => {
    if (selectedExercise) {
      goBack()
    }
  }

  const footerButtons: IButton[] = [
    {
      disabled: !readyToSubmit,
      label: "Next",
      action: handleNext,
      buttonType: "primary",
    }
  ]

  if (selectedExercise) {
    footerButtons.push({
      label: "Go back",
      action: handleGoBack,
    })
  }

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            {selectedExercise && (              
              <>
                <h4>{selectedExercise.name}</h4>
                {selectedExercise?.description && <p>{selectedExercise.description}</p> }
              </>
            )}
            {!selectedExercise && <h5>Choose a Centered Breathing Exercise </h5>}
          </Col>
          <Col>
            <Button onClick={goToManualEntry} className="float-right" type="primary">Manual Entry</Button>
          </Col>
        </Row>
        <Row ref={choices}>{exerciseCols}</Row>
        {!selectedExercise && <>
          <p>In the <em>15 for Me&reg;</em> program, you can choose from a menu of different centered breathing activities.&nbsp; Each exercise will teach you how to slow down your breath, lowering stress. If you don&rsquo;t have a lot of experience with centered breathing or just want to brush up on the basics, start with <strong>Breathing 1-2-3</strong>. Once you have mastered the basic level, you can try the other options.</p>
          <p>To ensure that you have a unique experience that meets your needs, we will continue to add a variety of different centered breathing exercises to our platform.&nbsp; Try them all!</p>
        </>}
      </Container>
      <FooterButtons
        buttons={footerButtons}
      />
    </>
  )
}

export default CbExerciseChooser
