import { GoalDetails, MeasureType, Weekly, GoalState } from "./goalSlice"
import { reduce, some, uniq } from 'lodash'
import moment from 'moment'

const DATE_OF_WEEK_FORMAT = 'YYYY-MM-DD'

export const hasGoal = (goalDetails?: GoalDetails | null): boolean => {
  return !!goalDetails && !!goalDetails.type && !!goalDetails.goal
}

export type GoalCategory = 'cognitive' | 'cardio' | 'centeredBreathing' | 'total'

export const getWeeklyArchived = (type: MeasureType, weekly: Weekly, category?: GoalCategory): number => {
  category = category || 'total'
  return reduce(weekly, (acc, dayOfweek) => dayOfweek && !!dayOfweek[category!][type] ? acc + dayOfweek[category!][type]! : acc, 0)
}

export interface ThreeCNumbers {
  cognitive: number
  cardio: number
  centeredBreathing: number
}

export const getWeeklyThreeCArchived = (type: MeasureType, weekly: Weekly): ThreeCNumbers => {
  const cognitive = getWeeklyArchived(type, weekly, 'cognitive')
  const cardio = getWeeklyArchived(type, weekly, 'cardio')
  const centeredBreathing = getWeeklyArchived(type, weekly, 'centeredBreathing')
  return { cognitive, cardio, centeredBreathing }
}

export const hasNoActivitiesThisWeek = (goalDetails: GoalDetails | null): boolean => {
  if (goalDetails && goalDetails.weekly) {
    if (some(goalDetails.weekly, day => !!day?.exercises?.length)) {
      return false
    }
  }
  return true
}

export interface GoalAndArchived {
  type: MeasureType
  goal?: number
  archived: number
  percentage?: number
  threeCArchived?: ThreeCNumbers
  threeCPercentage?: ThreeCNumbers
}

export const getGoalAndArchived = (goalDetails?: GoalDetails): GoalAndArchived => {
  if (goalDetails && goalDetails.goal) {
    const type = goalDetails.type!
    const goal = goalDetails.goal![type]   
    const archived = getWeeklyArchived(type, goalDetails.weekly)
    const percentage = goal ? Math.round((archived / goal) * 100) : undefined
    const threeCArchived = getWeeklyThreeCArchived(type, goalDetails.weekly)
    const threeCPercentage = goal ? {
      cognitive: Math.round((threeCArchived.cognitive / goal) * 100),
      cardio: Math.round((threeCArchived.cardio / goal) * 100),
      centeredBreathing: Math.round((threeCArchived.centeredBreathing / goal) * 100)
    } : undefined
    return { type, goal, archived, percentage, threeCArchived, threeCPercentage }
  }
  return { type: 'count', archived: 0 }
}

export const isCurrentWeek = (goalDetails: GoalDetails): boolean => {
  const currentFirstDayOfWeek = moment().weekday(0)
  const goalFirstDayOfWeek = moment(goalDetails.dateOfWeek).weekday(0)
  return goalFirstDayOfWeek.isSame(currentFirstDayOfWeek, 'day')
}

export const getCurrentWeek = (): string => {
  return moment().weekday(0).format(DATE_OF_WEEK_FORMAT)
}

export const getSelectedGoalDetails = (state: GoalState, current?: boolean): GoalDetails|undefined|null => {
  if (current) {
    return state.details[getCurrentWeek()]
  }
  if (state.selectedDateOfWeek) {
    return state.details[state.selectedDateOfWeek]
  }
}

export const getPrevWeek = (currentWeek?: string): string => {
  const baseWeek = currentWeek || getCurrentWeek()
  return moment(baseWeek).subtract(1, 'w').format(DATE_OF_WEEK_FORMAT)
}

export const getNextWeek = (currentWeek?: string): string => {
  const baseWeek = currentWeek || getCurrentWeek()
  return moment(baseWeek).add(1, 'w').format(DATE_OF_WEEK_FORMAT)
}

export const getDateOfWeek = (date?: number): string|undefined => {
  if (date !== undefined) {
    return moment(date * 1000).startOf('w').format(DATE_OF_WEEK_FORMAT)
  }
}

export const getDatesOfWeek = (dates: number[]): string[] => uniq(dates.map(getDateOfWeek)) as string[]
