import React from 'react'
import { range } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import { Skeleton } from 'antd'

interface ILoadingProps {
  count?: number
}

const Loading = ({ count }: ILoadingProps) => {
  const realCount = count || 4

  return (
    <Container>
      <Row>
        <Col>
          {range(0, realCount).map((index) => 
            <Skeleton key={index} active />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default Loading