import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import { updateUserDetails, UserDetails } from '../user/userSlice'
import Welcome from './Welcome'

const WelcomeConnect = () => {
  const dispatch = useAppDispatch()
  const { details } = useAppSelector((state: RootState) => state.user)  
  const skip = details?.skipWelcome || false

  const handleSetSkip = (skipWelcome: boolean) => {
    const userDetails: UserDetails = { skipWelcome }
    dispatch(updateUserDetails(userDetails))
  }

  return (
    <Welcome 
      goToHome={() => dispatch(push(pages.home.route))} 
      skip={skip} 
      setSkip={handleSetSkip} 
    />
  )
}

export default WelcomeConnect