import styled, { keyframes } from "styled-components";
import { ImSpinner8 } from "react-icons/im";

const spinner = keyframes`
  from {
   -webkit-transform:rotate(0deg);
   transform:rotate(0deg)
  }
  to {
   -webkit-transform:rotate(1turn);
   transform:rotate(1turn)
  }
`;

const LoadingSpinner = styled(ImSpinner8)`
  animation: ${spinner} 2s linear infinite;
`;

export default LoadingSpinner;