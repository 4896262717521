import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { goBack } from 'connected-react-router'
import { Radio, RadioChangeEvent, Slider, Button, Card, message } from 'antd'
import { GoalDetails, MeasureType, goalLimits, Measure } from '../goalSlice'
import { PlusCircleFilled, MinusCircleFilled, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../../app/hooks'

export interface IUpdateGoalProps {
  goalDetails?: GoalDetails | null
  saveGoal: (type: MeasureType, goal: Measure) => void
}

const UpdateGoal = ({ goalDetails, saveGoal }: IUpdateGoalProps) => {
  const defaultType = goalDetails?.type || 'count'
  const defaultCount = goalDetails?.goal?.count || goalLimits.count.default
  const defaultMinutes = goalDetails?.goal?.minutes || goalLimits.minutes.default

  const [type, setType] = useState<MeasureType>(defaultType)
  const [count, setCount] = useState<number>(defaultCount)
  const [minutes, setMinutes] = useState<number>(defaultMinutes)
  const [saved, setSaved] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const title = 'Set Weekly Goal'

  const disabled = goalDetails === undefined

  useEffect(() => {
    if (goalDetails) {
      setType(goalDetails.type || 'count')
      setCount(goalDetails.goal?.count || goalLimits.count.default)
      setMinutes(goalDetails?.goal?.minutes || goalLimits.minutes.default)
    }
  }, [goalDetails])

  useEffect(() => {
    if (saved && goalDetails) {
      setSaved(false)
      message.success('Goal saved!')
      dispatch(goBack())
    }
  }, [saved, goalDetails, dispatch])

  const handleChooseType = (e: RadioChangeEvent) => {
    setType(e.target.value as MeasureType)
  }

  const countSlider = (
    <Slider
      key="count-slider"
      min={goalLimits.count.min}
      max={goalLimits.count.max}
      marks={{ [goalLimits.count.min]: `${goalLimits.count.min}`, [goalLimits.count.max]: `${goalLimits.count.max}` }}
      tipFormatter={(value) => `${value} exercises/week`}
      value={count}
      tooltipVisible={false}
      onChange={(value: number) => setCount(value)}
      disabled={disabled}
    />
  )

  const minutesSlider = (
    <Slider
      key="minutes-slider"
      min={goalLimits.minutes.min}
      max={goalLimits.minutes.max}
      marks={{ [goalLimits.minutes.min]: `${goalLimits.minutes.min}`, [goalLimits.minutes.max]: `${goalLimits.minutes.max}` }}
      tipFormatter={(value) => `${value} minutes/week`}
      value={minutes}
      tooltipVisible={false}
      onChange={(value: number) => setMinutes(value)}
      disabled={disabled}
    />
  )

  const slider = type === 'count' ? countSlider : minutesSlider

  const handleRevert = () => {
    dispatch(goBack())
  }

  const handleSave = () => {
    if (type) {
      saveGoal(type, { count, minutes })
      setSaved(true)
    }
  }

  const handleIncreaseDecrease = (value: number) => {
    if (!disabled) {
      if (type === 'count') {
        const nextValue = count + value
        if (goalLimits.count.min <= nextValue && nextValue <= goalLimits.count.max) {
          setCount(nextValue)
        }
      } else {
        const nextValue = minutes + value
        if (goalLimits.minutes.min <= nextValue && nextValue <= goalLimits.minutes.max) {
          setMinutes(nextValue)
        }
      }
    }
  }

  const label = type === 'count' ? 'exercise/week' : 'minutes/week'

  const value = type === 'count' ? count : minutes

  const plusButton = !disabled ? (
    <PlusCircleFilled style={{ fontSize: "40px" }} className="float-left" onClick={() => handleIncreaseDecrease(1)} />
  ) : (
    <PlusCircleOutlined style={{ color: "rgba(0,0,0,0.25", fontSize: "40px" }} className="float-left" />
  )

  const minusButton = !disabled ? (
    <MinusCircleFilled style={{ fontSize: "40px" }} className="float-right" onClick={() => handleIncreaseDecrease(-1)} />
  ) : (
    <MinusCircleOutlined style={{ color: "rgba(0,0,0,0.25", fontSize: "40px" }} className="float-right" />
  )

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <h3>{title}</h3>
          <Row>
            <Col>
              <Radio.Group disabled={disabled} style={{ width: "100%" }} value={type} size="large" onChange={handleChooseType} buttonStyle="solid">
                <Radio.Button style={{ width: "50%" }} value="count">Exercise Count</Radio.Button>
                <Radio.Button style={{ width: "50%" }} value="minutes">Total Minutes</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Card className="mt-3 height-auto">
            <Row>
              <Col>
                <p className="text-center"><span className="h2">{value}</span>{' '}{label}</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs={2}>
                {minusButton}
              </Col>
              <Col xs={8}>
                {slider}
              </Col>
              <Col xs={2}>
                {plusButton}
              </Col>
            </Row>
          </Card>
          <Row className="mt-3">
            <Col>
              <Button disabled={disabled} block type="default" size="large" onClick={handleRevert}>Cancel</Button>
            </Col>
            <Col>
              <Button loading={disabled} disabled={disabled} block type="primary" size="large" onClick={handleSave}>Save</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default UpdateGoal