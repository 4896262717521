import { AxiosResponse } from "axios"
import { get } from "lodash"
import { all, call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects"
import { QueryParams } from "../api/ApiManager"
import { 
  fetchAppUtilization,
  fetchCategoryUtilization,
  fetchGoalUtilization,
  fetchManagersVsNonManagers,
  fetchRawData,
  fetchReportOptions,
  fetchThreeCsUtilization
} from "../api/PortalAdminApiManager"
import { RootState } from "../app/store"
import { getQueryParams } from "../features/portalAdmin/portalAdminHelper"
import {
  FilterOptions,
  getFilterOptions,
  installFilterOptions,
  Charts, installCharts,
  getCharts,
  startFetchingCharts,
  getRawData,
  cancelGetRawData,
  startFetcchingRawData,
  installRawData
} from "../features/portalAdmin/portalAdminSlice"
import { getAuthToken } from "../features/user/userHelper"

function* processGetFilterOptions() {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    try {
      const response = (yield call(fetchReportOptions, authToken)) as AxiosResponse
      const options: FilterOptions = get(response, 'data')
      yield put(installFilterOptions(options))
    } catch (err: any) {
      if (err.response.status >= 500) {
        yield put(installFilterOptions({ error: true }))
      }
    }
  }
}

// function* processFetchAllChartsOneShot() {
//   const state: RootState = yield select()
//   const authToken = getAuthToken(state)
//   if (authToken !== undefined) {
//     const abortController = new AbortController();
//     const queryParams = getQueryParams(state.portalAdmin.filters)
//     yield put(startFetchingCharts())
//     try {
//       const response = (yield call(fetchAllCharts, authToken, queryParams, abortController.signal)) as AxiosResponse
//       const charts: Charts = get(response, 'data')
//       yield put(installCharts(charts))
//     } catch (err: any) {
//       if (err.response.status >= 500) {
//         yield put(installCharts({ error: true }))
//       }
//     } finally {
//       if ((yield cancelled()) as boolean) {
//         abortController.abort();
//       }
//     }
//   }
// }

function* processFetchOneChart(
  fetchCall: (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal) => Promise<AxiosResponse>,
  authToken: string,
  queryParams?: QueryParams,
  cancelSignal?: AbortSignal
) {
  try {
    const response = (yield call(fetchCall, authToken, queryParams, cancelSignal)) as AxiosResponse
    const charts: Charts = get(response, 'data')
    yield put(installCharts(charts))
  } catch (err: any) {
    if (err.response.status >= 500) {
      yield put(installCharts({ error: true }))
    }
  }
}

function* processFetchAllChartsInParallel() {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const abortController = new AbortController()
    const { signal } = abortController
    const queryParams = getQueryParams(state.portalAdmin.filters)
    yield put(startFetchingCharts())
    try {
      yield all([
        processFetchOneChart(fetchAppUtilization, authToken, queryParams, signal),
        processFetchOneChart(fetchManagersVsNonManagers, authToken, queryParams, signal),
        processFetchOneChart(fetchThreeCsUtilization, authToken, queryParams, signal),
        processFetchOneChart(fetchCategoryUtilization, authToken, queryParams, signal),
        processFetchOneChart(fetchGoalUtilization, authToken, queryParams, signal),
      ])
    } finally {
      if ((yield cancelled()) as boolean) {
        abortController.abort()
      }
    }
  }
}

function* processFetchRawData() {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const abortController = new AbortController()
    const { signal } = abortController
    const queryParams = getQueryParams(state.portalAdmin.filters)
    yield put(startFetcchingRawData())
    try {
      const response = (yield call(fetchRawData, authToken, queryParams, signal)) as AxiosResponse
      const rawData: Blob = get(response, 'data')
      yield put(installRawData(rawData))      
    } catch (err: any) {
      if (err.response.status >= 500) {
        yield put(installCharts({ error: true }))
      }
    } finally {
      if ((yield cancelled()) as boolean) {
        abortController.abort()
      }
    }
  }
}

function* rawDataRace() {
  yield race([
    call(processFetchRawData),
    take(cancelGetRawData),
  ])
}

export function* portalAdminWatch() {
  yield takeLatest(getFilterOptions, processGetFilterOptions)
  yield takeLatest(getCharts, processFetchAllChartsInParallel)
  yield takeLatest(getRawData, rawDataRace)
}
