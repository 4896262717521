import { push } from 'connected-react-router';
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { pages } from '../../constants/pages';
import ForgetPassword from './components/ForgetPassword';
import { forgetPassword, resetProcessedPassword } from './userSlice';

const ForgetPasswordConnect = () => {
  const dispatch = useAppDispatch();
  const { processing, processedPassword } = useAppSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(resetProcessedPassword())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleForgetPassword = (email: string) => {
    dispatch(forgetPassword(email))
  }

  const handleGoToSignIn = () => {
    dispatch(push(pages.signIn.route))    
  }

  return (
    <ForgetPassword
      processing={processing}
      processed={processedPassword || false}
      forgetPassword={handleForgetPassword}
      goToSignIn={handleGoToSignIn}
    />
  );
};

export default ForgetPasswordConnect;