import { replace } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import { isUserDetailsExpired } from '../user/userHelper'
import { getUserDetails } from '../user/userSlice'
import Loading from './components/Loading'
import { get } from 'lodash'

const LoadingConnect = () => {
  const dispatch = useAppDispatch()
  const { details, signIn } = useAppSelector((state: RootState) => state.user)
  const { location } = useAppSelector((state: RootState) => state.router)
  const authToken = signIn?.authToken

  useEffect(() => {
    if (!isUserDetailsExpired(details)) {
      const next = get(location, 'query.next') as string|undefined
      if (next) {
        dispatch(replace(next))        
      } else {
        const skipWelcome = details?.skipWelcome || false
        if (skipWelcome) {
          dispatch(replace(pages.home.route))
        } else {
          dispatch(replace(pages.welcome.route))
        }  
      }
    } else {
      dispatch(getUserDetails())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, authToken])

  return (
    <Loading />
  )
}

export default LoadingConnect