import { sampleSize, max, filter } from 'lodash'
import { ExerciseStatus } from "../../constants/exercises"
import { FtiExercise, FtiState, FtiUserAnswer, FtiExerciseSet } from "./ftiSlice"

const CHOICES = 'ABC'

export const getChoiceLabel = (index: number): string => CHOICES.charAt(index)

export interface Current {
  currentExerciseNumber: number
  currentSet: number
  currentExercise?: FtiExercise
  currentExerciseSet?: FtiExerciseSet  
}

export function getCurrent(exercises: FtiExercise[], userAnswers: FtiUserAnswer[]): Current {
  let currentExerciseNumber: number = userAnswers.length
  let currentSet: number = 0
  let currentExercise = undefined
  let currentExerciseSet = undefined
  if (currentExerciseNumber > 0) {
    const { answers, selectedQuestions, level } = userAnswers[userAnswers.length - 1]
    if (answers.length < selectedQuestions.length) {
      currentSet = answers.length + 1
      currentExercise = getExercise(exercises, level)
      const currentSelectedQuestion = selectedQuestions[answers.length]
      currentExerciseSet = getExerciseSet(exercises, level, currentSelectedQuestion)
    } else {
      currentExerciseNumber += 1
    }
  } else {
    currentExerciseNumber += 1
  }
  return { currentExerciseNumber, currentSet, currentExercise, currentExerciseSet }
}


export const updateAnswer = (answer: number, state: FtiState): void => {
  const { userAnswers, exercises } = state
  const answersLength = userAnswers.length
  const answerIndex = answersLength - 1
  const userAnswer = userAnswers[answerIndex]
  const exercise = getExercise(exercises, userAnswer.level) 
  if (exercise !== undefined) {
    if (userAnswer.answers.length < exercise.numOfQuestions) {
      userAnswer.answers.push(answer)
    }
  }
}

export interface FtiSetResult {
  level: number
  sets: boolean[]
  percentage: number
}

export interface FtiResults {
  maxSet: number
  results: FtiSetResult[]
}


export const getResults = (status: ExerciseStatus, exercises: FtiExercise[], userAnswers: FtiUserAnswer[]): FtiResults => {
  let results: FtiSetResult[] = []
  let maxSet = 0
  if (status === 'completed' || status === 'check-point') {
    results = userAnswers.map((userAnswer) => {
      const { level } = userAnswer
      const selectedQuestions = getSelectedQuestions(userAnswer, exercises)
      const sets = selectedQuestions.map((set, j) => set.correctAnswer === userAnswer.answers[j])
      const correctCount = sets.reduce((acc, set) => set ? acc + 1 : acc , 0)
      const percentage = Math.round(correctCount * 100 / sets.length)
      return { level, sets, percentage }
    })
    results.reverse()  
    maxSet = max(userAnswers.map((userAnswer) => userAnswer.answers.length)) || 0
  }
  return { maxSet, results }
}

export const getExercise = (exercises: FtiExercise[], level?: number): FtiExercise | undefined => {
  return exercises.find(exercise => exercise.level === level)
}

export const getLevels = (exercises: FtiExercise[]): number[] => {
  return filter(exercises, exercise => exercise.sets.length > 0).map(exercise => exercise.level)
}

export const getRandomQuestions = (exercises: FtiExercise[], level: number): number[] => {
  const exercise = getExercise(exercises, level)
  if (exercise !== undefined) {
    return sampleSize(Array.from(exercise.sets.keys()), exercise.numOfQuestions)
  }
  return []
}

export const getExerciseSet = (exercises: FtiExercise[], level: number, index: number): FtiExerciseSet | undefined => {
  const exercise = getExercise(exercises, level)
  if (exercise !== undefined) {
    return exercise.sets[index]
  }
  return undefined
}

export const getSelectedQuestions = (userAnswer: FtiUserAnswer, exercises: FtiExercise[]): FtiExerciseSet[] => {
  const { level, selectedQuestions } = userAnswer
  const exercise = getExercise(exercises, level)
  if (exercise !== undefined) {
    return selectedQuestions.map((index) => exercise.sets[index])
  }
  return []
}

export const startExercise = (state: FtiState, level?: number) => {
  const { userAnswers, exercises } = state
  const answersLength = userAnswers.length
  if (answersLength === 0) {
    if (level !== undefined) {
      userAnswers.push({ 
        level,
        selectedQuestions: getRandomQuestions(exercises, level),
        answers: [] 
      })
    }
    return
  }
  const answersIndex = answersLength - 1
  const userAnswer = userAnswers[answersIndex]
  const nextLevel = level || userAnswer.level
  if (userAnswer.answers.length === userAnswer.selectedQuestions.length) {
    userAnswers.push({ 
      level: nextLevel, 
      selectedQuestions: getRandomQuestions(exercises, nextLevel),
      answers: [],
    })
  }
}

export const moveNext = (state: FtiState, repeat?: boolean): void => {
  const { status, exercises, userAnswers } = state
  const lastUserAnswer = userAnswers[userAnswers.length - 1]
  if (status === 'start') {
    if (exercises.length) {
      state.startTime = Date.now()
      state.status = 'in-progress'
    }
    return
  }
  if (status === 'completed') {
    if (!repeat) {
      quitExercise(state)
    } else {
      state.status = 'in-progress'
    }
    return
  }
  if (status === 'check-point') {
    state.status = 'in-progress'
  }
  if (status === 'in-progress') {
    if (lastUserAnswer.answers.length === lastUserAnswer.selectedQuestions.length) {
      state.endTime = Date.now()
      state.status = 'check-point'
      return
    }
    state.endTime = Date.now()    
  }
}


export const quitExercise = (state: FtiState): void => {
  state.status = 'start'
  state.id = undefined
  state.exercises = []
  state.userAnswers = []
  state.startTime = undefined
  state.endTime = undefined
}
