import { Empty, Skeleton, Button, Card, DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { isCurrentWeek, hasGoal, hasNoActivitiesThisWeek, getPrevWeek, getNextWeek } from '../goalHelper'
import { GoalDetails } from '../goalSlice'
import GoalProgress from './GoalProgress'
import { map } from 'lodash'
import styled from 'styled-components'

export interface IGoalDetailsProps {
  firstWeek?: string
  goalDetails?: GoalDetails | null
  gotoSetGoal: () => void
  setDateOfWeek: (value: string) => void
}

const DatePickerWrapper = styled.span`
  .ant-picker-input input {
    display: none;
  }
`;

const GoalDetailsComponent = ({
  firstWeek,
  goalDetails,
  gotoSetGoal,
  setDateOfWeek
}: IGoalDetailsProps) => {
  let content: JSX.Element | null
  if (goalDetails === undefined) {
    content = <Skeleton active />
  } else {
    const currentWeek = isCurrentWeek(goalDetails!)
    const isFirstWeek = firstWeek === goalDetails!.dateOfWeek
    const firstDayOfWeek = moment(goalDetails!.dateOfWeek)
      .weekday(0)
      .format("MMM Do")
    const weekLabel = currentWeek ? "This Week" : `Week of ${firstDayOfWeek}`
    const weeklyArchived = map(goalDetails!.weekly, (dayOfWeek, key) => (
      <tbody key={`${key}-group`}>
        {dayOfWeek!.total.count > 0 ? (
          <tr className="total-row" key={`${key}-total`}>
            <th>
              {moment(goalDetails!.dateOfWeek).day(key).format("ddd, MMM Do")}
            </th>
            <th className="text-right">
              {dayOfWeek!.total.count}
            </th>
            <th className="text-right">
              {dayOfWeek!.total.minutes}
            </th>
          </tr>
        ) : null}
        {dayOfWeek!.cognitive.count > 0 ? (
          <tr key={`${key}-cognitive`}>
            <td>Cognitive Exercises</td>
            <td className="text-right">
              {dayOfWeek!.cognitive.count}
            </td>
            <td className="text-right">
              {dayOfWeek!.cognitive.minutes}
            </td>
          </tr>
        ) : null}
        {dayOfWeek!.cardio.count > 0 ? (
          <tr key={`${key}-cardio`}>
            <td>Cardio Exercises</td>
            <td className="text-right">
              {dayOfWeek!.cardio.count}
            </td>
            <td className="text-right">
              {dayOfWeek!.cardio.minutes}
            </td>
          </tr>
        ) : null}
        {dayOfWeek!.centeredBreathing.count > 0 ? (
          <tr key={`${key}-breathing`}>
            <td>Centered Breathing</td>
            <td className="text-right">
              {dayOfWeek!.centeredBreathing.count}
            </td>
            <td className="text-right">
              {dayOfWeek!.centeredBreathing.minutes}
            </td>
          </tr>
        ) : null}
      </tbody>
    ))

    const activityTable = hasNoActivitiesThisWeek(goalDetails) ? (<p>No exercises yet this week.</p>) : (
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th className="text-right"><span className="d-lg-none">Ex. Count</span><span className="d-none d-lg-block">Exercise Count</span></th>
            <th className="text-right"><span className="d-lg-none">Mins.</span><span className="d-none d-lg-block">Minutes</span></th>
          </tr>
        </thead>
        {weeklyArchived}
      </Table>
    )

    const goalIntro = (
      <>
        <p>Setting goals are an essential part of your wellness journey. By setting goals, you are better able to hold yourself accountable and can also help you make a longer-lasting change.</p>
        <p>Tips to get started:&nbsp;</p>
        <ol>
          <li>Start small and set achievable goals &ndash; By starting small and setting manageable goals, you are more likely to be successful.</li>
          <li>Work on one goal at a time &ndash; Work to reach each goal. This will keep you motivated and help you stay focused!</li>
          <li>Be patient and never give up &ndash; Starting on a journey of health and wellness will take time. Be patient with yourself as success takes time.&nbsp;</li>
        </ol>
      </>
    )

    const emptyGoal = (
      <>
        <Row>
          <Col>
            <Empty />
          </Col>
        </Row>
        {currentWeek && <Row className="mt-4 justify-content-center">
          <Col xs={8}>
            <Button block type="primary" size="large" onClick={gotoSetGoal}>
              Set Goal
            </Button>
          </Col>
        </Row>}
      </>
    )

    const goalContent = hasGoal(goalDetails) ? <GoalProgress goalDetails={goalDetails} /> : emptyGoal

    content = (
      <>
        <Row>
          <Col xs={currentWeek ? 8 : 12} lg={10}>
            <h5>
              <Button disabled={isFirstWeek} onClick={() => setDateOfWeek(getPrevWeek(goalDetails!.dateOfWeek))} size="small">&nbsp;&lt;&nbsp;</Button>
              <DatePickerWrapper>
                &nbsp;{' '}&nbsp;{weekLabel}
                <DatePicker
                  value={moment(goalDetails?.dateOfWeek)}
                  allowClear={false}
                  size='large'
                  inputReadOnly
                  bordered={false}
                  onChange={(value) => value && setDateOfWeek(value.format('YYYY-MM-DD'))}
                  disabledDate={(date: Moment): boolean => moment().isBefore(date) || moment(firstWeek).isAfter(date)}
                />
              </DatePickerWrapper>
              <Button disabled={currentWeek} onClick={() => setDateOfWeek(getNextWeek(goalDetails!.dateOfWeek))} size="small">&nbsp;&gt;&nbsp;</Button>
            </h5>
          </Col>
          {currentWeek && <Col xs={4} lg={2}>
            <Button block size="small" type="primary" onClick={gotoSetGoal}>
              Update Goal
            </Button>
          </Col>}
        </Row>
        <Row>
          <Col className="mb-3" xs={12} md={5}>
            <Card>
              {goalContent}
            </Card>
            <div className='my-3 d-none d-md-block'>{goalIntro}</div>
          </Col>
          <Col className="mb-3" xs={12} md={7}>
            {activityTable}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='my-3 d-md-none'>{goalIntro}</div>
          </Col>
        </Row>
      </>
    )
  }

  return <Container>{content}</Container>
}

export default GoalDetailsComponent
