import React, { useState, useRef } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { Radio, Button, Modal } from 'antd'
import { ExerciseStatus } from '../../../constants/exercises'
import FooterButtons from '../../common/components/FooterButtons'
import classnames from 'classnames'
import styled from 'styled-components'

export interface INsInProgressProps {
  status: ExerciseStatus
  currentExercise: number
  currentSet: number
  keys: string[]
  symbols: string[]
  questionSet: number[]
  answerSet: (string | undefined)[]
  pickAnswer: (index: number, symbol: string) => void
  next: () => void
}

const QuestionTable = styled(Table)`
  table-layout: fixed;
  width: ${props => props.numofitem * 50}px;
  .answers td {
    height: 49px;
    color: black;
    &.table-success {
      background-color: #c3e6cb;
    }  
  }  
`

const NsInProgress = ({
  answerSet,
  currentExercise,
  currentSet,
  keys,
  next,
  symbols,
  pickAnswer,
  questionSet,
  status
}: INsInProgressProps) => {
  const [answerIndex, setAnswerIndex] = useState<number>(0)
  const [showKeys, setShowKeys] = useState<boolean>(false)
  const questionTable = useRef<HTMLDivElement>(null)

  if (status !== 'in-progress') { return null }

  const keyItems = keys.map((key, index) => (
    <td className="text-dark text-center" key={index}>{key}</td>
  ))

  const keyTable = (
    <Table size="sm" bordered responsive>
      <tbody>
        <tr className="table-dark">
          { Array.from(Array(keys.length).keys()).map(index => (<td key={index} className="text-dark text-center">{index + 1}</td>)) }
        </tr>
        <tr className="table-dark">
          {keyItems}
        </tr>
      </tbody>
    </Table>
  )

  const setItems = questionSet.map((question, index) => <td key={index} className="text-center">{question}</td>)

  const setInputs = answerSet.map((answer, index) => {
    const classes = classnames('text-center', { 'table-success': index === answerIndex })
    return (
      <td key={index} className={classes} onClick={() => setAnswerIndex(index)}><strong>{answer}</strong></td>
    )
  })

  const handleNext = () => {
    setAnswerIndex(0)
    next()
  }

  const handleChooseSymbol = (symbol: string) => {
    pickAnswer(answerIndex, symbol)
    const newIndex = (answerIndex + 1) % questionSet.length
    setAnswerIndex(newIndex)
    const offSet = newIndex > 1 ? (newIndex - 2) * 49 : 0
    if (questionTable && questionTable.current) {
      questionTable.current.scrollTo({ left: offSet, top: 0, behavior: 'smooth' })
    }
  }

  const choiceRadios = symbols.map(symbol => (
    <Radio.Button
      onClick={() => handleChooseSymbol(symbol)}
      key={symbol}
      value={symbol}
    >
      {symbol}
    </Radio.Button>
  ))

  const setLabel = currentSet === 0 ? 'Practice' : `Set ${currentSet}`

  const showKeyButton = currentSet !== 0 ? (
    <>
      <Button className="mb-3" type="primary" block onClick={() => setShowKeys(true)}>Show Keys</Button>
      <Modal title="Keys" visible={showKeys} onCancel={() => setShowKeys(false)} footer={null}>
        {keyTable}
      </Modal>
    </>
  ) : null

  return (
    <>
      <Container>
        <h4>Exercise {currentExercise}</h4>
        <p>Look at the boxes below. In the first set (marked with the word “key”), you will notice that each number is paired with a specific symbol. In the next set of boxes (marked with the word “example”), only the numbers are listed. Select the corresponding symbols, working as quickly as you can without skipping any boxes. <strong>For this exercise, limit the number of times you look at the answer key.</strong></p>
        <Row className="mb-3">
          <Col>
            {currentSet === 0 && <><h5>Key</h5>{keyTable}</>}
            <Row className="mt-3">
              <Col xs={7}>
                <h5>{setLabel}</h5>
              </Col>
              <Col xs={5}>
                {showKeyButton}
              </Col>
            </Row>
            <div className="table-responsive" ref={questionTable}>
              <QuestionTable bordered numofitem={questionSet.length}>
                <tbody>
                  <tr key="questions" className="questions table-dark text-dark">{setItems}</tr>
                  <tr key="answers" className="answers table-dark">{setInputs}</tr>
                </tbody>
              </QuestionTable>
            </div>
            <h5>Choices</h5>
            <Radio.Group size="large" buttonStyle="solid">
              {choiceRadios}
            </Radio.Group>
          </Col>
        </Row>
      </Container>
      <FooterButtons
        buttons={[{ label: "Next", action: handleNext, buttonType: "primary" }]}
      />
    </>
  )
}

export default NsInProgress
