import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExerciseStatus } from "../../constants/exercises";
import { endExercise, moveNext, quitExercise, startExercise } from "./mmHelper";

export interface MmLevel {
  cards: string[]
}

export interface MmTheme {
  name: string
  slug: string
  image: string
  cardBack: string
  levels: MmLevel[]
}

export interface MmExercises {
  count: number
  themes: MmTheme[]
}

export interface MmUserScore {
  theme: string
  level: number
  finished?: boolean
  start: number
  end?: number
  attempts?: number
}

export interface MmState {
  status: ExerciseStatus
  id?: string
  exercises?: MmExercises
  userScores: MmUserScore[]
  startTime?: number
  endTime?: number
}

export interface MmStart {
  theme: string
  level: number
}

export interface MmResult {
  finished: boolean
  attempts: number
}

const initialState: MmState = {
  status: 'start',
  userScores: []
}

interface MmExercisesPayload {
  id: string
  exercises: MmExercises
}

export const mmSlice = createSlice({
  name: 'memoryMatch',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<MmExercisesPayload>) => {
      state.exercises = action.payload.exercises
      state.id = action.payload.id
    },
    next: (state) => {
      moveNext(state)  
    },
    start: (state, action: PayloadAction<MmStart>) => {
      const { theme, level } = action.payload
      startExercise(theme, level, state)
      moveNext(state, true)
    },
    end: (state, action: PayloadAction<MmResult>) => {
      const { attempts, finished } = action.payload
      endExercise(finished, attempts, state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('memoryMatch/requestQuit')

export const { installExercises, next, start, end, quit, reset } = mmSlice.actions

export default mmSlice.reducer
