import React from 'react'
import { Container } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import { getLevelAndSample } from '../ciHelper'
import { CiLevel } from '../ciSlice'
import CiGame from './CiGame'
import CiLevelChooser from './CiLevelChooser'

export interface ICiInProgressProps {
  status: ExerciseStatus
  currentExercise: number
  levels: CiLevel[]
  selectedLevel?: number
  selectedSample?: number
  pickLevel: (level: number) => void
  finishExercise: () => void
}

const CiInProgress = ({
  currentExercise,
  finishExercise,
  levels,
  pickLevel,
  selectedLevel,
  selectedSample,
  status,
}: ICiInProgressProps) => {
  if (status !== 'in-progress') { return null }

  const hasSelectedLevelAndSample = selectedLevel !== undefined && selectedSample !== undefined

  const { level, sample } = getLevelAndSample(levels, selectedLevel, selectedSample)

  return (
    <>
      <Container>
        <h4>Exercise {currentExercise}</h4>
      </Container>
      <CiLevelChooser
        hidden={hasSelectedLevelAndSample}
        levelCount={levels.length}
        pickLevel={pickLevel}
      />
      <CiGame
        level={level}
        sample={sample}
        gameCompleted={finishExercise}
      />
    </>
  )
}

export default CiInProgress