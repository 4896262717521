import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExerciseStatus } from "../../constants/exercises";
import { BaseExercise, BaseUserAnswer, moveNext, quitExercise } from "../common/exerciseHelper";
import { updateAnswer } from "./wtHelper";

export interface WtQuestionSet {
  words: string[]
  correctAnswer: string
}

export interface WtExercise extends BaseExercise<WtQuestionSet> {
  themes: string[]
}

export interface WtUserAnswer extends BaseUserAnswer<string> {}

export interface WtState {
  status: ExerciseStatus
  id?: string
  exercises: WtExercise[]
  userAnswers: WtUserAnswer[]
  startTime?: number
  endTime?: number
}

const initialState: WtState = {
  status: 'start',
  exercises: [],
  userAnswers: [],
}

interface WtExercisesPayload {
  id: string
  exercises: WtExercise[]
}

export const wtSlice = createSlice({
  name: 'wordTheme',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<WtExercisesPayload>) => {
      state.id = action.payload.id
      state.exercises = action.payload.exercises
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      moveNext(state, action.payload)
    },
    answer: (state, action: PayloadAction<string>) => {
      updateAnswer(action.payload, state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    }, 
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('wordTheme/requestQuit')

export const { installExercises, next, answer, quit, reset } = wtSlice.actions

export default wtSlice.reducer
