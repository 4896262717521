import React from 'react'
import { DefaultSubheader } from './DefaultSubheader'
import { ThreeCSubheader } from './ThreeCSubheader'

export interface ISubheaderProps {
  pageKey: string
  title: string
}

const Subheader = ({ pageKey, title }: ISubheaderProps) => {
  const threeCCategories : string[] = ['cognitiveExercises', 'cardioExercises', 'centeredBreathing']

  if (title === '') { return null }

  if (threeCCategories.indexOf(pageKey) >= 0) {
    return (
      <ThreeCSubheader pageKey={pageKey}  />
    )
  }

  return (
    <DefaultSubheader title={title} />
  )
}


export { Subheader }