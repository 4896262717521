import React, { useState, useEffect, useMemo } from 'react'
import { ExerciseStatus } from '../../../constants/exercises'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import { Row, Col, Table } from 'react-bootstrap'
import FooterButtons from '../../common/components/FooterButtons'
import WtInProgress from './WtInProgress'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import { getCenteredBreathingText, getCognitiveFooterButtons, getCurrent, getCurrentExercise, getCurrentSet, getTotalMinutes, isReadyToStart } from '../../common/exerciseHelper'
import { WtExercise, WtQuestionSet, WtUserAnswer } from '../wtSlice'
import { getResults, getThemeChoices } from '../wtHelper'
import RightWrongIcon from '../../common/components/RightWrongIcon'
import { pages } from '../../../constants/pages'

export interface IWtExercisesProps {    
  status: ExerciseStatus
  exercises: WtExercise[]
  userAnswers: WtUserAnswer[]
  startTime?: number
  endTime?: number
  submitAnswer: (answer: string) => void
  next: (repeat?: boolean) => void
  backTo: (route: string) => void
}

const WtExercises = ({ status, exercises, userAnswers, startTime, endTime, next, submitAnswer, backTo }: IWtExercisesProps) => {
  const [answer, setAnswer] = useState<string | undefined>(undefined)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    next(true)
  }

  const handleSubmitAnswer = () => {
    if (answer !== undefined) {
      submitAnswer(answer)
      setAnswer(undefined)
      window.scrollTo(0, 0)
    }
  }

  const { currentExercise, currentSet, currentExerciseIndex } = getCurrent(exercises, userAnswers)

  const { themes } = getCurrentExercise(exercises, currentExerciseIndex)

  const { words, correctAnswer } = getCurrentSet(exercises, currentExerciseIndex, currentSet) as WtQuestionSet

  const themeChoices = useMemo(() => getThemeChoices(themes, correctAnswer), [themes, correctAnswer])

  const totalMinutes = getTotalMinutes(startTime, endTime)

  const results = getResults(status, exercises, userAnswers)

  const completedExercises = currentExercise - 1

  return (
    <>
      <ExerciseIntro status={status}>
        <h4>Instructions</h4>
        <p>You will find a series of “scrambled” words. All but one word have something in common. Unscramble the words that share something in common. From the four categories below the word list, choose the best category they belong to. You may not have to unscramble all of the words to choose the correct category. For an added challenge, try to unscramble every word, including the “outlier” word.</p>
        <p>Enjoy!</p>
      </ExerciseIntro>
      <WtInProgress
        status={status}
        currentExercise={currentExercise}
        currentSet={currentSet}
        words={words}
        themes={themeChoices}
        answer={answer}
        pickAnswer={(answer: string) => setAnswer(answer)}
        next={handleSubmitAnswer}
      />
      <ExerciseComplete status={status} category="cognitive" totalMinutes={totalMinutes} completedExercises={completedExercises}>
        <h4>Result</h4>
        {results.map((result, i) => (
          <div key={`section-${i}`}>
            <Row>
              <Col xs={8}>
                <h5>Exercise {results.length - i}</h5>
              </Col>
              <Col xs={4}>
                <h5 className="text-right">{result.percentage}%</h5>
              </Col>
            </Row>
            <Table striped>
              <tbody>
                {result.sets.map((set, j) => (<tr key={`row-${i}-${j}`}><td>Set&nbsp;{j + 1}</td><td className="text-right"><RightWrongIcon correct={set} /></td></tr>))}
              </tbody>
            </Table>
          </div>
        ))}
      </ExerciseComplete>
      <FooterButtons
        extraText={getCenteredBreathingText(status)}
        hidden={status === 'in-progress'}
        buttons={getCognitiveFooterButtons(
          status,
          pages.wordTheme.title!,
          isReadyToStart(status, exercises),
          backTo,
          handleNext,
          handleRepeat,
        )}
      />
    </>
  )
}

export default WtExercises