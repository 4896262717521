import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'

export interface IExerciseIntroProps {
  status: ExerciseStatus
  children: React.ReactNode
}

const ExerciseIntro = ({ status, children }:IExerciseIntroProps) => {
  if (status !== 'start') { return null }
  return (
    <Container>
      <Row>
        <Col>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

export default ExerciseIntro