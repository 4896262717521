import React, { useState } from 'react'
import { Card, Typography, Form, Input, Button } from 'antd';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { SignUpError } from '../userSlice';
import AnchorLink from '../../common/AnchorLink';

const { Title } = Typography;

export interface IPasswordResetProps {
  uid: string
  token: string
  processing: boolean
  processed?: boolean
  error?: SignUpError
  resetPassword: (uid: string, token: string, password: string) => void
  goToSignIn: () => void
}

const PasswordReset = ({ uid, token, processing, processed, error, resetPassword, goToSignIn }: IPasswordResetProps) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordMisMatch, setPasswordMisMatch] = useState<boolean>(false);

  const handleReset = () => {
    setPasswordMisMatch(password !== confirmPassword);      
    if (password && confirmPassword && password === confirmPassword) {
      resetPassword(uid, token, password)
    }
  }

  const handleConfirmPasswords = (pass: string) => {
    setConfirmPassword(pass);
    if (pass.length >= password.length) {
      setPasswordMisMatch(password !== pass);      
    }
  };

  const disabledSubmitButton = processing || !password || !confirmPassword

  const invalidPassword = error && error.invalidPassword && error.invalidPassword.join('\n')
  
  const resetForm = (
    <Form onFinish={handleReset} layout="vertical">
      <Form.Item>
        <Form.Item
          name="password"
          validateStatus={!!invalidPassword ? 'error' : 'success'}
          help={invalidPassword}
        >
          <Input.Password
            placeholder="Enter new password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item 
          name="confirmPassword" 
          validateStatus={passwordMisMatch ? 'error' : 'success'}
          help={passwordMisMatch ? 'The passwords do not match each other.' : null}
        >
          <Input.Password
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfirmPasswords(e.target.value)}
            autoComplete="new-password"
          />
        </Form.Item>
      </Form.Item>
      <Button htmlType="submit" size="large" type="primary" block disabled={disabledSubmitButton} loading={processing}>Submit</Button>
    </Form>
  )

  const resetPasswordProcessed = (
    <>
      <p>Your password is reset successfully.</p>
      <p>You may try to <AnchorLink onClick={goToSignIn}>sign in</AnchorLink> again.</p>
    </>
  );

  let content: JSX.Element
  
  if (error && error.invalidToken) {
    content = <Alert variant="danger">Invalid/expired reset link</Alert>
  } else if (processed) {
    content = resetPasswordProcessed
  } else {
    content = resetForm
  }
    
  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={8} lg={6}>
          <Card>
            <Typography>
              <Title level={4}>Password Reset</Title>
              {content}
            </Typography>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordReset