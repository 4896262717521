import React from 'react'
import { push } from 'connected-react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import CeExercise from './components/CeExercise'
import { next, select, update, finish } from './ceSlice'

const CbExerciseConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, selectedExercise, durations } = useAppSelector((state: RootState) => state.cardioExercises)

  const handleNext = (repeat?: boolean, redirect?: string) => {
    dispatch(next(repeat))
    if (status === 'completed' && !repeat) {
      dispatch(push(redirect || pages.home.route))
    }
  }

  const handleSelectExercise = (exercise?: string) => {
    dispatch(select(exercise))
  }

  const handleUpdate = (duration: number) => {
    dispatch(update(duration))
  }

  const handleFinish = (duration: number, startDate?: number) => {
    dispatch(finish({ duration, startDate }))
  }

  return (
    <CeExercise 
      status={status}
      exercises={exercises}
      durations={durations}
      selectedExercise={selectedExercise} 
      next={handleNext} 
      update={handleUpdate}
      finish={handleFinish}
      selectExercise={handleSelectExercise}
      goToManualEntry={() => dispatch(push(pages.cardioExercisesManualEntry.route))}
    />
  )
}

export default CbExerciseConnect
