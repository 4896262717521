import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Input, message, notification } from 'antd'
import { UserSettingsError } from '../userSlice'
import { LockOutlined } from '@ant-design/icons'

interface IChangePasswordModalProps {
  error?: UserSettingsError
  processing: boolean
  changePassword: (oldPassword: string, newPassword: string) => void
}

const ChangePasswordModal = ({ error, processing, changePassword }: IChangePasswordModalProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [oldPassword, setOldPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordMisMatch, setPasswordMisMatch] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (!processing && submitted) {
      if (!error) {
        message.success('Your password is changed successfully.')
        handleClose()
      } else {
        const description = error.invalidOldPassword ? 'Wrong Current Password' : error.samePasswords ? 'Same old and new passwords' : error.invalidNewPassword ? 'Invalid new password' : 'System error.  Please try again later.'
        notification.error({ message: 'Change Password Error', description })
      }
    }
  }, [processing, submitted, error])

  const readyToSubmit = oldPassword && newPassword && newPassword === confirmPassword
  const disabledSubmitButton = processing || !newPassword || !confirmPassword || !oldPassword
  const invalidOldPassword = submitted && error?.invalidOldPassword
  const invalidNewPassword = submitted && (error?.samePasswords || error?.invalidNewPassword)
  const invalidNewPasswordMesage = invalidNewPassword ? error?.invalidNewPassword ? error?.invalidNewPassword!.join('\n') : 'The new password is the same as the old one.' : null

  const handleChangePassword = () => {
    setPasswordMisMatch(newPassword !== confirmPassword);      
    if (readyToSubmit) {
      changePassword(oldPassword, newPassword)
      setSubmitted(true)
    }
  }

  const handleConfirmPasswords = (pass: string) => {
    setConfirmPassword(pass);
    if (pass.length >= newPassword.length) {
      setPasswordMisMatch(newPassword !== pass);      
    }
  };

  const handleClose = () => {
    setVisible(false)
    setSubmitted(false)
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const changeForm = (
    <Form layout="vertical">
      <Form.Item
        label="Current Password"
        validateStatus={invalidOldPassword ? 'error' : 'success'}
        help={invalidOldPassword ? 'The current password is wrong.' : null}
      >
        <Input.Password
          value={oldPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)}
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        label="New Password"
        validateStatus={invalidNewPassword ? 'error': 'success'}
        help={invalidNewPasswordMesage}
      >
        <Input.Password
          value={newPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        validateStatus={passwordMisMatch ? 'error' : 'success'}
        help={passwordMisMatch ? 'The passwords do not match each other.' : null}
      >
        <Input.Password
          value={confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfirmPasswords(e.target.value)}
          autoComplete="new-password"
        />
      </Form.Item>
    </Form>
  )

  const footerButtons = (
    <>
      <Button onClick={handleClose}>Cancel</Button>
      <Button
        type="primary"
        disabled={disabledSubmitButton}
        loading={processing}
        onClick={handleChangePassword}
      >
        Submit
      </Button>      
    </>
  )

  return (
    <>
      <Button icon={<LockOutlined />} onClick={() => setVisible(true)}>Change Password</Button>
      <Modal
        visible={visible}
        title="Change Password"
        onCancel={handleClose}
        footer={footerButtons}
      >
        {changeForm}
      </Modal>
    </>
  )
}

export default ChangePasswordModal
