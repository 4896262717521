import React from 'react'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { Button } from 'antd'
import { useAppDispatch } from '../../../app/hooks'
import { setShowContactUs } from '../../user/userSlice'
import styled from 'styled-components'
import carotRight from '../../../imgs/carot-right.svg'

const FooterContainer = styled(Container)`
  p {
    color: white;
  }

  p.copy-right {
    font-size: 10px;
    span {
      color: #47abed;
    }
  }

  .ant-btn-link {
    color: #47abed;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .carot-right {
    width: 8px;
    margin-left: 5px;
  }
`;

const Footer = () => {
  const dispatch = useAppDispatch() 

  const year = new Date().getFullYear()

  const handleContactUs = () => {
    dispatch(setShowContactUs(true))
  }
  
  return (
    <FooterContainer className="pt-4">
      <Row>
        <Col xs={12} md={6} lg={8}>
          <p className="text-center text-md-left">
            We’d love to hear what you<br className="d-lg-none" /> think of <strong>15 For Me&reg;</strong>!
            <br className="d-md-none" />
            <Button type="link" onClick={handleContactUs}>Contact Us <Image className="carot-right" src={carotRight} alt="carot-right" /></Button>
          </p>
        </Col>
        <Col xs={12} md={6} lg={4} className='align-self-end'>
          <p className="copy-right text-center text-md-right">ENRICHvisits.com does not provide medical recommendations, medical advice, diagnoses, or treatments. &copy;{year} Mansbach Health Tools, LLC. All Rights Reserved. <span>| <a href="https://enrichvisits.com/privacy">Privacy Policy</a></span></p>
        </Col>
      </Row>
    </FooterContainer>
  )
}

export { Footer }