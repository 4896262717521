import React, { useState } from 'react'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { Typography, Modal, Button, Affix } from 'antd'
import styled from 'styled-components'
import { useAppDispatch } from '../../../app/hooks'
import { IButton, pages } from '../../../constants/pages'
import { push } from 'connected-react-router'
import UserMenu from '../../user/components/UserMenu'
import { showSuggestionNow, signOut } from '../../user/userSlice'
import logo from '../../../imgs/logo.png'
import headerBg from '../../../imgs/header-bg.gif'
import greeter from '../../../imgs/greeter.svg'

const { Title } = Typography

export interface IHeaderProps {
  title: string
  pageKey: string
  firstName?: string
  disabledExit?: boolean 
  extraExits?: IButton[]
  exitConfirm?: boolean
  exitDispatch?: any
  userSession: boolean
  hasSuggestions?: boolean
}

const HeaderContainer = styled(Container)`
  background: linear-gradient(.25turn, #001e57, #1679bb, #59c4d2)
`;

const Header = ({ title, pageKey, firstName, disabledExit = false, extraExits, exitConfirm, exitDispatch, userSession, hasSuggestions }: IHeaderProps) => {
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false)
  const dispatch = useAppDispatch() 

  const handleCancelExit = () => {
    setShowCloseModal(false)
  }

  const handleModalAction = (action: () => void, cancel?: boolean) => {
    setShowCloseModal(false)
    if (!cancel && exitDispatch !== undefined) {
      dispatch(exitDispatch())
    } else {
      action()
    }
  }

  const handleSignOut = () => {
    dispatch(signOut())
  }

  const cancelExit: IButton = { label: "Cancel", action: handleCancelExit, cancel: true }

  const defaultExit: IButton = { label: "Back to Home", action: () => dispatch(push(pages.home.route)), buttonType: "primary" }

  const exits: IButton[] = [cancelExit, defaultExit].concat(extraExits || [])

  const handleClose = () => {
    if (!disabledExit) {
      if (exitConfirm) {
        setShowCloseModal(true)
      } else {
        if (exitDispatch !== undefined) {
          dispatch(exitDispatch())
        } else {
          defaultExit.action()      
        }
      }
    }
  }

  const ModalActionButton = ({exit}: {exit: IButton}) => {
    const buttonType = exit.buttonType !== 'danger' && exit.buttonType !== 'success' ? exit.buttonType : 'default'
    return (
      <Button type={buttonType} onClick={() => handleModalAction(exit.action, exit.cancel)} >
        {exit.label}
      </Button>
    )
  }

  const closeButton = !disabledExit ? (
    <>
      <Modal 
        visible={showCloseModal}
        title="Exit"
        onCancel={handleCancelExit}
        footer={ exits.map((exit, index) => <ModalActionButton key={`exit-${index}`} exit={exit} />) }
      >
        <p>Are you sure?</p>
      </Modal>
    </>
  ) : null
  
  const userMenu = (
    <UserMenu
      signOut={handleSignOut}
      userSession={userSession}
      firstName={firstName}
      exitConfirm={exitConfirm}
      exitDispatch={exitDispatch}
    />
  )

  const handleSuggestion = () => {
    dispatch(showSuggestionNow())
  }

  const avatorIcon = (userSession && hasSuggestions && pageKey === 'home') && <Image style={{ cursor: 'pointer'} } onClick={handleSuggestion} src={greeter} className="float-right" height="45px" />

  return (
    <Affix offsetTop={0}>
      <div style={{ background: `center / cover repeat-y url(${headerBg})` }} >
        <HeaderContainer className="pt-3">
          <Row>
            <Col xs={8}>
              <Title onClick={handleClose}><Image draggable={false} src={logo} height="50px" /> </Title>
            </Col>
            <Col xs={4}>
              {userMenu}
              {closeButton}
              {avatorIcon}
            </Col>
          </Row>
        </HeaderContainer>
      </div>
    </Affix>
  )
}

export { Header }