import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap'
import styled from 'styled-components';
import CognitiveBadge from '../../../imgs/cognitive-badge.svg'
import BreathingBadge from '../../../imgs/breathing-badge.svg'
import CardioBadge from '../../../imgs/cardio-badge.svg'
import { pages } from '../../../constants/pages';
import { WeekAtAGlance } from '../../goal/components/WeekAtAGlance';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getGoalAndArchived, getSelectedGoalDetails } from '../../goal/goalHelper';
import { GoalState } from '../../goal/goalSlice';
import { Skeleton, Card, Button } from 'antd';
import pluralize from 'pluralize';
import CogWheel from '../../../imgs/cog-wheel.png'
import { push } from 'connected-react-router';
import { ExerciseSuggestion } from './ExerciseSuggestion';

const ThreeCBox = styled.div`
  background-color: #001E57;
  border-radius: 20px;
  padding: 1rem 0;
  box-shadow: 3px 3px 8px rgba(31, 31, 31, 0.25);
  border: solid 2px #FFFFFF;
  height: 100%;
  h3 {
    color: white;
    font-size: 20px;
    font-family: Poppins, sans-serif;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  p {
    color: #47ABED;
    font-size: 18px;
    font-family: Poppins, sans-serif;
    text-align: center;
    font-weight: 600;
  }
`;

const SoFarRow = styled(Row)`
  p {
    text-align: center;
    color: #505050;
    font-size: 20px;
    font-family: Poppins, sans-serif;
    .encourage {
      color: #FF7F00;
      font-weight: 600;
    }
    &.minutes {
      color: #1679BB;
      font-size: 30px;
      text-transform: uppercase;
      span {
        font-weight: 800;
        font-size: 70px;
      }
    }
  }
`;

const Home = () => {
  const dispatch = useAppDispatch()
  const goalState = useAppSelector(state => state.goal) as GoalState
  const goalDetails = getSelectedGoalDetails(goalState, true)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let SoFarMessage: JSX.Element | null = null

  if (goalDetails === undefined) { SoFarMessage =  (<Skeleton active />) }

  const { type, archived } = getGoalAndArchived(goalDetails!)

  const typeLabel = pluralize(type === 'count' ? 'exercise' : 'minute', archived)

  if (goalDetails !== undefined) {
    SoFarMessage = (
      <SoFarRow className="justify-content-center my-5 align-items-center">
        <Col xs={12} lg={5}>
          <p className="text-lg-right">So far this week, <br className="d-lg-none" />you’ve completed</p>
        </Col>
        <Col xs={12} lg={2}>
          <p className="minutes"><span>{archived}</span><br />{typeLabel}</p>
        </Col>
        <Col xs={12} lg={5}>
          <p className="text-lg-left">toward your wellness goal. <br className="d-lg-none" /><span className="encourage">Keep going!</span></p>
        </Col>
      </SoFarRow>
    )  
  }

  return (
    <>
      <Container>
        <Row className="mb-5">
          <Col xs={12} lg={7} xl={8}>
            <ThreeCBox className="d-flex align-items-center">
              <div>
                <h3 className="py-3">The 3 C’s for Stress Reduction</h3>
                <p>Select a category:</p>
                <Row className="justify-content-center">
                  <Col className="justify-content-center d-flex" xs={12} md={4}>
                    <a href={pages.cognitiveExercises.route}><Image className="img-fluid" src={CognitiveBadge} alt="cognitive-badge" /></a>
                  </Col>
                  <Col className="justify-content-center d-flex" xs={12} md={4}>
                    <a href={pages.cardioExercises.route}><Image className="img-fluid" src={CardioBadge} alt="cardio-badge" /></a>
                  </Col>
                  <Col className="justify-content-center d-flex" xs={12} md={4}>
                    <a href={pages.centeredBreathing.route}><Image className="img-fluid" src={BreathingBadge} alt="breathing-badge" /></a>
                  </Col>
                </Row>
              </div>
            </ThreeCBox>
          </Col>
          <Col xs={12} lg={5} xl={4}>
            <WeekAtAGlance />
          </Col>
        </Row>
        {SoFarMessage}
        <Row>
          <Col xs={12} lg={6} className="mb-4">
            <Card className="light-blue theme-card">
              <Row>
                <Col xs={8} md={9}>
                  <h2>Take Your “Stress Temperature”</h2>
                  <Button
                    className="blue-theme-btn"
                    size="large"
                    type="primary"
                    onClick={() => dispatch(push(pages.m5Test.route))}
                    block
                  >
                    Mood-5&reg; (M5&reg;)
                  </Button>
                </Col>
                <Col xs={4} md={3}>
                  <Image src={CogWheel} alt="cog-wheel" className="img-fluid" />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} lg={6} className="mb-4">
            <Card className="light-yellow theme-card">
              <Row>
                <Col xs={7} md={8}>
                  <h2>Try Our Sample Cardio Workouts </h2>
                  <Button
                    className="blue-theme-btn"
                    size="large"
                    type="primary" 
                    block
                    href="https://enrichvisits.com/sample-cardio-workouts"
                    target="_blank"
                  >View All</Button>
                </Col>
                <Col xs={5} md={4}>
                  <Image src={CardioBadge} alt="cardio-badge" className="img-fluid" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <ExerciseSuggestion />
    </>
  )
};

export default Home;