import React, { useMemo, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { CbExercise } from '../cbSlice'
import ReactPlayer from 'react-player/file'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import { Button, Progress } from 'antd'
import classnames from 'classnames'
import styled from 'styled-components'
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons'

interface ICbVideoProps {
  exercise?: CbExercise
  exerciseCompleted: (duration: number, startDate?: number) => void
  updateDuration: (duration: number) => void
  goBack: () => void
}

const VideoControlButton = styled(Button)`
  border-color: transparent !important;
`;

const CbVideo = ({ exercise, exerciseCompleted, updateDuration, goBack }: ICbVideoProps) => {
  const [loaded, setLoaded] = useState<number>(0)
  const [played, setPlayed] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)
  const [duration, setDuration] = useState<number>(0)

  useEffect(() => {
    let incTimeout: number | undefined = undefined
    if (duration || playing) {
      incTimeout = window.setTimeout(() => setDuration(duration + 100), 100)
    } else {
      window.clearTimeout(incTimeout)
    }
    return () => {
      window.clearTimeout(incTimeout)
    }
  }, [duration, playing])

  useEffect(() => {
    if ((duration / 100) % 300 === 299) {
      updateDuration(Math.round(duration / 1000))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration])

  const handleProgessUpdate = (played: number, loaded: number) => {
    setPlayed(Math.round(played * 100))
    setLoaded(Math.round(loaded * 100))
  }

  const videoUrls = useMemo(() => {
    if (exercise) {
      return [exercise.webm, exercise.mp4].map((url) => `${APIMEDIA_HOST}${url}`)
    }
    return undefined
  }, [exercise])

  if (exercise === undefined) {
    return null
  }

  
  const handlePauseResume = () => {
    setPlaying(!playing)
  }

  const handleEndVideo = () => {
    exerciseCompleted(Math.round(duration / 1000))
  }

  const pauseResumeLabel = playing ? 'Pause' : played > 0 ? 'Resume' : 'Start'

  const pauseResumeIcon = playing ? <PauseCircleFilled style={{ fontSize: "1.5em" }} /> : <PlayCircleFilled style={{ fontSize: "1.5em" }} />

  const pauseResumeClass = classnames('video-play-control', playing ? 'bg-danger' : 'bg-success')

  return (
    <>
      <ReactPlayer
        playing={playing}
        url={videoUrls}
        width="100%"
        height="100%"
        onEnded={handleEndVideo}
        config={{
          attributes: { poster: `${APIMEDIA_HOST}${exercise.poster}` },
        }}
        onProgress={({ played, loaded }) =>
          handleProgessUpdate(played, loaded)
        }
      />
      <Progress
        percent={loaded}
        success={{ percent: played, strokeColor: "#1178ba" }}
        status="active"
        showInfo={false}
        strokeColor="#DDD"
      />
      <Row className="justify-content-center mt-2">
        <Col xs={12} md={6} lg={4} xl={3}>
          <VideoControlButton block icon={pauseResumeIcon} className={pauseResumeClass} type="primary" onClick={handlePauseResume}>
            {pauseResumeLabel}
          </VideoControlButton>
        </Col>
        <Col xs={12} md={6} lg={4} xl={3}>
          <Button type="default" block onClick={goBack}>Go back</Button>
        </Col>
      </Row>
    </>
  )
}

export default CbVideo
