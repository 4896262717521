import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import ChangePasswordModal from './components/ChangePasswordModal'
import { changePassword } from './userSlice'

const ChangePasswordModalConnect = () => {
  const { processing, settings } = useAppSelector((state: RootState) => state.user)

  const dispatch = useAppDispatch()

  const handleChangePassword = (oldPassword: string, newPassword: string) => {
    dispatch(changePassword({ oldPassword, newPassword }))
  }

  return (
    <ChangePasswordModal 
      error={settings?.error}
      processing={processing}
      changePassword={handleChangePassword}
    />
  )
}

export default ChangePasswordModalConnect
