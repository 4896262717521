import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import styled from 'styled-components'
import ArrowRight from '../../../imgs/arrow-right.svg'
import { Modal } from 'antd'
import { useAppDispatch } from '../../../app/hooks'
import { pages } from '../../../constants/pages'
import { push } from 'connected-react-router'

export interface ISubfooterProps {
  pageKey: string
  exitConfirm?: boolean
  exitDispatch?: any
  isPublic?: boolean
}

const SubfooterWrapper = styled.div`
  background: #EEEEEE;
  height: 100px;
  p {
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
  }
`;

const Subfooter = ({ pageKey, exitConfirm, exitDispatch, isPublic }: ISubfooterProps) => {  
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const dispatch = useAppDispatch() 

  if (pageKey === pages.home.key || pageKey === pages.userRegistration.key || isPublic) { return null }

  const handleClickLink = () => {
    if (exitConfirm) {
      setShowConfirmModal(true)
    } else {
      if (exitDispatch !== undefined) {
        dispatch(exitDispatch())
      }
      dispatch(push(pages.home.route))
    }
  }

  const handleOkConfirm = () => {
    setShowConfirmModal(false)
    if (exitDispatch !== undefined) {
      dispatch(exitDispatch())
    }
    dispatch(push(pages.home.route))
  }

  const confirmModal = (
    <Modal 
      visible={showConfirmModal}
      title="Leaving Page"
      onCancel={() => setShowConfirmModal(false)}
      onOk={handleOkConfirm}
      okText="Yes"
      cancelText="No"
    >
      <p>Are you sure you want to leave the current page?</p>
    </Modal>
  )

  return (
    <SubfooterWrapper>
      <p onClick={handleClickLink} className="text-center">Return to dashboard <Image src={ArrowRight} alt="arrow-right" /></p>
      {confirmModal}
    </SubfooterWrapper>
  )
}

export { Subfooter }