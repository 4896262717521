import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExerciseStatus } from "../../constants/exercises";
import { endExercise, moveNext, quitExercise, startExercise } from "./ciHelper";


export interface CiTileInstruction {
  tile: string
  instruction: string
}

export interface CiSample {
  tiles: CiTileInstruction[]
  instruction: string
}

export interface CiLevel {
  level: number
  samples: CiSample[]
  tiles: string[]
}

export interface CiExercises {
  count: number
  levels: CiLevel[]
}

export interface CiUserExercise {
  level: number
  sample?: number
  start: number
  end?: number  
}

export interface CiStart {
  level: number
  sample?: number
}

export interface CiState {
  status: ExerciseStatus
  id?: string
  exercises?: CiExercises
  userExercises: CiUserExercise[]
  startTime?: number
  endTime?: number
}

const initialState: CiState = {
  status: 'start',
  userExercises: []
}

interface CiExercisesPayload {
  id: string
  exercises: CiExercises
}

export const ciSlice = createSlice({
  name: 'colorIllusion',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<CiExercisesPayload>) => {
      state.exercises = action.payload.exercises
      state.id = action.payload.id
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      moveNext(state, action.payload)
    },
    start: (state, action: PayloadAction<CiStart>) => {
      const { level, sample } = action.payload
      startExercise(level, sample, state)
      moveNext(state, true)
    },
    end: (state) => {
      endExercise(state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('colorIllusion/requestQuit')

export const { installExercises, next, start, end, quit, reset } = ciSlice.actions

export default ciSlice.reducer