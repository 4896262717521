import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Button } from 'antd'
import FooterButtons from '../../common/components/FooterButtons'
import { FtiExercise } from '../ftiSlice'
import { getLevels } from '../ftiHelper'

export interface IFtiLevelChooserProps {
  hidden?: boolean
  exercises: FtiExercise[]
  pickLevel: (level: number) => void
}

const FtiLevelChooser = ({hidden, exercises, pickLevel}: IFtiLevelChooserProps) => {
  const [level, setLevel] = useState<number | undefined>(undefined)

  if (hidden) { return null }

  const readyToSubmit = level !== undefined

  const handleNext = () => {
    if (readyToSubmit) {
      pickLevel(level!)
      setLevel(undefined)
    }
  }

  const levelCols = getLevels(exercises).map((exerciseLevel) => {   
    const buttonType = exerciseLevel === level ? 'primary' : 'default'
    return (
      <Col xs={12} sm={6} md={3} key={`level-${exerciseLevel}`}>
        <Button size="large" block type={buttonType} onClick={() => setLevel(exerciseLevel)}>Level {exerciseLevel}</Button>
      </Col>
    )
  })

  return (
    <>
      <Container>
        <h4>Instructions</h4>
        <p>Note: level 1 and level 2 are <strong>vertical</strong>, and level 3 and level 4 are <strong>horizontal</strong>.</p>
        <h5 className="mt-4">Choose Level</h5>
        <Row>
          {levelCols}
        </Row>
      </Container>
      <FooterButtons 
        buttons={[{ disabled: !readyToSubmit, label: "Next", action: handleNext, buttonType: "primary" }]}
      />
    </>
  )
}

export default FtiLevelChooser