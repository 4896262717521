import React from 'react'
import { ISubheaderProps, Subheader } from './components/Subheader'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { getPageFromLocation } from '../../constants/pages'

const  SubheaderConnect = () => {
  const { location } = useAppSelector((state: RootState) => state.router)
  const page = getPageFromLocation(location)
  const subheaderProps: ISubheaderProps = {
    title: page?.title ?? '',
    pageKey: page?.key ?? '',
  }
  return (
    <Subheader { ...subheaderProps } />
  )
}

export default SubheaderConnect