import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import UserSettings from './components/UserSettings'
import { updateUserSettings, UserSettings as UserSettingsType } from './userSlice'

const UserSettingsConnect = () => {
  const dispatch = useAppDispatch()
  const { settings, processing } = useAppSelector((state: RootState) => state.user)
  
  const handleUpdateSettings = (settings: UserSettingsType) => {
    dispatch(updateUserSettings(settings))
  }

  const handleGoBack = () => {
    dispatch(push(pages.home.route))
  }

  return (
    <UserSettings
      processing={processing}
      currentSettings={settings}
      updateSettings={handleUpdateSettings}
      goBack={handleGoBack}
    />
  )
}

export default UserSettingsConnect