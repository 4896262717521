import { Button, Card } from 'antd'
import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { pages } from '../../../constants/pages'
import SpanishPopup from './SpanishPopup'
import { WatchIntroVideo } from './WatchIntroVideo'

interface ICognitiveExercisesProp {
  gotoFlipTheImage?: () => void
  gotoMemoryMatch?: () => void
  gotoNumberSymbol?: () => void
  gotoWordTheme?: () => void
  gotoColorIllusion?: () => void
}

interface Exercise {
  label: string
  goto?: () => void
  disabled?: boolean
}

const CognitiveExercises = ({ gotoFlipTheImage, gotoMemoryMatch, gotoNumberSymbol, gotoWordTheme, gotoColorIllusion }: ICognitiveExercisesProp) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const cognitiveExercises: Exercise[] = [
    {
      label: pages.memoryMatch.title!,
      goto: gotoMemoryMatch,
    },
    {
      label: pages.flipTheImage.title!,
      goto: gotoFlipTheImage
    },
    {
      label: pages.numberSymbol.title!,
      goto: gotoNumberSymbol,
    },
    {
      label: pages.wordTheme.title!,
      goto: gotoWordTheme,
    },
    {
      label: pages.colorIllusion.title!,
      goto: gotoColorIllusion,
    },
  ]

  const cognitiveExerciseButtons = cognitiveExercises.map((exercise, index) => (
    <Col key={`cog-${index}`} className="my-2" xs={12} lg={6}>
      <Button disabled={exercise.disabled} style={{ height: "auto" }} block type="primary" onClick={exercise.goto}><span className="h4 my-4">{exercise.label}</span></Button>
    </Col>
  ))

  return (
    <Container>
      <Row>
        <Col className="mb-4 order-md-2" md={6} lg={7}>
          <Card>
            <Row>
              {cognitiveExerciseButtons}
            </Row>
          </Card>
        </Col>
        <Col className="mb-4 order-md-1" md={6} lg={5}>
          <p>Doing the cognitive or thinking exercises on a regular basis can help to keep your brain healthy and boost your mood.&nbsp; The cognitive exercises found in the <em>15 for Me&reg;</em> program are proven to lower stress, and they are fun to do!</p>
          <p>You will find that the exercises have different levels of difficulty. When selecting an exercise, we suggest that you consider choosing a lower level of difficulty first, and work your way up to more challenging options.</p>
          <p>You can mix and match the exercises and should set a goal to spend 10-12 minutes on these brain healthy activities.&nbsp; Remember, the goal is <em>to do</em> the exercises, not get a <em>perfect score</em>! Just participating in cognitive exercise can lower your stress level.</p>
          <p>&nbsp;</p>
        </Col>
      </Row>
      <Row className='justify-content-center mb-4'>
        <Col md={6} lg={4}>
          <SpanishPopup title="Introducción a los ejercicios cognitivos">
            <p>Hacer los ejercicios cognitivos regularmente le puede ayudar a mantener la mente saludable y aumentar su estado de humor. Se ha comprobado que los ejercicios cognitivos encontrados en el programa de 15 For Me&reg; bajan el estrés, ¡y son divertidos de hacer!</p>
            <p>Usted verá que los ejercicios tienen niveles de dificultad diferentes. Al seleccionar un ejercicio, sugerimos que escoja un nivel más bajo al empezar y que vaya subiendo a los niveles más exigentes. </p>
            <p>Usted puede combinar y mezclar los ejercicios, y debe tener como meta pasar 10-12 minutos en estas actividades de la salud cerebral. Acuérdese- ¡la meta es hacer los ejercicios, no sacar un puntaje perfecto en ellos! El simple hecho de participar en los ejercicios cognitivos puede bajar su nivel de estrés. </p>
          </SpanishPopup>
        </Col>
      </Row>
      <WatchIntroVideo videoSlug="cognitive" introText="Cognitive Exercises" />
    </Container>
  )
}

export default CognitiveExercises