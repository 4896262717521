import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import MmExercises from './components/MmExercises'
import { next, start, end, requestQuit  } from './mmSlice'

const MmExercisesConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, userScores, startTime, endTime } = useAppSelector((state: RootState) => state.memoryMatch)
 
  const handleNext = () => {
    dispatch(next())
    if (status === 'completed') {
      dispatch(push(pages.cognitiveExercises.route))
    }
  }

  const handleStartExercise = (theme: string, level: number) => {
    dispatch(start({ theme, level }))
  }

  const handleEndExercise = (finished: boolean, attempts: number) => {
    dispatch(end({ finished, attempts }))
  }

  const handleQuit = (route: string) => {
    dispatch(requestQuit(route))
  }

  return (
    <MmExercises 
      status={status}
      next={handleNext}
      exercises={exercises}
      userScores={userScores}
      startTime={startTime}
      endTime={endTime} 
      startExercise={handleStartExercise}
      endExercise={handleEndExercise}
      backTo={handleQuit}
    />
  )
}

export default MmExercisesConnect