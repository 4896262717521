import React from 'react'
import { push } from 'connected-react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import { next, start, end, requestQuit } from './ciSlice'
import CiExercises from './components/CiExercises'


const CiExercisesConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, userExercises, startTime, endTime } = useAppSelector((state: RootState) => state.colorIllusion)

  const handleNext = () => {
    dispatch(next())
    if (status === 'completed') {
      dispatch(push(pages.home.route))
    }
  }

  const handleStartExercise = (level: number, sample?: number) => {
    dispatch(start({ level, sample }))
  }

  const handleEndExercise = () => {
    dispatch(end())
  }

  const handleQuit = (route: string) => {
    dispatch(requestQuit(route))
  }

  return (
    <CiExercises
      status={status}
      next={handleNext}
      exercises={exercises}
      userExercises={userExercises}
      startTime={startTime}
      endTime={endTime}
      startExercise={handleStartExercise}
      endExercise={handleEndExercise}
      backTo={handleQuit}
    />
  )
}

export default CiExercisesConnect