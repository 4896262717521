import { CbExercise, CbState } from "./cbSlice"

export const moveNext = (state: CbState, repeat?: boolean) => {
  const { status, exercises } = state
  if (exercises.length) {
    if (status === 'start') {
      state.durations.push(0)
      state.status = 'in-progress'
      return
    }
    if (status === 'completed') {
      if (!repeat) {
        quitExercise(state)
      } else {
        state.durations.push(0)
        state.status = 'in-progress'
        state.selectedExercise = undefined    
      }
      return
    }
    if (status === 'in-progress') {
      state.status = 'completed'
      return
    }
  }
}

export const quitExercise = (state: CbState) => {
  state.status = 'start'
  state.id = undefined
  state.exercises = []
  state.durations = []
  state.selectedExercise = undefined    
  state.startDate = undefined
}

export const updateDuration = (state: CbState, value: number) => {
  if (state.durations.length) {
    state.durations[state.durations.length - 1] = value
  }
}

export const unSelectExercise = (state: CbState) => {
  const { selectedExercise } = state
  state.selectedExercise = findParentExerciseSlug(undefined, state.exercises, selectedExercise)
}

export const findExercise = (exercises: CbExercise[], slug?:string): CbExercise | undefined => {
  if (exercises.length === 0 || slug === undefined) { return }
  for (const exercise of exercises) {
    if (exercise.slug === slug) {
      return exercise
    }
    const result = findExercise(exercise.children, slug)
    if (result !== undefined) {
      return result
    }
  }
}

export const findParentExerciseSlug = (parent: string|undefined, exercises: CbExercise[], slug?:string): string | undefined => {
  if (exercises.length === 0 || slug === undefined) { return }
  for (const exercise of exercises) {
    let nextSlug: string|undefined = slug
    if (exercise.slug === slug) {
      if (!exercise.variant) {
        return parent
      }
      nextSlug = parent
    }
    const result = findParentExerciseSlug(exercise.slug, exercise.children, nextSlug)
    if (result !== undefined) {
      return result
    }
  }
}

export const getNumOfVariants = (exercise: CbExercise): number => {
  if (exercise.children.length === 0) { return 0 }
  for (const child of exercise.children) {
    if (child.variant) {
      return exercise.children.length
    }
  }
  return 0
}
