import { all } from 'redux-saga/effects'
import { exercisesWatcher } from './exercises'
import { userWatcher } from './user'
import { goalWatcher } from './goal'
import { portalAdminWatch } from './portalAdmin'

export function* rootSaga() {
  yield all([
    exercisesWatcher(),
    userWatcher(),
    goalWatcher(),
    portalAdminWatch(),
  ]);
};