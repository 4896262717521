import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch } from '../../app/hooks'
import { exerciseTypeMap } from '../../constants/exercises'
import { pages } from '../../constants/pages'
import ManualExerciseEntryForm, { IManualEntry } from '../common/components/ManualExerciseEntryForm'
import { getBulkUpdateEntries } from '../user/userHelper'
import { bulkUpdateExercise } from '../user/userSlice'

const CbManualEntryConnect = () => {
  const dispatch = useAppDispatch()

  const handleSubmitEntries = (manualEntries: Required<IManualEntry>[]) => {
    const entries = getBulkUpdateEntries(manualEntries)
    dispatch(bulkUpdateExercise({ exercise: exerciseTypeMap.centeredBreathing, entries }))
  }

  const handleDone = () => {
    dispatch(push(pages.home.route))
  }

  return (
    <ManualExerciseEntryForm 
      exercise={exerciseTypeMap.centeredBreathing}
      submitEntries={handleSubmitEntries}
      done={handleDone}
    />
  )
}

export default CbManualEntryConnect
