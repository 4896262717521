import { random } from 'lodash'
import { ExerciseStatus } from '../../constants/exercises';
import { getMinutesAndSeconds } from '../common/exerciseHelper';
import { CiExercises, CiLevel, CiSample, CiState, CiUserExercise } from "./ciSlice";

export interface CiCurrent {
  totalExercise: number
  currentExercise: number
}

export const isReadyToStart = (status: ExerciseStatus, exercises?: CiExercises): boolean => {
  return status === 'start' && exercises !== undefined
}

export const getCurrent = (exercises: CiExercises | undefined, userExercises: CiUserExercise[]): CiCurrent => {
  const totalExercise: number = exercises?.count || 0
  let currentExercise: number = userExercises.length
  if (currentExercise === 0) {
    currentExercise = 1
  } else {
    const { end } = userExercises[userExercises.length - 1]
    if (end !== undefined) {
      currentExercise += 1
    }
  }
  return { totalExercise, currentExercise }
}

export interface CiLevelAndSample {
  level?: CiLevel
  sample?: CiSample
}

export const getLevelAndSample = (levels: CiLevel[], selectedLevel?: number, selectedSample?: number): CiLevelAndSample => {
  const level = selectedLevel !== undefined ? levels[selectedLevel] : undefined
  const sample = level !== undefined && selectedSample !== undefined ? level.samples[selectedSample] : undefined
  return { level, sample }
}

export const moveNext = (state: CiState, repeat?: boolean) => {
  const { status, exercises, userExercises } = state
  if (status === 'start') {
    if (exercises !== undefined) {
      state.startTime = Date.now()
      state.status = 'in-progress'
    }
  }
  if (status === 'completed') {
    if (!repeat) {
      quitExercise(state)
    } else {
      state.status = 'in-progress'
    }
    return
  }
  if (status === 'check-point') {
    state.status = 'in-progress'
  }
  if (status === 'in-progress') {
    if (exercises) {
      const lastExercise = userExercises[userExercises.length - 1]
      if (userExercises.length >= exercises.count) {
        if (lastExercise.end !== undefined) {
          state.endTime = Date.now()
          state.status = 'completed'
          return            
        }
      } else {
        if (lastExercise.end !== undefined) {
          state.endTime = Date.now()
          state.status = 'check-point'
          return  
        }
      } 
    }
    state.endTime = Date.now()
  }
}

export const quitExercise = (state: CiState) => {
  state.status = 'start'
  state.id = undefined
  state.exercises = undefined
  state.userExercises = []
  state.startTime = undefined
  state.endTime = undefined
}

interface SelectedLevelAndSample {
  level: number
  sample?: number
}

export const getLastSelectedLevelAndSample = (userExercises: CiUserExercise[]): SelectedLevelAndSample | null => {
  if (userExercises.length) {
    const lastExercise = userExercises[userExercises.length - 1]
    const { level, sample } = lastExercise
    return { level, sample }
  }
  return null
} 

export const startExercise = (level: number, sample: number|undefined, state: CiState) => {
  const { userExercises } = state
  const exerciseLength = userExercises.length
  const start = Date.now()
  if (exerciseLength === 0) {
    userExercises.push({ level, sample, start })
    return
  }
  const exerciseIndex = exerciseLength - 1
  const userExercise = userExercises[exerciseIndex]
  if (userExercise.end !== undefined) {
    userExercises.push({ level, sample, start })
  }
}

export const endExercise = (state: CiState) => {
  const { userExercises } = state
  const exerciseLength = userExercises.length
  if (exerciseLength > 0) {
    const lastExercise = userExercises[exerciseLength - 1]
    if (lastExercise.end === undefined) {
      lastExercise.end = Date.now()
    }
  }
}

export const getRandomSample = (exercises: CiExercises, levelNumber: number, exceptSample?: number): number|undefined => {
  const { levels } = exercises
  if (levelNumber < levels.length) {
    const level = levels[levelNumber]
    const { samples } = level
    if (samples.length) {
      let pick = random(samples.length - 1)
      if (samples.length > 1) {
        while (pick === exceptSample) {
          pick = random(samples.length - 1)      
        }  
      }
      return pick
    }
  }
  return undefined
}

export interface CiResult {
  level: number
  time: string
}

export const getResults = (status: ExerciseStatus, userExercises: CiUserExercise[]): CiResult[] => {
  let results: CiResult[] = []
  if (status === 'completed' || status === 'check-point') {
    results = userExercises.map(userExercise => {
      const { mins, seconds } = getMinutesAndSeconds(userExercise.start, userExercise.end)
      return {
        level: userExercise.level + 1,
        time: `${mins}:${seconds.toString().padStart(2, '0')}`
      }
    })
    results.reverse()
  }
  return results;
}