import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Card } from 'antd'
import ExerciseManualEntry from '../../common/components/ExerciseManualEntry'
import { CbExercise, cbLimits } from '../cbSlice'
import CbVideo from './CbVideo'
import FooterButtons from '../../common/components/FooterButtons'
import { IButton } from '../../../constants/pages'

interface ICbExerciseDetailProps {
  exercise?: CbExercise
  doneExercise: (duration: number, startDate?: number) => void
  updateDuration: (duration: number) => void
  goBack: () => void
}

const CbExerciseDetail = ({ exercise, doneExercise, updateDuration, goBack }: ICbExerciseDetailProps) => {
  const [minutes, setMinutes] = useState<number>(cbLimits.default)
  const [startDate, setStartDate] = useState<number | undefined>(undefined)

  if (exercise === undefined || exercise.children.length ) { return null }

  const otherExercise = exercise.slug === 'other'

  const manualButtons: IButton[] = [
    { label: 'Go back', action: goBack, buttonType: 'default' },
    { label: 'Add your time here', action: () => doneExercise(minutes * 60, startDate), buttonType: 'primary' }
  ]

  const footerButtons = otherExercise ? manualButtons : []

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col>
            <Card>
              <h5>{exercise.name}</h5>
              <CbVideo
                exercise={!otherExercise ? exercise : undefined}
                exerciseCompleted={doneExercise}
                updateDuration={updateDuration}
                goBack={goBack}
              />
              {otherExercise && <p>
                A daily meditation practice can have significant benefits for mental and physical health. There are many different types of meditation, and you may decide to select a different meditation or centered breathing exercise than what is currently offered in the 15 for Me&reg; program.  You can select the type that works best for you and complete it here.  Add your time below for your own meditation here.
              </p>}
              <ExerciseManualEntry
                hidden={!otherExercise}
                minutes={minutes}
                startDate={startDate}
                setMinutes={setMinutes}
                setStartDate={setStartDate}
                limits={cbLimits}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterButtons 
        buttons={footerButtons}      
      />
    </>
  )
}

export default CbExerciseDetail