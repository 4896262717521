import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import WtExercises from './components/WtExercises'
import { next, answer as submitAnswer, requestQuit } from './wtSlice'

const WtExercisesConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, userAnswers, startTime, endTime } = useAppSelector((state: RootState) => state.wordTheme)

  const handleNext = (repeat?: boolean) => {
    dispatch(next(repeat))
    if (status === 'completed' && !repeat) {
      dispatch(push(pages.home.route))
    }
  }

  const handleSubmitAnswer = (answer: string) => {
    dispatch(submitAnswer(answer))
  }

  const handleQuit = (route: string) => {
    dispatch(requestQuit(route))
  }

  return (
    <WtExercises
      status={status}
      next={handleNext} 
      exercises={exercises} 
      userAnswers={userAnswers} 
      submitAnswer={handleSubmitAnswer}
      startTime={startTime}
      endTime={endTime}
      backTo={handleQuit}    
    />
  )
}

export default WtExercisesConnect