import React from 'react'
import { push } from 'connected-react-router'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import CbExercise from './components/CbExercise'
import { next, update, finish, unSelect, select } from './cbSlice'

const CbExerciseConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, durations, selectedExercise } = useAppSelector((state: RootState) => state.centeredBreathing)

  const handleNext = (repeat?: boolean) => {
    dispatch(next(repeat))
    if (status === 'completed' && !repeat) {
      dispatch(push(pages.home.route))
    }
  }

  const handleUnSelect = () => {
    dispatch(unSelect())
  }

  const handleUpdate = (duration: number) => {
    dispatch(update(duration))
  }

  const handleFinish = (duration: number, startDate?: number) => {
    dispatch(finish({ duration, startDate }))
  }

  const handleSelectExercise = (exercise: string) => {
    dispatch(select(exercise))
  }

  return (
    <CbExercise 
      status={status} 
      exercises={exercises}
      durations={durations}
      selectedExercise={selectedExercise}
      next={handleNext}  
      selectExercise={handleSelectExercise}
      unSelect={handleUnSelect} 
      update={handleUpdate}
      finish={handleFinish} 
      goToManualEntry={() => dispatch(push(pages.centeredBreathingManualEntry.route))}
    />
  )
}

export default CbExerciseConnect