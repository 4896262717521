import { Input, Typography } from 'antd';
import React, { useState } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { Card, Form, Button } from 'antd';
import LoadingSpinner from '../../common/LoadingSpinner';
import { SignUpError } from '../userSlice';

const { Title } = Typography;

export interface ISignUpProps {
  couponCode: string | null;
  defaultCouponCode?: string;
  company?: string;
  processing: boolean;
  error?: SignUpError;
  submitCoupon: (code: string) => void;
  signUp: (
    couponCode: string,
    email: string,
    password: string, 
    firstName: string, 
    lastName: string,
  ) => void;
  checkEmail: (couponCode: string, email: string) => void;
  clearCode: () => void;
}

const SignUp = ({ couponCode, defaultCouponCode, company, processing, error, submitCoupon, signUp, checkEmail, clearCode }: ISignUpProps) => {
  const [code, setCode] = useState<string>(defaultCouponCode || '');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordMisMatch, setPasswordMisMatch] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');

  const submitLabel = !processing ? 'Next' : (<LoadingSpinner />);
  const signUpLabel = !processing ? 'Sign Up' : (<LoadingSpinner />);

  const handleSubmitCoupon = () => {
    if (code) {
      submitCoupon(code);
    }
  };

  const handleSignUp = () => {
    setPasswordMisMatch(password !== confirmPassword);      
    if (couponCode && email && password && confirmPassword && firstName && lastName && password === confirmPassword) {
      signUp(couponCode, email, password, firstName, lastName);
    }
  };

  const handleConfirmPasswords = (pass: string) => {
    setConfirmPassword(pass);
    if (pass.length >= password.length) {
      setPasswordMisMatch(password !== pass);      
    }
  };

  const handleCheckEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (couponCode && email.length) {
      checkEmail(couponCode, email)
    }
  }

  const invalidCouponCodeAlert = error && error.invalidCouponCode ? (
    <Alert variant="danger">Invalid registration code</Alert>
  ) : null;

  const submitCouponForm = (
    <Form onFinish={handleSubmitCoupon} layout="vertical">
      <p>Please provide the registration code you received.</p>
      <Form.Item label="Registration Code" name="couponCode">
        <Input
          type="text"
          placeholder="Enter registration code"
          value={code}
          defaultValue={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
        />
      </Form.Item>
      {invalidCouponCodeAlert}
      <Button htmlType="submit" type="primary" size="large" block disabled={processing || !code}>{submitLabel}</Button>
      <p className="mt-4 mb-0">The 15 for Me&reg; Program requires a registration code. For more information, please contact <a href="mailto:15forme@enrichvisits.com">15forme@enrichvisits.com</a>. Thank you! </p>
    </Form>
  );

  const disabledSignUpButton = processing || !email || !password || !confirmPassword || !firstName || !lastName;

  const unavailableEmail = error && error.unavailableEmail;

  const invalidPassword = error && error.invalidPassword && error.invalidPassword.join('\n')

  const signUpForm = (
    <Form onFinish={handleSignUp} layout="vertical">
      <Form.Item label="Registration Code">
        <Input
          type="text"
          disabled
          value={couponCode!}
        />
        <Button className="px-0" type="link" onClick={ clearCode }>Change Code</Button>
      </Form.Item>
      {company && <p><strong>{company}</strong></p>}
      <Form.Item label="Name">
        <Form.Item name="firstName">
          <Input
            type="text"
            placeholder="Enter first name"
            value={firstName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            autoComplete="given-name"
          />
        </Form.Item>
        <Form.Item name="lastName">
          <Input
            type="text"
            placeholder="Enter last name"
            value={lastName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            autoComplete="family-name"
          />
        </Form.Item>
      </Form.Item>
      <Form.Item 
        label="Email" 
        validateStatus={ unavailableEmail ? 'error' : 'success'}
        help={ unavailableEmail ? 'This email address is not available for sign up.' : null }
      >
        <Input
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          onBlur={handleCheckEmail}
          autoComplete="email"
        />
      </Form.Item>
      <Form.Item label="Set Password">
        <Form.Item
          name="password"
          validateStatus={!!invalidPassword ? 'error' : 'success'}
          help={ invalidPassword }
        >
          <Input.Password
            placeholder="Enter new password"
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item 
          name="confirmPassword" 
          validateStatus={passwordMisMatch ? 'error' : 'success'}
          help={passwordMisMatch ? 'The passwords do not match each other.' : null}
        >
          <Input.Password
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfirmPasswords(e.target.value)}
            autoComplete="new-password"
          />
        </Form.Item>
      </Form.Item>
      <Button htmlType="submit" size="large" type="primary" block disabled={disabledSignUpButton}>{signUpLabel}</Button>
    </Form>
  );

  const content = couponCode === null ? submitCouponForm : signUpForm;

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Card>
            <Typography>
              <Title level={4}>Sign Up for 15 for Me&reg; Access</Title>
              {content}
            </Typography>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;