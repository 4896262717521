import React from 'react'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { getPageFromLocation } from '../../constants/pages'
import { ISubfooterProps, Subfooter } from './components/Subfooter'

const  SubfooterConnect = () => {
  const { location } = useAppSelector((state: RootState) => state.router)
  const page = getPageFromLocation(location)
  const subfooterProps: ISubfooterProps = {
    pageKey: page?.key ?? '',
    exitConfirm: page?.exitConfirm,
    exitDispatch: page?.exitDispatch,
    isPublic: page?.public,
  }
  return (
    <Subfooter { ...subfooterProps } />
  )
}

export default SubfooterConnect