import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"

interface IRightWrongIconProps {
  correct: boolean
}

const RightWrongIcon = ({ correct }: IRightWrongIconProps) => {
  return correct ? ( 
    <CheckCircleTwoTone twoToneColor="#52c41a" />
  ) : (
    <CloseCircleTwoTone twoToneColor="#ff4d4f" />
  )
}

export default RightWrongIcon