import React, { useMemo } from 'react'
import { CeExercise } from '../ceSlice'
import ReactPlayer from 'react-player/file'
import { APIMEDIA_HOST } from '../../../conf/hosts'

interface ICeVideoProps {
  exercise?: CeExercise
}

const CeVideo = ({ exercise }: ICeVideoProps) => {
  const videoUrls = useMemo(() => {
    if (exercise) {
      return [exercise.webm, exercise.mp4].map(url => `${APIMEDIA_HOST}${url}`)
    }
    return undefined
  }, [exercise])

  if (exercise === undefined) {
    return null
  }

  return (
    <ReactPlayer
      controls
      url={videoUrls}
      width="100%"
      height="100%"
      config={{
        attributes: { poster: `${APIMEDIA_HOST}${exercise.poster}` },
      }}
    />
  )
}

export default CeVideo
