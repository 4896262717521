import React, { useMemo } from 'react'
import ReactPlayer from 'react-player/file'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { videoKey } from '../../user/userSlice'
import { getVideoUrls } from '../exerciseHelper'
import styled from 'styled-components'

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

interface IGeneralVideoProps {
  slug: videoKey
  playing?: boolean
}

const GeneralVideo = ({ slug, playing }: IGeneralVideoProps) => {
  const userDetails = useAppSelector((state: RootState) => state.user.details)

  const videoUrls = useMemo(() => {
    return getVideoUrls(slug, userDetails)
  }, [slug, userDetails])

  if (videoUrls === undefined) { return null }

  return (
    <VideoWrapper className="my-3">
      <ReactPlayer
        className='react-player'
        playing={playing || false}
        controls
        url={videoUrls.videos}
        width="100%"
        height="100%"
        config={{
          attributes: { poster: videoUrls.poster },
        }}
      />
    </VideoWrapper>
  )
}

export default GeneralVideo