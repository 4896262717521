import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import UpdateGoal from './components/UpdateGoal'
import { goalDetails as goalDetailsSelector, Measure, MeasureType, updateGoal } from './goalSlice'

const UpdateGoalConnect = () => {
  const goalDetails = useAppSelector(goalDetailsSelector)
  const dispatch = useAppDispatch()
  
  const handleSaveGoal = (type: MeasureType, goal: Measure) => {
    const { count, minutes } = goal
    dispatch(updateGoal({ type, count, minutes }))
  }

  return (
    <UpdateGoal
      goalDetails={goalDetails}
      saveGoal={handleSaveGoal}
    />
  );
};

export default UpdateGoalConnect;