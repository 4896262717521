import { ExerciseStatus } from "../../constants/exercises";
import { baseUpdateAnswer } from "../common/exerciseHelper";
import { NsExercise, NsState, NsUserAnswer } from "./nsSlice";

export const updateAnswer = (answers: string[], state: NsState) => {
  baseUpdateAnswer(
    answers,
    state,
    (answers, index) => state.userAnswers[index].sets.push(answers),
  )
}

export interface NsSetResult {
  correct: number
  total: number
}

export interface NsResult {
  sets: NsSetResult[]
  percentage: number
}

export const getResults = (status: ExerciseStatus, exercises: NsExercise[], userAnswers: NsUserAnswer[]): NsResult[] => {
  let results: NsResult[] = []
  if (status === 'completed' || status === 'check-point') {
    results = userAnswers.map((userAnswer) => {
      const { sets: answerSets } = userAnswer
      const { sets: questionSets, keys } = exercises[userAnswer.index]
      const sets: NsSetResult[] = []      
      for (let j = 1; j < questionSets.length; j++) {
        const questionSet = questionSets[j]
        const answerSet = answerSets[j]
        const total = questionSet.length
        const correct = questionSet.reduce((acc, question, k) => keys[question - 1] === answerSet[k] ? acc + 1 : acc, 0)
        sets.push({ correct, total })
      }
      const totalCorrect = sets.reduce((acc, item) => acc + item.correct, 0)
      const grandTotal = sets.reduce((acc, item) => acc + item.total, 0)
      const percentage = Math.round(totalCorrect * 100 / grandTotal)
      return { sets, percentage }
    })
    results.reverse()
  }
  return results
}