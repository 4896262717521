import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import Register from './components/Register'
import { register, UserRegistration } from './userSlice'

const RegisterConnect = () => {
  const dispatch = useAppDispatch()
  const { processing, registerError, registerOptions } = useAppSelector((state: RootState) => state.user)

  const handleSubmit = (data: UserRegistration) => {
    dispatch(register(data))
  }

  return (
    <Register
      processing={processing}
      error={registerError}
      options={registerOptions}
      submit={handleSubmit}
    />
  )
}

export default RegisterConnect