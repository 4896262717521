import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash'
import { UserSettings as UserSettingsType } from '../userSlice'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, Card, Checkbox, Form, Input, Typography, message } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import Loading from '../../common/components/Loading'
import ChangePasswordModal from '../ChangePasswordModalConnect'

interface IUserSettingsProps {
  processing: boolean
  currentSettings?: UserSettingsType
  updateSettings: (settings: UserSettingsType) => void
  goBack: () => void
}

const { Title } = Typography

const UserSettings = ({ processing, currentSettings, updateSettings, goBack }: IUserSettingsProps) => {
  const [newSettings, setNewSettings] = useState<UserSettingsType | undefined>(undefined)
  const [saved, setSaved] = useState<boolean>(false)

  useEffect(() => {
    if (currentSettings) {
      setNewSettings(currentSettings)
    }
  }, [currentSettings])

  useEffect(() => {
    if (saved) {
      message.success('Settings updated')
      setSaved(false)
      goBack()
    }
  }, [goBack, saved])

  if (currentSettings === undefined || newSettings === undefined) { return <Loading /> }

  const readyToSubmit = !isEqual(newSettings, currentSettings) && !processing

  const handleUpdateSettings = () => {
    if (newSettings && readyToSubmit) {
      setSaved(true)
      updateSettings(newSettings)
    }
  }

  const handleReset = () => {
    setNewSettings(currentSettings)
  }

  const handleSetValue = (value: UserSettingsType) => {
    setNewSettings({
      ...newSettings,
      ...value
    })
  }

  const settingsForm = (
    <Form onFinish={handleUpdateSettings} layout="vertical">
      <Form.Item label="First Name">
        <Input
          type="text"
          value={newSettings.firstName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetValue({ firstName: e.target.value })}
          autoComplete="given-name"
          disabled={processing}
        />
      </Form.Item>
      <Form.Item label="Last Name">
        <Input
          type="text"
          value={newSettings.lastName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetValue({ lastName: e.target.value })}
          autoComplete="family-name"
          disabled={processing}
        />
      </Form.Item>
      <Form.Item label="Password">
        <ChangePasswordModal />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={newSettings?.emailSubscription}
          onChange={(e: CheckboxChangeEvent) => handleSetValue({ emailSubscription: e.target.checked })}
          disabled={processing}
        >
          Yes, I would like to receive free educational emails to improve my brain health and reduce stress.
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={newSettings?.skipWelcome}
          onChange={(e: CheckboxChangeEvent) => handleSetValue({ skipWelcome: e.target.checked })}
          disabled={processing}
        >
          Skip Introduction
        </Checkbox>
      </Form.Item>
      <Row>
        <Col>
          <Button disabled={processing} size="large" type="default" block onClick={handleReset}>Reset</Button>
        </Col>
        <Col>
          <Button loading={processing} htmlType="submit" size="large" type="primary" block disabled={!readyToSubmit}>Save</Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={10} md={8} lg={6}>
          <Card>
            <Typography>
              <Title level={4}>My Settings</Title>
              {settingsForm}
            </Typography>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default UserSettings