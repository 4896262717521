import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { Button } from 'antd'
import FooterButtons from '../../common/components/FooterButtons'
import { MmTheme } from '../mmSlice'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import styled from 'styled-components'

export interface IMmThememLevelChooserProps {
  hidden?: boolean
  themes: MmTheme[]
  pickThemeAndLevel: (theme: string, level: number) => void
}

const Choice = styled(Button)`
  height: auto;

  &.selected {
    border: solid 3px #1890ff;
  }
`

const MmThemeLevelChooser = ({hidden, themes, pickThemeAndLevel}: IMmThememLevelChooserProps) => {
  const [themeSlug, setThemeSlug] = useState<string | undefined>(undefined)
  const [level, setLevel] = useState<number | undefined>(undefined)

  const selectedTheme: MmTheme | undefined = themes.find(theme => theme.slug === themeSlug)
  const hasMoreThanOneLevel: boolean = selectedTheme !== undefined && selectedTheme.levels.length > 1

  useEffect(() => {
    if (selectedTheme) {
      if (!hasMoreThanOneLevel) {
        setLevel(0)
      } else {
        setLevel(undefined)
      }
    } 
  }, [themeSlug, selectedTheme, hasMoreThanOneLevel])

  if (hidden) { return null }

  const readyToSubmit = themeSlug !== undefined && level !== undefined

  const handleNext = () => {
    if (readyToSubmit) {
      pickThemeAndLevel(themeSlug!, level!)
      setThemeSlug(undefined)
      setLevel(undefined)
    }
  }

  const themeCols = themes.map(theme => {
    const buttonType = theme.slug === themeSlug ? 'primary' : 'default'
    return (
      <Col xs={12} sm={6} md={4} lg={3} key={theme.slug} className="mb-1">
        <Choice className="py-3" size="large" block type={buttonType} onClick={() => setThemeSlug(theme.slug)}>
          <Image draggable={false} src={`${APIMEDIA_HOST}${theme.image}`} fluid />
          <p className="my-1">{theme.name}</p>
        </Choice>
      </Col>
    )
  })

  const levelCols = selectedTheme ? selectedTheme.levels.map((_, index) => {
    const buttonType = index === level ? 'primary' : 'default'
    return (
      <Col xs={12} sm={6} md={4} lg={3} key={`level-${index}`}>
        <Button size="large" block type={buttonType} onClick={() => setLevel(index)}>Level {index + 1}</Button>
      </Col>
    )
  }) : null

  const chooseLevel = hasMoreThanOneLevel ? (
    <>
      <h5 className="mt-4">Choose Level</h5>
      <Row>
        {levelCols}
      </Row>
    </>
  ) : null

  return (
    <>
      <Container>
        <h4>Instructions</h4>
        <p>Choose a theme to start.  Find all the matched sets or pairs in each activity. Work as quickly as you can, and see your score displayed after each exercise.</p>
        <h5>Choose Theme</h5>
        <Row>
          {themeCols}
        </Row>
        {chooseLevel}
      </Container>
      <FooterButtons 
        buttons={[{ disabled: !readyToSubmit, label: "Next", action: handleNext, buttonType: "primary" }]}
      />
    </>
  )
}

export default MmThemeLevelChooser