import React, { useEffect, useState } from 'react'
import { SuggestedExercise, setNextSuggestionDue } from '../../user/userSlice'
import { Button, Modal } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { sample } from 'lodash'
import CloseModalIcon from '../../../imgs/close-modal-icon.svg'
import { Row, Col, Image } from 'react-bootstrap'
import { getSuggestedExercise, hasSuggestedExercises, isSuggestionDue } from '../../user/userHelper'
import Avatar from '../../../imgs/avatar.svg'
import { push } from 'connected-react-router'
import { categoryName } from '../../../constants/exercises'
import { APIMEDIA_HOST } from '../../../conf/hosts'

const salutaions: string[] = [
  'Hello',
  'Hi there',
  'Greetings',
  'Glad you’re here',
  'Welcome',
  'It’s great to see you',
  'Nice to see you',
  'Hola',
  'Howdy',
  'Ciao',
]

const questions: string[] = [
  'Would you like me to select an activity or exercise for you?',
  'Can I assist you in choosing an activity or exercise?',
  'Would you prefer if I chose an activity or exercise for you?',
  'Are you open to me recommending an activity or exercise?',
  'Need help deciding on an activity or exercise?',
  'Are you interested in having me pick an activity or exercise for you?',
  'Can I suggest an activity or exercise?',
  'Would it be helpful if I took the lead in selecting an activity or exercise?',
  'Can I offer my expertise in selecting an activity or exercise for you?',  
]

const ExerciseSuggestion = () => {
  const [showSuggestion, setShowSuggestion] = useState<boolean>(false)
  const [suggestedExercise, setSuggestedExercise] = useState<SuggestedExercise|undefined>(undefined)
  const [salution, setSalution] = useState<string|undefined>(sample(salutaions))
  const [question, setQuestion] = useState<string|undefined>(sample(questions))
  const { signIn, suggestionDue, details } = useAppSelector((state: RootState) => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuggestionDue(suggestionDue)) {
      setShowSuggestion(true)
    }
  }, [suggestionDue])

  const handleNoThanks = () => {
    setShowSuggestion(false)
    setSuggestedExercise(undefined)
    setSalution(sample(salutaions))
    setQuestion(sample(questions))
    dispatch(setNextSuggestionDue())    
  }

  const handleGoForIt = () => {
    if (suggestedExercise === undefined) {
      setSuggestedExercise(getSuggestedExercise(details))
    } else {
      setShowSuggestion(false)
      setSuggestedExercise(undefined)
      setSalution(sample(salutaions))
      setQuestion(sample(questions))
      dispatch(setNextSuggestionDue())
      dispatch(push(suggestedExercise.url))
    }
  }

  if (!hasSuggestedExercises(details)) { return null }

  const firstName = signIn?.firstName 

  const title = firstName ? `${salution}, ${firstName}!` : `${salution}!`

  const questionText = suggestedExercise === undefined ? (
    <>
      <h2>{title}</h2>
      <p>{question}</p>
    </>
  ) : null

  const suggestionText = suggestedExercise !== undefined ? (
    <Row className="mb-4">
      {suggestedExercise.poster && 
        <Col xs={12} sm={5} lg={4} className="mb-3">
          <Image className="img-fluid poster" src={`${APIMEDIA_HOST}${suggestedExercise.poster}`} alt="suggested-exercise" />
        </Col>
      }
      <Col xs={12} sm={7} lg={8}>
        <h3 className="text-center text-sm-left"><strong>{categoryName[suggestedExercise.type]}</strong><br />{suggestedExercise.name}</h3>
      </Col>
    </Row>
  ) : null

  const goForItLabel = suggestedExercise === undefined ? 'Ok, surprise me!' : 'Let’s go'

  return (
    <Modal
      visible={showSuggestion}
      closeIcon={<img className="close-modal" src={CloseModalIcon} alt="Close Modal" />}
      wrapClassName="exercise-suggestion-modal"
      footer={null}
      onCancel={handleNoThanks}
      width={600}
    >
      <div className="d-flex justify-content-center">
        <Image className="avatar" src={Avatar} alt="avatar" />
      </div>
      {questionText}
      {suggestionText}
      <Row>
        <Col sm={7} className="order-2 order-sm-1 d-flex d-sm-block justify-content-center">
          <Button
            size="middle"
            type="link"
            className="float-sm-left no-thanks"
            onClick={handleNoThanks}
          >
            No thanks, I’ll explore on my own.
          </Button>
        </Col>
        <Col sm={5} className="order-1 order-sm-2 d-flex d-sm-block justify-content-center">
          <Button
            size="large"
            type="primary"
            className="suggest-btn float-sm-right"
            onClick={handleGoForIt}
          >
            {goForItLabel}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

export { ExerciseSuggestion }
