import { Typography } from 'antd'
import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

export interface IDefaultSubheaderProps {
  title: string
}

const { Title } = Typography;

const SubheaderWrapper = styled.div`
  background: #EEEEEE;
  height: 100px;

  h2 {
    padding-top: 1.5rem;
    margin-bottom: 0;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 36px;
    text-transform: uppercase;
  }
`;

const DefaultSubheader = ({ title }: IDefaultSubheaderProps) => {
  return (
    <SubheaderWrapper>
      <Container>
        <Title level={2}>{title}</Title>
      </Container>
    </SubheaderWrapper>
  )
}


export { DefaultSubheader }