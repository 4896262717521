import React, { useState } from 'react'
import { Button, Drawer, Modal } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { MdLogout, MdSettings, MdAdminPanelSettings, MdOutlineMail } from 'react-icons/md'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { adminBackend, adminPortal, setShowContactUs } from '../userSlice'
import { pages } from '../../../constants/pages'
import drawerBg from '../../../imgs/drawer-bg.gif'
import closeIcon from '../../../imgs/close-icon.svg'
import { push } from 'connected-react-router'

export interface IUserMenuProps {
  firstName?: string
  userSession: boolean
  exitConfirm?: boolean
  exitDispatch?: any
  signOut: () => void
}

const Menu = styled.div`
  .ant-btn {
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    margin-bottom: 0.7rem;

    &.main-link {
      color: white;

      &.first {
        margin-top: 2rem;
      }

      &.last {
        margin-bottom: 2.5rem;
      }
    }

    svg {
      margin-right: 0.5rem;
      font-size: 20px;
    }
  }
`

const UserMenuDrawer = styled(Drawer)`
  .ant-drawer-content {
    background: url(${drawerBg}) repeat-x #001f5c;
  }
  .ant-drawer-close {
    color: white;
    float: right;
    font-size: 22px;
    margin-right: 0;
    padding: 0;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
  .ant-drawer-header-title {
    display: block;
    border-bottom: 2px solid #47abed;
  }
  .ant-drawer-title {
    clear: both;
    float: right;
    color: white;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    font-size: 26px;
    margin-bottom: 1.5rem;
  }
  .ant-drawer-header {
    border-bottom: none;
  }
  .ant-drawer-body {
    padding-top: 0;
  }
`;

const UserMenu = ({ firstName, userSession, signOut, exitConfirm, exitDispatch }: IUserMenuProps) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [nextRouteAction, setNextRouteAction] = useState<string|(() => void)|undefined>(undefined)
  const adminBackendUrl = useAppSelector(adminBackend)
  const isAdminPortal = useAppSelector(adminPortal)
  const dispatch = useAppDispatch() 

  if (!userSession) { return null }

  const handleClickAction = (action: () => void) => {
    setVisible(false)
    if (exitConfirm) {
      setShowConfirmModal(true)
      setNextRouteAction(action)
    } else {
      if (exitDispatch !== undefined) {
        dispatch(exitDispatch())
      }
      action()
    }
  }

  const handleClickLink = (route: string) => {
    setVisible(false)
    if (exitConfirm) {
      setNextRouteAction(route)
      setShowConfirmModal(true)
    } else {
      if (exitDispatch !== undefined) {
        dispatch(exitDispatch(route))
      }
      dispatch(push(route))
    }
  }

  const handleContactUs = () => {
    setVisible(false)
    dispatch(setShowContactUs(true))
  }

  const handleOkConfirm = () => {
    setShowConfirmModal(false)
    if (exitDispatch !== undefined) {
      if (typeof nextRouteAction === 'string') {
        dispatch(exitDispatch(nextRouteAction))
      } else {
        dispatch(exitDispatch())
      }
    }
    if (typeof nextRouteAction === 'string') {
      dispatch(push(nextRouteAction))
    } else if (typeof nextRouteAction === 'function') {
      nextRouteAction()
    }
  }

  const confirmModal = (
    <Modal 
      visible={showConfirmModal}
      title="Leaving Page"
      onCancel={() => setShowConfirmModal(false)}
      onOk={handleOkConfirm}
      okText="Yes"
      cancelText="No"
    >
      <p>Are you sure you want to leave the current page?</p>
    </Modal>
  )

  const menu = (
    <Menu>
      <Button type="link" block onClick={() => handleClickLink(pages.userSettings.route)}><MdSettings /> Settings</Button>      
      <Button className="main-link first" type="link" block onClick={() => handleClickLink(pages.welcome.route)}>About 15 For Me&reg;</Button>
      <Button className="main-link" type="link" block onClick={() => handleClickLink(pages.home.route)}>Dashboard</Button>
      <Button className="main-link" type="link" block onClick={() => handleClickLink(pages.cognitiveExercises.route)}>Cognitive Exercises</Button>
      <Button className="main-link" type="link" block onClick={() => handleClickLink(pages.cardioExercises.route)}>Cardio Exercises</Button>
      <Button className="main-link" type="link" block onClick={() => handleClickLink(pages.centeredBreathing.route)}>Centered Breathing</Button>
      <Button className="main-link" type="link" block onClick={() => handleClickLink(pages.m5Test.route)}>Stress Temperature Check (M5&reg;)</Button>            
      <Button className="main-link last" type="link" block onClick={() => handleClickLink(pages.goal.route)}>My Weekly Goal</Button>
      <Button type="link" block onClick={handleContactUs}><MdOutlineMail /> Contact Us</Button>
      {isAdminPortal && <Button type="link" block onClick={() => handleClickLink(pages.adminDashboard.route)}><MdAdminPanelSettings /> Admin Portal</Button>}
      {adminBackendUrl && <Button type="link" block href={adminBackendUrl} target="_blank"><MdAdminPanelSettings /> Admin Backend</Button>}
      <Button type="link" block onClick={() => handleClickAction(signOut)}><MdLogout /> Sign Out</Button>
    </Menu>
  )

  return (
    <>
      <MenuOutlined className="float-right mt-2" style={{ fontSize: 26, color: 'white' }} onClick={() => setVisible(true)} />
      {confirmModal}
      <UserMenuDrawer
        visible={visible}
        title={`Hi ${firstName}!`}
        onClose={() => setVisible(false)}
        className='user-menu-drawer'
        closeIcon={<img src={closeIcon} alt="close" />}
      >
        {menu}
      </UserMenuDrawer>
    </>
  )
}

export default UserMenu