import React, { useEffect } from 'react'
import { ExerciseStatus } from '../../../constants/exercises'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import FooterButtons from '../../common/components/FooterButtons'
import CbInProgress from './CbInProgress'
import { getTotalDuration, isReadyToStart } from '../../common/exerciseHelper'
import { CbExercise as CbExercisesType } from '../cbSlice'
import { IButton } from '../../../constants/pages'
import { Row, Col } from 'react-bootstrap'
import { Skeleton } from 'antd'
import { WatchIntroVideo } from '../../common/components/WatchIntroVideo'
import { range } from 'lodash'

export interface ICbExerciseProps {
  status: ExerciseStatus
  exercises: CbExercisesType[]
  durations: number[]
  selectedExercise?: string
  next: (repeat?: boolean) => void
  unSelect: () => void
  finish: (duration: number, startDate?: number) => void
  update: (duration: number) => void
  selectExercise: (exercise: string) => void
  goToManualEntry: () => void
}

const CbExercise = ({ status, exercises, durations, selectedExercise, next, unSelect, finish, update, selectExercise, goToManualEntry }: ICbExerciseProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    next(true)
  }

  const handleUnSelect = () => {
    window.scrollTo(0, 0)
    unSelect()
  }

  const handlePickExercise = (value: string) => {
    window.scrollTo(0, 0)
    selectExercise(value)
  }

  const handleFinish = (duration: number, startDate?: number) => {
    window.scrollTo(0, 0)
    finish(duration, startDate)
  }

  const handleUpdateDuration = (duration: number) => {
    update(duration)
  }

  const loading = status === 'start' && !isReadyToStart(status, exercises)

  const nextLabel: string = status === 'completed' ? 'Return to main menu' : 'Loading...'

  const totalDuration = getTotalDuration(durations)

  const totalMinutes = Math.round(totalDuration / 60)

  const footerButtons: IButton[] = [{ label: nextLabel, action: handleNext, buttonType: "primary", loading }]

  if (status === 'completed') {
    footerButtons.unshift({ label: 'Return to the centered breathing menu', action: handleRepeat, buttonType: "default" })
  }

  
  return (
    <>
      <ExerciseIntro status={status}>
        <Row>
          <Col>
            {range(0, 4).map((index) => 
              <Skeleton key={index} active />
            )}
          </Col>
        </Row>
      </ExerciseIntro>
      <CbInProgress
        status={status}
        exercises={exercises}
        pickExercise={handlePickExercise}
        goBackToChoose={handleUnSelect}
        finishExercise={handleFinish}
        updateDuration={handleUpdateDuration}
        selectedExercise={selectedExercise}
        goToManualEntry={goToManualEntry}
      />
      <ExerciseComplete status={status} category="centered-breathing" totalMinutes={totalMinutes} />
      <FooterButtons
        hidden={status === 'in-progress'}
        buttons={footerButtons}
      />
      {selectedExercise === undefined ? <WatchIntroVideo videoSlug="breathing" introText="Centered Breathing" /> : null}
    </>
  )
}

export default CbExercise