import { map, join } from 'lodash'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_HOST } from '../conf/hosts'
import { ChangePassword, UserDetails, UserRegistration, UserSettings } from '../features/user/userSlice'
import { UpdateGoalPayload } from '../features/goal/goalSlice'
import { ExerciseStatus } from '../constants/exercises'
import { IEntry } from '../features/common/exerciseHelper'

interface ICommonHeaders {
  Accept: string
  'Content-Type': string,
  'The-Timezone-IANA': string,
}

export const commonHeaders: ICommonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'The-Timezone-IANA': Intl.DateTimeFormat().resolvedOptions().timeZone
}

export type QueryParams = {
  [key: string]: string
}

const EXERCISES_URL = `${API_HOST}exercises/`

const USERS_URL = `${API_HOST}users/`

const fetchExercises = (exercise: string, authToken: string, queryParams?: QueryParams): Promise<AxiosResponse> => {
  let url = `${EXERCISES_URL}${exercise}/`
  if (queryParams) {
    url += `?${join(map(queryParams, (value, key) => `${key}=${value}`), '&')}`
  }
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
  }
  return axios(request)
}

export const fetchFlipTheImageExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('flip-the-image', authToken)

export const fetchNumberSymbolExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('number-symbol', authToken)

export const fetchWordThemeExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('word-theme', authToken)

export const fetchMemoryMatchExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('memory-match', authToken)

export const fetchColorIllusionExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('color-illusion', authToken)

export const fetchCenteredBreathingExercises = (authToken: string, queryParams?: QueryParams): Promise<AxiosResponse> => {
  return fetchExercises('centered-breathing', authToken, queryParams)
}

export const fetchCardioExercises = (authToken: string): Promise<AxiosResponse> => fetchExercises('cardio-exercises', authToken)

export const checkCoupon = (coupon: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}coupon-validation/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { coupon },
    url,
  }
  return axios(request)
}

export const signUpAccount = (coupon: string, email: string, password: string, firstName: string, lastName: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}sign-up/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { coupon, email, password, firstName, lastName },
    url,
  }
  return axios(request)
}

export const checkEmailAddress = (coupon: string, email: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}email-validation/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { coupon, email },
    url,
  }
  return axios(request)
}

export const authenticate = (username: string, password: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}token/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { username, password },
    url,
  }
  return axios(request)
}

export const refreshToken = (refresh: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}token/refresh/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { refresh },
    url,
  }
  return axios(request)
}

export const forgetPassword = (email: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}forget-password/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { email },
    url
  }
  return axios(request)
}

export const resetPassword = (uid: string, token: string, password: string): Promise<AxiosResponse> => {
  const url = `${USERS_URL}reset-password/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'POST',
    data: { uid, token, password },
    url
  }
  return axios(request)
}

const fetchAuthorizedContent = (authToken: string, path: string, queryParams?: QueryParams ) => {
  let url = `${USERS_URL}${path}/`
  if (queryParams) {
    url += `?${join(map(queryParams, (value, key) => `${key}=${value}`), '&')}`
  }
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
  }
  return axios(request)
}

export const fetchUserDetails = (authToken: string) => fetchAuthorizedContent(authToken, 'details')

export const patchUserDetails = (authToken: string, userDetails: UserDetails) => {
  const url = `${USERS_URL}details/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'PATCH',
    data: userDetails,
    url,
  }
  return axios(request)
}

export const fetchM5URL = (authToken: string) => fetchAuthorizedContent(authToken, 'm5-url')

export const updateGoal = (authToken: string, goal: UpdateGoalPayload) => {
  const url = `${USERS_URL}goal-update/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'POST',
    data: goal,
    url,
  }
  return axios(request)
}

export const fetchGoalAchievement = (authToken: string, dateOfWeek?: string) => {
  const queryParams = dateOfWeek !== undefined ? { w: dateOfWeek } : undefined
  return fetchAuthorizedContent(authToken, 'goal-achievement', queryParams)
} 

export const updateExercise = (authToken: string, id: string, category: string, exercise: string, action: ExerciseStatus, duration?: number, startDate?: number) => {
  const url = `${USERS_URL}exercise-update/${id}/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'POST',
    data: { category, exercise, action, duration, startDate },
    url,
  }
  return axios(request)
}

export const fetchContactUsSubjects = () => {
  const url = `${USERS_URL}contact-us/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders },
    method: 'GET',
    url,
  }
  return axios(request)
}

export const submitContactUs = (subject: string, body: string, contact_url: string, authToken?: string, email?: string, name?: string, phone?: string) => {
  const url = `${USERS_URL}contact-us/`
  const headers: any = { ...commonHeaders }
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`
  }
  const request: AxiosRequestConfig = {
    headers,
    method: 'POST',
    data: { subject, body, url: contact_url, email, name, phone },
    url,
  }
  return axios(request)
}

export const bulkUpdateExercise = (authToken: string, exercise: string, entries: IEntry[]) => {
  const url = `${USERS_URL}exercise-manual-entries/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'POST',
    data: { exercise, entries },
    url,
  }
  return axios(request)
}

export const fetchUserSettings = (authToken: string) => {
  const url = `${USERS_URL}settings/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
  }
  return axios(request)
}

export const updateUserSettings = (authToken: string, userSettings: UserSettings) => {
  const url = `${USERS_URL}settings/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'PATCH',
    data: { ...userSettings },
    url,
  }
  return axios(request)
}

export const changePassword = (authToken: string, passwords: ChangePassword) => {
  const url = `${USERS_URL}change-password/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'POST',
    data: { ...passwords },
    url,
  }
  return axios(request)
}

export const fetchRegisterOptions = (authToken: string) => {
  const url = `${USERS_URL}register-options/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
  }
  return axios(request)
}

export const register = (authToken: string, registration: UserRegistration) => {
  const url = `${USERS_URL}register/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'POST',
    data: { ...registration },
    url,
  }
  return axios(request)
}
