import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExerciseStatus, MinMaxDefault } from "../../constants/exercises"
import { updateDuration, moveNext, quitExercise } from "./ceHelper"

export interface CeExercise {
  name: string
  slug: string
  preview: boolean
  blob: string
  mp4: string | null
  webm: string | null
  poster: string | null
  thumbnail: string | null
}

export const ceLimits: MinMaxDefault = {
  min: 1, 
  max: 120, 
  default: 30 
}

export interface CeState {
  status: ExerciseStatus  
  id?: string
  exercises: CeExercise[]
  selectedExercise?: string
  durations: number[]
  startDate?: number 
}

const initialState: CeState = {
  status: 'start', 
  exercises: [],
  durations: []
}

interface CeExercisesPayload {
  id: string
  exercises: CeExercise[]
}

export interface CeExerciseFinishPayload {
  duration: number
  startDate?: number
}

export const ceSlice = createSlice({
  name: 'cardioExercises',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<CeExercisesPayload>) => {
      state.id = action.payload.id
      state.exercises = action.payload.exercises
      moveNext(state)
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      moveNext(state, action.payload)
    },
    repeat: (state) => {
      moveNext(state, true)
    },
    quit: (state) => {
      quitExercise(state)
    },
    select: (state, action: PayloadAction<string|undefined>) => {
      state.selectedExercise = action.payload
    },
    update: (state, action: PayloadAction<number>) => {
      updateDuration(state, action.payload)
    },
    finish: (state, action: PayloadAction<CeExerciseFinishPayload>) => {
      updateDuration(state, action.payload.duration)
      state.startDate = action.payload.startDate
      moveNext(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction('cardioExercises/requestQuit')

export const { installExercises, next, repeat, quit, select, update, finish, reset } = ceSlice.actions

export default ceSlice.reducer