import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch } from '../../app/hooks'
import { pages } from '../../constants/pages'
import CognitiveExercises from './components/CognitiveExercises'


const CognitiveExercisesConnect = () => {
  const dispatch = useAppDispatch()
  
  return (
    <CognitiveExercises
      gotoFlipTheImage={() => dispatch(push(pages.flipTheImage.route))}
      gotoNumberSymbol={() => dispatch(push(pages.numberSymbol.route))}
      gotoWordTheme={() => dispatch(push(pages.wordTheme.route))}
      gotoMemoryMatch={() => dispatch(push(pages.memoryMatch.route))}
      gotoColorIllusion={() => dispatch(push(pages.colorIllusion.route))}
    />
  )
}

export default CognitiveExercisesConnect