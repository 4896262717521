import React from 'react'

const TermsOfUse = () => (
  <>
    <p>The 15 for Me Service is provided by&nbsp;Mansbach Health Tools, LLC&nbsp;("We" or "Us" or equivalent pronouns) from a website located at www.enrichvisits.com and subdomains thereof, and mobile applications provided by Us or that are otherwise authorized to obtain data from such domains and subdomains (collectively the &ldquo;Services&rdquo;).&nbsp;&nbsp;Upon use of the Services for any purpose, including but not limited to viewing the content on the Services, these terms of use (the "Terms of Use") are a contract by and between you the user ("You" or equivalent pronouns) and Us.&nbsp;&nbsp;Your use of the Services constitutes an agreement to these Terms of Use, Our Privacy Policy and Our Acceptable Use Policy.&nbsp;We do not consent to Your use of the Services unless You have agreed to these Terms of Use, therefore if You do not agree to these Terms of Use, immediately cease any and all use of the Services; You do not have permission to access the Services unless You agree to these Terms of Use, and You conform Your conduct to the terms and conditions herein.&nbsp;</p>
    <p>You may not share, disclose or misuse your login credentials to the Service.&nbsp;&nbsp;You must immediately report to us any actual or suspected loss of Your login credentials.&nbsp;&nbsp;We may suspend Your access to the Service at any time without notice if we suspect that Your login credentials have been lost, stolen or misused.&nbsp;&nbsp;This right is for Our benefit only, We do not undertake any duty to monitor Your login credential use, or to notify you if We suspect misuse.&nbsp;&nbsp;We may log certain information about You and Your machine used to login, and We may elect to apply additional verification procedures to Your login attempts, which may include secondary verification such as sending you additional temporary secondary codes to use when logging in.</p>
    <p>By accessing the Services, You acknowledge, represent, warrant and covenant that: (i) You have read and understand these Terms of Use; (ii) You have and will access the Services and use Information made available on the Services solely for Your own personal use, as more fully described below; and (iii) You further covenant to comply with all applicable international, country, federal and state laws, rules and regulations with respect to Your use of the Services.</p>
    <p><strong>Changes and Notice.</strong>&nbsp;We may change these Terms of Use and/or Our Privacy Policy and Acceptable Use Policy at any time by posting revisions to Our Services. Your use and continued usage after such changes are posted constitutes acceptance of each of the revised Terms of Use and/or Privacy Policy and Acceptable Use Policy. We encourage You to familiarize Yourself with Our policies. Our Privacy Policy may be found at a link for "Privacy Policy" or equivalent on the Services.&nbsp;</p>
    <p><strong>Eligibility; No Access by Children.&nbsp;</strong>Use of&nbsp;the Services is void where prohibited. The Services are intended solely for users who are eighteen (18) years of age or older. Any registration by, use of or access to the Services by anyone under 18 is unauthorized, unlicensed and in violation of these Terms of Use. By using the Services, You represent and warrant that You are 18 or older and that You agree to and to abide by all of the terms and conditions of these Terms of Use.&nbsp;&nbsp;We may terminate Your access, delete Your profile and any content or information that You have posted on the Services and/or prohibit You from using or accessing the Services (or any portion, aspect or feature of the Services) for any reason, or no reason, at any time in Our sole discretion, with or without notice, including if We believe that You are under 18.&nbsp;</p>
    <p><strong>Ownership Acknowledgment.&nbsp;&nbsp;</strong>All rights and property interests, including but not limited to patents, copyrights, trade secrets, trademarks and other proprietary rights recognized under applicable law in or relating to the Services and all modifications, adaptation, derivative works and enhancements thereto, are owned exclusively by Us and/or Our licensors, and all such rights not expressly granted herein are reserved to Us.&nbsp;&nbsp;Notwithstanding the above, We shall not acquire through this Agreement any ownership interest in Your personal information (defined herein to be your email address, regular address, phone and fax number, and any payment information relating to you).</p>
    <p><strong>Copyrights and Limited License.</strong>&nbsp;All content on and in the Website (other than content we have licensed from third parties or that is in the public domain) is our proprietary property and copyrighted "Copyright 2022 Mansbach Health Tools, LLC" (the "Information"). We grant You a limited, royalty free, non-exclusive, revocable license to make use of the Information for Your own purposes.&nbsp;&nbsp;All other rights are expressly reserved.&nbsp;For the avoidance of doubt, and including by way of example and not limitation, except to the extent necessary to make use of the Services (or, to the extent the Services allow you to connect your data to a third-party, use of such functionality), You are not authorized to use, and You specifically covenant that that You will not use, the Information on the Services to: (i) provide access to the Information to any third person, or to otherwise distribute, make available, transmit or otherwise disseminate the Information to anyone else or to allow someone else to use the Information on the Services; (ii) download any Information on this Service (except for Your personal use); (iii) sell the Information downloaded (or copied in another form) for money, exchange or other consideration; (iv) redistribute the Information for free to anyone; (v) make any print copies of the Information other than for Your personal use; (vi) republish the Information; (vii) make any alterations, additions or other modifications to the Information; or (viii) use the Information to compete, directly or indirectly, with Us.</p>
    <p><strong>No License to Trademarks.</strong>&nbsp;All trademarks, service marks, tradenames or other identifying marks displayed in respect of the Services (the "Marks") are owned by Us and/or Our licensors.&nbsp;&nbsp;The Services may also contain trademarks of third parties; such marks are owned by third parties and unless expressly stated, We are not affiliated with or associated with the owners of such third party marks, and such marks are used on the site solely to identify the goods and services of such owners and not to represent any affiliation with such trademark owners.&nbsp;&nbsp;Except as applicable law may otherwise provide, or except as agreed to in writing by Us, We do not consent to any use of Our Marks by any person and do not grant You any right to use the Marks displayed on this Services.</p>
    <p><strong>Misuse of the Services.</strong>&nbsp;You violate these Terms of Use and engage in unauthorized use of Our computer systems when You make any use of the Services that is not in conformity to the limitations and permitted uses above, or that violate Our Acceptable Use Policy set out below, which is incorporated by reference.</p>
    <p><strong>Available Remedies.</strong>&nbsp;When We become aware of any incorrect Information, harmful activities or if We receive any claim from a third party, in Our sole and unfettered discretion We may take steps to stop the activity, such as removing the offending materials, denying the offender access to the Services, removing or deleting the Your account (and preserving the related data in case of any claim by a third party) or any other action We deem appropriate. We may be required to remove or block access to content or postings upon receipt of a proper notice of copyright infringement, and We may do so by disabling an entire page or record, instead of disabling solely the allegedly infringing content.&nbsp;&nbsp;&nbsp;The sole and exclusive remedy for any claim against Us shall be for Us to repair, replace, remove or otherwise correct the Information.</p>
    <p><strong>Third-party content.</strong>&nbsp;To the extent Information on the Services include content from third parties (such as advertising, promotions, coupons) We are not responsible for errors in such third-party information.&nbsp;&nbsp;We do not vouch for, review, validate, accept responsibility for or otherwise undertake any liability arising from, third party content, products, services or other actions of third parties.&nbsp;&nbsp;</p>
    <p><strong>Disclaimers as to Information; Limitations of Liability.</strong></p>
    <p>WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY OF THE INFORMATION FOR ANY PURPOSE. THE SERVICES AND ALL INFORMATION IS PROVIDED TO YOU "AS IS" WITHOUT WARRANTY OF ANY KIND AND ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE SERVICES AND/OR THE INFORMATION, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, TITLE AND NON-INFRINGEMENT, ARE SPECIFICALLY DISCLAIMED. IN NO EVENT SHALL WE AND/OR OUR CONTRIBUTORS BE LIABLE FOR ANY SPECIAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS OF USE, DATA OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, ARISING OUT OF OR IN CONNECTION WITH THE INFORMATION OR THE SERVICES. THERE IS NO WARRANTY THAT ANY INFORMATION, SERVICES, CONTENT, COMPUTER PROGRAM OR ANY EFFORTS PROVIDED BY US WILL FULFILL ANY OF YOUR PARTICULAR PURPOSES OR NEEDS.&nbsp;YOUR SOLE REMEDY IN THE EVENT YOU HAVE ANY CLAIM AGAINST US IN RESPECT OF THE SERVICES OR THE INFORMATION IS FOR US TO REPAIR, REPLACE OR OTHERWISE CORRECT (OR REMOVE) THE INFORMATION.&nbsp;&nbsp;IF ANY OF THE ABOVE LIMITATIONS FAIL TO APPLY, YOU AGREE OUR MAXIMUM LIABILITY TO YOU IS $10 FOR ANY BREACH OR OTHER VIOLATION OF YOUR RIGHTS.</p>
    <p><strong>No Linking; No Framing.</strong>&nbsp;&nbsp;Except as contemplated solely to use the Services as intended, We do not consent to publicly linking any part of our Services that are protected by a login, or "framing" or displaying any of Our Information in connection with, inside of, in relation to, or otherwise as part of, any other website or other information provider.&nbsp;</p>
    <p><strong>Electronic Communications.</strong>&nbsp;You agree to transact business with Us using electronic communications, either via web forms on the Services, or via email. Electronic communications will be deemed received by You when Your email or other electronic communication system reports that any email/communication We send You, has been received by Your system, regardless of whether You ever actually open or read such communication. We may, but are not required, to use return receipt requests. Unless specifically required by applicable law, You consent to receive all notices, information, and other communications from Us concerning any subject matter, via electronic email or other electronic communication. It is Your responsibility to maintain a valid email address and electronic communication service addresses, and We may terminate Your access to the Services at any time that We determine You are in violation of these Terms of Use, or fail to maintain a valid electronic communication address.</p>
    <p><strong>User Content Posted on the Services.</strong>&nbsp;You are solely responsible for the photos, profiles (including Your name, image, and likeness), messages, notes, text, information, music, video, images, advertisements, listings, and other content that You upload, publish or display on or through the Services, or transmit to or share with other users (collectively the "User Content"). You may not post, transmit, or share User Content on the Services that You did not create or that You do not have permission to post. You understand and agree that We may, but are not obligated to, review the Services and may delete or remove (without notice) any content or User Content in Our sole discretion, for any reason or no reason, including User Content that in Our sole judgment violates these Terms of Use or the&nbsp;<a href="#acceptable-user-policy">&nbsp;Acceptable Use Policy</a>, or which might be offensive, illegal, or that might violate the rights, harm, or threaten the safety of users or others. You are solely responsible at Your sole cost and expense for creating backup copies and replacing any User Content You post or store on the Services or provide Us.</p>
    <p>When You post User Content to the Services, You authorize and direct Us to make such copies thereof as We deem necessary in order to facilitate the posting and storage of the User Content on the Services. By posting User Content to any part of the Services, You automatically grant, and You represent and warrant that You have the right to grant, to Us an irrevocable, perpetual, non-exclusive, transferable, fully paid, worldwide license (with the right to sublicense) to use, copy, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part) and distribute such User Content for any purpose, commercial, advertising, or otherwise, on or in connection with the Services or the promotion thereof, to prepare derivative works thereof, or incorporate into other works, such User Content, and to grant and authorize sublicenses of the foregoing. You may remove Your User Content from the Services at any time. If You choose to remove Your User Content, the license granted above will automatically expire, however You acknowledge that We may retain archived copies of Your User Content.&nbsp;</p>
    <p>We do not assert any ownership over Your User Content; rather, as between Us and You, subject to the rights granted to Us in these Terms of Use, You retain full ownership of all of Your User Content and any intellectual property rights or other proprietary rights associated with Your User Content.</p>
    <p><strong>Termination.</strong>&nbsp;We may terminate Your access to the Services for any reason, or no reason, at any time in Our sole discretion, with or without notice.</p>
    <p><strong>Submissions.</strong> You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Services ("Submissions"), provided by You to Us are non-confidential and shall become Our sole property. We shall own exclusive rights, including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to You.&nbsp;&nbsp;</p>
    <p><strong>Indemnity.</strong>&nbsp;You agree to defend, indemnify, and hold harmless Us, Our affiliates, and Our respective officers, directors, employees and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from (i) any User Content or other material You provide to the Services, (ii) Your use of any of Our Information or Our Marks, (iii) Your use of the Services, (iv) Your conduct in connection with the Services or with other users of the Services, (v) Your breach of the terms of these Terms of Use, the Acceptable Use Policy, or the Privacy Policy, or (vi) Your violation of any applicable international, country, federal and state laws, rules and regulations.&nbsp;</p>
    <p><strong>Arbitration</strong>.&nbsp;&nbsp;Except for solely equitable claims which may be pursued immediately and directly in a court of competent jurisdiction, all claims by a party for breach of this Agreement that are not resolved by mutual written agreement shall be settled by binding arbitration before a single arbitrator under the rules of the American Arbitration Association. The location of such arbitration shall be in the State that Our principal offices are located at the time the dispute arises, or at a location determined by the arbitrator(s).&nbsp;&nbsp;&nbsp;The parties shall mutually agree on an arbitrator or if they cannot, the arbitrator shall be appointed under the rules of the American Arbitration Association. Arbitration may be commenced by either party by giving written notice to the other party that the dispute has been referred to arbitration under this Section. Any award rendered by the arbitrator shall be conclusive and binding upon the parties, provided that any such award shall be accompanied by a written opinion giving the reasons for the award. This Section shall be specifically enforceable by the parties and the decision of the arbitrator may be filed under the Federal Arbitration Act or any equivalent state arbitration act as an award of arbitration and shall be a judgment of record in such court; provided however, that either party may appeal the decision of the arbitrator as if such decision had been rendered by such court sitting without a jury, subject to all court rules and procedures for collection and stay of judgment. Each party shall pay its own expenses of arbitration and the expenses of the arbitrator shall be equally shared. In all cases each of the parties IRREVOCABLY WAIVE ANY AND ALL RIGHT TO A DEMAND A TRIAL BY JURY of any claim arising out of or related to this Agreement or the performance or non performance of either party.</p>
    <p><strong>Governing Law; Choice of Forum.</strong>&nbsp;These Terms of Use and Your use of the Services shall be governed and construed in accordance with the laws of the State of Maryland, exclusive of its conflicts of laws principles. You consent and submit to the sole and exclusive jurisdiction and venue over any action, suit, or other legal proceeding that may arise out of or in connection with these Terms of Use, by the state or federal courts of the State of Maryland.</p>
    <p><strong>Reservation of rights</strong>.&nbsp;&nbsp;&nbsp;We reserve all rights not expressly granted herein. Except as set forth in these Terms of Use, no express or implied license or right of any kind is granted to You regarding the Services. Except as expressly permitted herein, You covenant not to know, use, produce, receive, reproduce, copy, market, sell, distribute, transfer, translate, modify, adapt, disassemble, decompile or reverse engineer the Services or any software comprised in the Services, or to create derivative works based on, or obtain possession of any source code of, or technological material relating to, the Services or any portion thereof and You shall not (except as expressly permitted in this Agreement) license, sublicense, sell, resell, transfer, assign, distribute, provide as a service bureau or software-as-service or otherwise commercially exploit or make available to any third party the Services in any way.</p>
    <p><strong>External tools</strong>.&nbsp;&nbsp;When using a standard browser, you may configure the browser with certain settings, and also some browsers have privacy plugins, that allow you to control what programs, data and information your browser stores and sends back ("Privacy Tools").&nbsp;&nbsp;For example, several Privacy Tools will block advertiser cookies, prevent flash from running, block advertising tracking, and so on.&nbsp;&nbsp;Our Services do not seek to circumvent your ability to use such Privacy Tools, however, our Services may not function correctly if certain Privacy Tools are enabled.&nbsp;&nbsp;Due to the large number of browsers, browser versions, plugins, versions of plugins, and operating systems, we are unable to test our site against these Privacy Tools to determine whether it will function correctly.&nbsp;</p>
    <p>Questions or comments regarding these Terms of Use should be directed to Us care of www.thebcat.com.</p>
    <h5 id="acceptable-use-policy">ACCEPTABLE USE POLICY</h5>
    <p>ACCEPTABLE USE OF 15 FOR ME SERVICE. You agree as follows in respect of the acceptable and unacceptable uses of the Services (as defined in the Terms of Use). You will not and agree not to:</p>
    <ol type="a">
      <li>use or attempt to use the Services for any illegal, abusive or unethical activities as determined by Us in Our sole discretion, which include by example and not limitation, transmission of illegal pornographic material, invasion of privacy, defamation, infringement of another person's copyright, trade secret, patent, trademark or other intellectual property rights, hacking or cracking (gaining unauthorized use of a computer), distributing computer viruses or other malware, gambling, harassment of another or distribution of other harmful materials, code, programs or scripts.</li>
      <li>send unsolicited bulk mail messages ("junk mail" or "spam").&nbsp;</li>
      <li>breach any applicable data protection statute by, for example, sending or storing personal information (as defined in applicable data protection statutes) in an unencrypted form.</li>
      <li>impersonate another person, falsify the source of TCP/IP packets, spoof any portion of an email or TCP/IP header, or misrepresent authorization to act on behalf of others or Us. All messages and packets transmitted via the Internet Service should correctly identify the sender and source; You may not alter the attribution of origin in electronic mail messages or posting.&nbsp;</li>
      <li>undermine the security or integrity of computing systems or networks or gain unauthorized access or control over Our computers or any other person's computers.&nbsp;</li>
      <li>permit another person to use Your resources or the Services to conduct any of the matters You are prohibited from doing.&nbsp;</li>
      <li>distribute any files in any peer-to-peer or file sharing arrangement, where it is possible that in such file sharing process any of the Services may be used to distribute copyrighted materials to or from persons who are not authorized to receive, copy, distribute or use them.</li>
      <li>take any action that would cause Us to violate any terms of an upstream service provider's acceptable use policy.</li>
    </ol>
    <p>The above list of acceptable and unacceptable uses of the Service is not intended to be exhaustive, but rather illustrative of the range of unacceptable uses. We have the right in Our sole discretion to determine whether any particular conduct is an acceptable use of the Services. In cases where We determine You engaged in conduct that You reasonably believed did not violate this policy in good faith, We will provide You electronic notice of Our determination that such conduct is not an acceptable use, and provided You cease such conduct not later than 24 hours after such notice, You will not be considered to be in breach of these Terms of Use.</p>
    <p><strong>Communications Decency Act Notice.</strong>&nbsp;&nbsp;We notify you that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist you in limiting access to material that is harmful to minors.&nbsp;&nbsp;You can discover some of the providers of such parental control technology by searching for "parental control software" using a major search engine.</p>  </>
)



export default TermsOfUse