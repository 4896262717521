import { call, delay, put, select, takeLatest } from "@redux-saga/core/effects"
import { PayloadAction } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { LOCATION_CHANGE } from "connected-react-router"
import { get } from "lodash"
import { fetchGoalAchievement, updateGoal as updateGoalCall } from "../api/ApiManager"
import { RootState } from "../app/store"
import { MAX_FETCH_GOAL_TRIALS } from "../constants/goal"
import { pages } from "../constants/pages"
import { getSelectedGoalDetails } from "../features/goal/goalHelper"
import { GoalDetails, installGoalDetails, updateGoal, UpdateGoalPayload, setDateOfWeek, resetAllGoalDetails } from "../features/goal/goalSlice"
import { getAuthToken } from "../features/user/userHelper"
import { signOut } from "../features/user/userSlice"

function* getGoalAchievementsCall(current: boolean, trial: number): any {
  const state: RootState = yield select()
  const goal = state.goal
  if (!getSelectedGoalDetails(goal, current)) {
    const authToken = getAuthToken(state)
    if (trial > 0) {
      if (authToken !== undefined) {
        try {
          const response = (yield call(fetchGoalAchievement, authToken, !current ? goal.selectedDateOfWeek: undefined)) as AxiosResponse
          const goalDetails: GoalDetails = get(response, 'data')
          yield put(installGoalDetails(goalDetails))  
        } catch (err) {
          yield delay(1000)
          yield getGoalAchievementsCall(current, trial - 1)
        }
      } else {
        yield delay(1000)
        yield getGoalAchievementsCall(current, trial - 1)
      }
    }
  }
}

function* getGoalAchievements() {
  const state: RootState = yield select()
  const router = state.router
  const pathname = get(router, 'location.pathname') as string | undefined
  if (pathname === pages.goal.route) {
    yield getGoalAchievementsCall(false, MAX_FETCH_GOAL_TRIALS)
  } else if (pathname === pages.home.route || pathname === pages.setGoal.route) {
    yield getGoalAchievementsCall(true, MAX_FETCH_GOAL_TRIALS)
  }
}

function* processUpdateGoal(action: PayloadAction<UpdateGoalPayload>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    try {
      const response = (yield call(updateGoalCall, authToken, action.payload)) as AxiosResponse
      const goalDetails: GoalDetails = get(response, 'data')
      yield put(installGoalDetails(goalDetails))
    } catch (err) {
      yield delay(1000)
      yield getGoalAchievementsCall(true, MAX_FETCH_GOAL_TRIALS)
    }
  }
}

function* processResetGoal() {
  yield put(resetAllGoalDetails())
}

export function* goalWatcher() {
  yield takeLatest(LOCATION_CHANGE, getGoalAchievements)
  yield takeLatest(updateGoal, processUpdateGoal)
  yield takeLatest(setDateOfWeek, getGoalAchievements)
  yield takeLatest(signOut, processResetGoal)
} 
