import moment from "moment";
import { concat, forEach, get, slice } from "lodash";
import { QueryParams } from "../../api/ApiManager";
import { CHART_COLORS } from "../../constants/portalAdmin";
import { Charts, FilterOptions, Filters } from "./portalAdminSlice";

export const MONTH_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_FORMAT = 'MMM YYYY'

export const isExpired = (object: FilterOptions | Charts | undefined) => {
  return object === undefined || 
    object.exp === undefined || 
    Math.round(Date.now() / 1000) >= object.exp
}

export const getQueryParams = (filters: Filters): QueryParams => {
  const queryParams: QueryParams = {}
  const loop = {
    c: 'companies',
    i: 'industries',
    s: 'sizes'
  }
  forEach(loop, (field, key) => {
    const values = get(filters, field) as string[]
    if (values.length) {
      queryParams[key] = values.join(',')
    }
  })

  if (filters.useCustomRange && filters.customRange.alpha) {    
    const ranges = {a: 'alpha', o: 'omega'}
    forEach(ranges, (field, key) => {
      const month = get(filters.customRange, field) as string | null
      if (month) {
        queryParams[key] = month 
      }
    })
  } else {
    queryParams.a = moment().startOf('month').subtract(filters.lastNumOfMonths, 'months').format(MONTH_FORMAT)
    if (filters.lastNumOfMonths !== 0) {
      queryParams.o = moment().endOf('month').subtract(1, 'months').format(MONTH_FORMAT)
    }
  }
  return queryParams 
}

export const getChartColors = (numColor: number, diffEnds?: boolean): string[] => {
  let colors = [...CHART_COLORS]
  while (colors.length < numColor) {
    colors = concat(colors, CHART_COLORS)
  }
  const output = slice(CHART_COLORS, 0, numColor)
  const last = output.length - 1
  if (diffEnds && output[0] === output[last]) {
    output[last] = output[1]
  }
  return output
}

export const getMonthDisplay = (filters: Filters): string => {
  let output: string = ''
  if (filters.useCustomRange && filters.customRange.alpha) {
    output += moment(filters.customRange.alpha).format(DISPLAY_FORMAT)
    if (filters.customRange.omega?.substring(0, 7) !== filters.customRange.alpha.substring(0, 7)) {
      output += ` ${String.fromCharCode(8212)} ${moment(filters.customRange.omega || undefined).format(DISPLAY_FORMAT)}`
    }
  } else {
    output += moment().startOf('month').subtract(filters.lastNumOfMonths, 'months').format(DISPLAY_FORMAT)
    if (filters.lastNumOfMonths > 0) {
      output += ` ${String.fromCharCode(8212)} ${moment().startOf('month').subtract(1, 'months').format(DISPLAY_FORMAT)}`
    }
  }
  return output
}

export const isReadyToGetCharts = (filters: Filters, forced?: boolean): boolean => {
  const hasRequirement: boolean = !filters.useCustomRange || !!filters.customRange.alpha 
  return hasRequirement && (!filters.fetched || !!forced)
}

export const formatMonthLabel = (index: number, labels: string[]): string => {
  const label = labels[index]
  if (labels.length < 6) {
    return `${label}`
  }
  if (labels.length < 9) {
    return index % 2 === 1 ? `${label}` : ''
  }
  return label.match(/^dec|mar|jun|sep/i) ? `${label}` : ''
}

export const getChartConfig = (enlarged: boolean | undefined) => ({
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: enlarged ? 16/9 : 1,
})
