import React, { useEffect, useState } from 'react'
import { Modal, Button, Input, Select, message } from 'antd'
import { IButton } from '../../../constants/pages'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { contactSubjects, contactUsStatus, requestContactUsSubjects, setProcessedContactUs, setShowContactUs, contactUs } from '../userSlice'
import { SelectValue } from 'antd/lib/select'
import { RootState } from '../../../app/store'
import { getAuthToken } from '../userHelper'

const OTHER = 'General Question'

const ContactUsModal = () => {
  const authToken = useAppSelector((state: RootState) => getAuthToken(state))
  const visible = useAppSelector((state: RootState) => state.user.showContactUs)
  const subjectOptions = useAppSelector(contactSubjects)
  const { processing, processed } = useAppSelector(contactUsStatus)
  const dispatch = useAppDispatch()
  const needOtherOptions = (): boolean => subjectOptions !== undefined && subjectOptions.length > 0

  const [subject, setSubject] = useState<string | undefined>(undefined)
  const [otherSubject, setOtherSubject] = useState<string | undefined>(undefined)
  const [body, setBody] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [name, setName] = useState<string | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(undefined)

  const handleDismiss = () => {
    setSubject(undefined)
    setOtherSubject(undefined)
    setBody(undefined)
    setEmail(undefined)
    setName(undefined)
    setPhone(undefined)
    dispatch(setShowContactUs(false))
  }

  useEffect(() => {
    if (subjectOptions === undefined) {
      dispatch(requestContactUsSubjects())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectOptions])

  useEffect(() => {
    if (processed === 'success') {
      message.success('Thanks for your message.  We will get back to you very soon', () => {
        dispatch(setProcessedContactUs(undefined))
      })
      handleDismiss()
    } else if (processed === 'fail') {
      message.error('There is an error submitting your message.  Please try again later', 3, () => {
        dispatch(setProcessedContactUs(undefined))
      })
    } else if (processed === 'emailError') {
      message.warning('Please enter a valid email', 5, () => {
        dispatch(setProcessedContactUs(undefined))
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processed])

  const readyToSubmit: boolean = (!!subject || !!otherSubject) && !!body && (!!authToken || (!!email && !!name))

  const handleSubmit = () => {
    if (readyToSubmit) {
      const finalSubject = (subject !== OTHER ? subject : otherSubject) as string
      dispatch(contactUs({ subject: finalSubject, body: body!, email, name, phone }))
    }
  }

  const cancel: IButton = { label: "Cancel", action: handleDismiss }
  const submit: IButton = { label: "Submit", action: handleSubmit, buttonType: "primary", disabled: !readyToSubmit || processing }

  const buttons: IButton[] = [cancel, submit]

  const ModalActionButton = ({ button }: { button: IButton }) => {
    const buttonType = button.buttonType !== 'danger' && button.buttonType !== 'success' ? button.buttonType : 'default'
    return (
      <Button type={buttonType} onClick={() => button.action()} disabled={button.disabled} >
        {button.label}
      </Button>
    )
  }

  const handleSelectChange = (value: SelectValue) => {
    if (value === OTHER) {
      setSubject(OTHER)
    } else if (value) {
      setSubject(value.toString())
    }
  }

  const needOther = needOtherOptions()

  const showOtherSubject: boolean = !needOther || subject === OTHER 

  return (
    <Modal
      visible={visible}
      title={<strong>Contact Us</strong>}
      onCancel={handleDismiss}
      footer={buttons.map((button, index) => <ModalActionButton key={`button-${index}`} button={button} />)}
    >
      <p>Let us know what you love about 15 for Me&reg; and what we can be doing better.  We do our best to respond as quickly as possible - you should hear from us within 72 hours.</p>
      {!authToken && <>
        <p className='mb-1'><strong>Name</strong></p>
        <Input className='mb-3' value={name} size="large" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)} />
        <p className='mb-1'><strong>Email</strong></p>
        <Input type="email" className='mb-3' value={email} size="large" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} />
        <p className='mb-1'><strong>Phone</strong> (optional)</p>
        <Input className='mb-3' value={phone} size="large" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)} />
      </>}
      <p className='mb-1'><strong>Subject</strong></p>
      <div className='mb-3'>
        {needOther &&
          <Select onChange={handleSelectChange} style={{ width: "100%" }} value={subject}>
            {subjectOptions!.map((option, index) =>
              <Select.Option value={option} key={`option-${index}`}>{option}</Select.Option>
            )}
            <Select.Option value={OTHER}>{OTHER}</Select.Option>
          </Select>
        }
        {showOtherSubject && <Input value={otherSubject} placeholder={needOther ? 'Please specify' : ''} size="large" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOtherSubject(event.target.value)} />}
      </div>
      <p className='mb-1'><strong>Message</strong></p>
      <Input.TextArea rows={7} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setBody(event.target.value)} value={body} />
    </Modal>
  )
}

export { ContactUsModal }