import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import SignIn from './components/SignIn'
import { signIn as signInAction } from './userSlice'

const SignInConnect = () => {
  const dispatch = useAppDispatch();
  const { processing, signIn } = useAppSelector((state: RootState) => state.user)
  const invalidCredential = signIn?.invalidCredential

  const handleSignIn = (email: string, password: string) => {
    dispatch(signInAction({ email, password }))
  };

  return (
    <SignIn
      authenticating={processing}
      invalidCredential={invalidCredential}
      signIn={handleSignIn}
      goToSignUp={() => { dispatch(push(pages.signUp.route)); }}
      goToForgetPassword={() => { dispatch(push(pages.forgetPassword.route)); }}
    />
  )
}

export default SignInConnect