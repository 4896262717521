import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import GoalDetailsComponent from './components/GoalDetailsComponent'
import { goalDetails as goalDetailsSelector, setDateOfWeek } from './goalSlice'

const GoalDetailsConnect = () => {
  const goalDetails = useAppSelector(goalDetailsSelector)
  const firstWeek = useAppSelector((state: RootState) => state.user.details?.firstWeek)
  const dispatch = useAppDispatch();
  
  return (
    <GoalDetailsComponent    
      goalDetails={goalDetails}
      gotoSetGoal={() => dispatch(push(pages.setGoal.route))}
      setDateOfWeek={(value: string) => dispatch(setDateOfWeek(value))}
      firstWeek={firstWeek}
    />
  );
};

export default GoalDetailsConnect;