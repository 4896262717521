import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import SignUp from './components/SignUp'
import { checkCoupon, checkEmailAddress, clearCouponCode, signUp as signUpAction } from './userSlice'

const SignUpConnect = () => {
  const dispatch = useAppDispatch()
  const { location } = useAppSelector((state: RootState) => state.router)
  const { signUp, processing } = useAppSelector((state: RootState) => state.user)
  const code = signUp?.coupon || null
  const company = signUp?.company
  const error = signUp?.error

  // useEffect(() => {
  //   const coupon = location.query['coupon']
  //   if (coupon) {
  //     dispatch(checkCoupon(coupon))
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  const handleSubmitCoupon = (code: string) => {
    dispatch(checkCoupon(code))
  }

  const handleSignUp = (coupon: string, email: string, password: string, firstName: string, lastName: string) => {
    dispatch(signUpAction({ coupon, email, password, firstName, lastName }))
  };

  const handleCheckEmail = (coupon: string, email: string) => {
    dispatch(checkEmailAddress({ coupon, email }))
  }

  const handleClearCode = () => {
    dispatch(clearCouponCode())
  }

  return (
    <SignUp
      couponCode={code}
      defaultCouponCode={location.query['coupon']}
      company={company}
      processing={processing}
      error={error}
      submitCoupon={handleSubmitCoupon}
      signUp={handleSignUp}
      checkEmail={handleCheckEmail}
      clearCode={handleClearCode}
    />
  );
};

export default SignUpConnect;