import { useEffect } from 'react'
import { UserState } from '../userSlice'

export interface ILoadStatesProps {
  user: UserState
  pathname: string
  restoreUser: (user: UserState) => void
  checkUser: () => void
}

const STATES_KEY = 'fifteenForMe'

const restoreStates = (restoreUser: (user: UserState) => void) => {
  const storageString = localStorage.getItem(STATES_KEY)
  if (storageString) {
    try {
      const { user } = JSON.parse(storageString) as Pick<ILoadStatesProps, 'user'>
      restoreUser(user)
    } catch (err) {
      // continue regardless of error
    }
  }
}

const LoadStates = ({ user, pathname,  restoreUser, checkUser }: ILoadStatesProps) => {
  useEffect(() => {
    restoreStates(restoreUser)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem(STATES_KEY, JSON.stringify({ user }))
  }, [user])

  useEffect(() => {
    checkUser()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  
  return null
}

export default LoadStates