import React, { useState, useEffect } from 'react'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import { Table, Col, Row } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import FooterButtons from '../../common/components/FooterButtons'
import NsInProgress from './NsInProgress'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import { NsExercise, NsUserAnswer } from '../nsSlice'
import { getCenteredBreathingText, getCognitiveFooterButtons, getCurrent, getCurrentExercise, getCurrentSet, getTotalMinutes, isReadyToStart } from '../../common/exerciseHelper'
import { getResults } from '../nsHelper'
import { pages } from '../../../constants/pages'

export interface INsExercisesProps {
  status: ExerciseStatus
  exercises: NsExercise[]
  userAnswers: NsUserAnswer[]
  startTime?: number
  endTime?: number
  submitAnswerSet: (answerSet: string[]) => void
  next: (repeat?: boolean) => void
  backTo: (route: string) => void
}

const NsExercises = ({ status, exercises, userAnswers, startTime, endTime, next, submitAnswerSet, backTo }: INsExercisesProps) => {
  const { currentExercise, currentSet, currentExerciseIndex } = getCurrent(exercises, userAnswers)
  const { keys, symbols } = getCurrentExercise(exercises, currentExerciseIndex)
  const questionSet = getCurrentSet(exercises, currentExerciseIndex, currentSet) as number[]
  const [answerSet, setAnswerSet] = useState<string[]>(Array(questionSet.length).fill(''))

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (status === 'in-progress') {
      setAnswerSet(Array(questionSet.length).fill(''))
    }
  }, [questionSet, status])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    next(true)
  }

  const handlePickAnswer = (index: number, symbol: string) => {
    const newAnswerSet = [...answerSet]
    newAnswerSet.splice(index, 1, symbol)
    setAnswerSet(newAnswerSet)
  }

  const handleSubmitAnswer = () => {
    submitAnswerSet(answerSet)
  }

  const totalMinutes = getTotalMinutes(startTime, endTime)

  const results = getResults(status, exercises, userAnswers)

  const completedExercises = currentExercise - 1

  return (
    <>
      <ExerciseIntro status={status}>
        <h4>Instructions</h4>
        <p>The Number Symbol exercise asks you to pair a series of symbols with corresponding numbers after you have memorized the answer key.</p>
        <p>You will be shown a key where numbers are paired with specific symbols. Memorize the pairings.  Match the symbols to the corresponding numbers by selecting the correct symbol, working from left to right across the row.  You cannot skip boxes during the exercise. Work quickly and try to limit the number of times you refer to the answer key.</p>

        <p>Here is an example:</p>
        <Table bordered size="sm">
          <tbody>
            <tr className="table-dark">
              <td className="text-dark">Number</td>
              <td className="text-dark text-center">1</td>
              <td className="text-dark text-center">2</td>
              <td className="text-dark text-center">3</td>
              <td className="text-dark text-center">4</td>
            </tr>
            <tr className="table-dark">
              <td className="text-dark">Symbol</td>
              <td className="text-dark text-center">&gt;</td>
              <td className="text-dark text-center">*</td>
              <td className="text-dark text-center">$</td>
              <td className="text-dark text-center">%</td>
            </tr>
          </tbody>
        </Table>
        <h4>How to do the Exercises</h4>
        <p>Using the key above, note the numbers and symbols are paired.</p>
        <ul>
          <li>1 is associated with “&gt;” (greater than sign)</li>
          <li>2 is associated with “*” (asterisk)</li>
          <li>3 is associated with “$” (dollar sign)</li>
          <li>4 is associated with “%” (percent sign)</li>
        </ul>
        <p>The task is to memorize the pairings and complete the boxes in the exercises. The boxes will provide the numbers and you will provide the corresponding symbols.</p>
        <p>Targeting memory skills can help to promote your brain health!</p>
      </ExerciseIntro>
      <NsInProgress
        status={status}
        currentExercise={currentExercise}
        currentSet={currentSet - 1}
        keys={keys}
        symbols={symbols}
        questionSet={questionSet}
        answerSet={answerSet}
        pickAnswer={handlePickAnswer}
        next={handleSubmitAnswer}
      />
      <ExerciseComplete status={status} category="cognitive" totalMinutes={totalMinutes} completedExercises={completedExercises}>
        <h4>Result</h4>
        {results.map((result, i) => (
          <div key={`section-${i}`}>
            <Row>
              <Col xs={8}>
                <h5>Exercise {results.length - i}</h5>
              </Col>
              <Col xs={4}>
                <h5 className="text-right">{result.percentage}%</h5>
              </Col>
            </Row>
            <Table striped>
              <tbody>
                { result.sets.map((item, j) => (<tr key={`row-${i}-${j}`}><td>Set&nbsp;{j+1}</td><td className="text-right"><strong>{item.correct} / {item.total}</strong></td></tr>)) }                
              </tbody>
            </Table>
          </div>
        ))}
      </ExerciseComplete>
      <FooterButtons
        extraText={getCenteredBreathingText(status)}
        hidden={status === 'in-progress'}
        buttons={getCognitiveFooterButtons(
          status,
          pages.numberSymbol.title!,
          isReadyToStart(status, exercises),
          backTo,
          handleNext,
          handleRepeat,
        )}
      />
    </>
  )
}

export default NsExercises