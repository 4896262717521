import { configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { logger } from 'redux-logger'
import userReducer from '../features/user/userSlice'
import goalReducer from '../features/goal/goalSlice'
import ftiReducer from '../features/flipTheImage/ftiSlice'
import nsReducer from '../features/numberSymbol/nsSlice'
import wtReducer from '../features/wordTheme/wtSlice'
import mmReducer from '../features/memoryMatch/mmSlice'
import ciReducer from '../features/colorIllusion/ciSlice'
import cbReducer from '../features/centeredBreathing/cbSlice'
import ceReducer from '../features/cardioExercises/ceSlice'
import portalAdminReducer from '../features/portalAdmin/portalAdminSlice'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from '../sagas'

export const history = createBrowserHistory()

const nonProduction = process.env.NODE_ENV !== 'production'

const reducer = {  
  user: userReducer,
  router: connectRouter(history),
  goal: goalReducer,
  flipTheImage: ftiReducer,
  numberSymbol: nsReducer,
  wordTheme: wtReducer,
  memoryMatch: mmReducer,
  colorIllusion: ciReducer,
  centeredBreathing: cbReducer,
  cardioExercises: ceReducer,
  portalAdmin: portalAdminReducer,
}

const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware:any) => {
  let middleware = getDefaultMiddleware({thunk: false})
  middleware.push(routerMiddleware(history))
  if (nonProduction) {
    middleware.push(logger)  
  }
  middleware.push(sagaMiddleware)
  return middleware
}


export const store = configureStore({
  reducer,
  middleware,
  devTools: nonProduction,
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
