import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import GeneralVideo from './GeneralVideo'
import { videoKey } from '../../user/userSlice'
import { Col, Row, Image } from 'react-bootstrap'
import ArrowRightButton from '../../../imgs/arrow-right-button.svg'

export interface IWatchIntroVideoProps {
  videoSlug: videoKey
  introText: string
}

const WatchVideoButton = styled(Button)`
  height: auto;
  border-radius: 10rem !important;
  border: solid 1px #FFF;
  background: linear-gradient(#FFFFFF 0%, #E2E2E2 100%) !important;
  box-shadow: 3px 3px 8px rgba(31, 31, 31, 0.25);
  font-family: Poppins, sans-serif;
  font-size: 18px;
  color: #1679BB;
  font-weight: 600;
  padding-top: 0.3rem;
  margin: 1.5rem 0;
  .intro-text {
    color: #001E57;
  }
  img {
    margin-right: -0.7rem;
  }
`;

const WatchIntroVideo = ({ videoSlug, introText }: IWatchIntroVideoProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [playing, setPlaying] = useState<boolean>(false)

  const handleCancel = () => {
    setPlaying(false)
    setTimeout(() => {    
      setShowModal(false)
    }, 200)
  }

  const handleClick = () => {
    setShowModal(true)
    setPlaying(true)
  }

  return (
    <Row className="justify-content-center">
      <Col md={8} className="justify-content-center d-flex">
        <WatchVideoButton className="text-center d-flex align-items-center" onClick={handleClick}>
          <div>
            Watch video: <br className="d-sm-none" />
            <span className="pl-2 intro-text">Introduction to <br className="d-md-none" />{introText}</span>
          </div>
          <div>
            <Image className="pl-2" src={ArrowRightButton} alt="arrow-right-button" />
          </div>
        </WatchVideoButton>
      </Col>
      <Modal
        visible={showModal}
        closable
        onCancel={handleCancel}
        footer={null}
        width="80%"
      >
        <GeneralVideo slug={videoSlug} playing={playing} />
      </Modal>
    </Row>
  )
}

export { WatchIntroVideo }