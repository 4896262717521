import React, { useEffect, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

export interface IGoalBarProps {
  percent?: number
  style?: React.CSSProperties
}

const GoalBar = ({ percent, style }: IGoalBarProps) => {
  const [showPercent, setShowPercent] = useState<number>(0)

  useEffect(() => {
    if (percent !== undefined) {
      setTimeout(() => {
        setShowPercent(percent)
      }, 500)
    }
  }, [percent])

  const onChangeProgressVisible = (isVisible: boolean) => {
    if (isVisible && percent) {
      setTimeout(() => {
        setShowPercent(percent)
      }, 500)
    }
  }


  let barLength = showPercent ? Math.round(285 * (showPercent / 100)) + 40 : 40
  if (barLength > 325) { barLength = 325 }
  return (
    <VisibilitySensor onChange={onChangeProgressVisible} >
      <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="42" viewBox="0 0 327 42" style={ style }>
        <defs>
          <linearGradient id="linear-gradient" x1="1" x2="1" y2="1" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#59c4d2"/>
            <stop offset="0.512" stopColor="#1679bb"/>
            <stop offset="1" stopColor="#001e57"/>
          </linearGradient>
        </defs>
        <g id="Group_447" data-name="Group 447" transform="translate(-888 -581)">
          <g id="Rectangle_234" data-name="Rectangle 234" transform="translate(889 582)" fill="#eee" stroke="#b9b9b9" strokeWidth="1">
            <rect width="325" height="40" rx="20" stroke="none"/>
            <rect x="-0.5" y="-0.5" width="326" height="41" rx="20.5" fill="none"/>
          </g>
          {percent && <g id="Rectangle_233" data-name="Rectangle 233" transform="translate(889 582)" stroke="#b9b9b9" strokeWidth="1" fill="url(#linear-gradient)">
            <rect width={barLength} height="40" rx="20" stroke="none"/>
            <rect x="-0.5" y="-0.5" width={barLength + 1} height="41" rx="20.5" fill="none"/>
          </g>}
        </g>
      </svg>
    </VisibilitySensor>
  )
}

export { GoalBar }
