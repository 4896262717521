import React, { useMemo } from 'react'
import { shuffle } from 'lodash'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { CiLevel, CiSample } from '../ciSlice'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import { Card, Button } from 'antd'
import { getCardCols, ICardCols } from '../../common/exerciseHelper'

export interface ICiGameProps {
  level?: CiLevel
  sample?: CiSample
  gameCompleted: () => void
}

const cardColsMin: ICardCols = {
  xs: 4,
  sm: 3,
  md: 2,
}

const CiGame = ({ level, sample, gameCompleted }: ICiGameProps) => {
  const tileCols = useMemo(() => {
    if (level !== undefined) {
      return shuffle(level.tiles).map((tile, index) => (
        <Col key={`tile-${index}`} { ...getCardCols(level.tiles.length, cardColsMin)} xs={4} sm={3} md={2} className="my-3">
          <Image draggable={false} src={`${APIMEDIA_HOST}${tile}`} fluid className="text-center" width="500px" rounded />
        </Col>
      ))
    }
  }, [level])

  if (level === undefined) { return null }

  return (
    <>
      <Container>
        <Card>
          <h5>Level {level.level}</h5>
          {sample &&
            <>
              <div dangerouslySetInnerHTML={{ __html: sample.instruction }} />
              {sample.tiles.map((tile, index) => (
                <Row className='mt-3' key={`row-${index}`}>
                  <Col xs={4} sm={3} md={2}>
                    <Image draggable={false} src={`${APIMEDIA_HOST}${tile.tile}`} fluid rounded className="text-center" width="250px" />
                  </Col>
                  <Col xs={8} sm={9} md={10}>
                    <h6 className='mt-5 pt-4'>{tile.instruction}</h6>
                  </Col>
                </Row>
              ))}
            </>
          }
        </Card>
        <Row>
          {tileCols}
        </Row>
        <Row className="py-4 justify-content-center">
          <Col xs={12} md={6} lg={4}>
            <Button block size="large" type="primary" onClick={gameCompleted}>Done</Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CiGame