import React from 'react'
import { Affix, Button, Checkbox } from 'antd'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import GeneralVideo from './components/GeneralVideo'
import intro2 from '../../imgs/intro2.png'
import intro3 from '../../imgs/intro3.png'
import intro4 from '../../imgs/intro4.png'
import SpanishPopup from './components/SpanishPopup'

interface IWelcomeProps {
  goToHome: () => void
  skip: boolean
  setSkip: (skip: boolean) => void
}

const Welcome = ({ goToHome, skip, setSkip }: IWelcomeProps) => {
  return (
    <>
      <Container>
        <h3>Welcome to the 15 For Me&reg; Stress Reduction Program!</h3>
        <GeneralVideo slug="introduction" />
        {/* <Image src={intro1} width="300px" roundedCircle className='float-right ml-4 mb-4' /> */}
        <Row className='justify-content-center mb-4'>
          <Col sm={6} md={4} lg={3}>
            <SpanishPopup title="Introducción">
              <p>El BCAT® Research Center ha desarrollado un programa del bienestar interactivo llamado 15 For Me&reg;. Este programa es simple y fácil de utilizar, perfecto para las personas que tienen el tiempo limitado pero experimentan del estrés en su trabajo o en su vida diaria. </p>
              <p>Este programa se basa en la mejor ciencia disponible y sólo requiere 15 minutos cada día para bajar el estrés.</p>
              <p>15 For Me&reg; está compuesto de las 3 Cs para la reducción del estrés: los ejercicios Cardiovasculares, Cognitivos y de la respiración Centrada. Cada vez que se conecta, usted puede:</p>
              <p>Seleccionar de un menú de ejercicios cognitivos o cardiovasculares, pasando 10-12 minutos haciendo los ejercicios que ha seleccionado.</p>
              <p>Pasar 3-5 minutos haciendo una actividad de la respiración centrada, a veces llamada la meditación o la respiración abdominal.</p>
              <p>Si a usted le gustaría saber su “temperatura del estrés” antes de empezar el programa, simplemente haga clic en Mood-5&reg; (M5&reg;) en el menú y complete un cuestionario de un minuto para un puntaje del estrés inmediato. Quédese asegurado, su información no se compartirá con nadie y se mantendrá privada. </p>
              <p>Para aprender más sobre cómo funciona el programa, vea el video de resumen de 15 For Me&reg;.</p>
            </SpanishPopup>
          </Col>
        </Row>
        <p>The BCAT&reg; Research Center has developed an innovative wellness program called <em>15 for Me&reg;</em>. This program is simple and easy to use, perfect for people who have limited time but who are experiencing stress at work or in their everyday life.</p>
        <p>This program is based on the best science available, and only requires 15 minutes a day to lower your stress.</p>
        <Row className='my-4'>
          <Col xs={4} className='my-3'>
            <Image src={intro2} roundedCircle fluid />
          </Col>
          <Col xs={4} className='my-3'>
            <Image src={intro3} roundedCircle fluid />
          </Col>
          <Col xs={4} className='my-3'>
            <Image src={intro4} roundedCircle fluid />
          </Col>
        </Row>
        <p><em>15 For Me&reg; </em>is made up of the <strong>3 C's</strong> for Stress Reduction: Cardio, Cognitive, and Centered-Breathing Exercises.  Each time you log on, you can:</p>
        <ul>
          <li>Select from a menu of cognitive or cardio exercises, spending 10-12 minutes completing the exercises of your choice. </li>
          <li>Spend 3-5 minutes doing a centered breathing activity sometimes called meditation or belly breathing. </li>
        </ul>
        <p>If you would like to take your “stress temperature” before you begin the program, just click on the Mood-5&reg; (M5&reg;) on the menu page and complete a one-minute questionnaire for an immediate stress score. Be assured, your information is not shared with anyone and remains private.</p>
        <p>To learn more about how the program works, watch the <a href="https://vimeo.com/576753835" target="_blank" rel="noreferrer">15 For Me&reg; Overview Video</a>.</p>
        {/* <h4>15 for Me&reg; Overview Video</h4> */}
        {/* <Vimeo className='mb-3' video="576753835" responsive /> */}
      </Container>
      <Affix offsetBottom={0}>
        <Container className="py-3" style={{ backgroundColor: '#EEE' }}>
          <Row className="justify-content-center">
            <Col xs={12} md={6} lg={4}>
              <Checkbox checked={skip} className="my-2" onChange={(e: CheckboxChangeEvent) => setSkip(e.target.checked)}>Skip introduction next time</Checkbox>
              <Button className="mb-3" size="large" block type="primary" onClick={goToHome}>Click here to begin</Button>
            </Col>
          </Row>
        </Container>
      </Affix>
    </>
  )
}

export default Welcome