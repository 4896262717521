import React, { ChangeEvent, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Modal, Button, Card, Checkbox, CheckboxOptionType, Form, InputNumber, Radio, RadioChangeEvent, Select, Typography, Input, Alert } from 'antd'
import { UserRegisterOptions, UserRegistration, UserRegistrationError } from '../userSlice'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import styled from 'styled-components'
import TermsOfUse from './TermsOfUse'

interface IRegisterProps {
  processing: boolean
  error?: UserRegistrationError
  options?: UserRegisterOptions
  submit: (data: UserRegistration) => void
}

const { Title } = Typography;

const Terms = styled.div`
  max-height: 60vh;
  overflow: scroll;
`

const Register = ({ processing, error, options, submit }: IRegisterProps) => {
  const [data, setData] = useState<UserRegistration>({ emailSubscription: true, agreeTerms: true })
  const [showTerms, setShowTerms] = useState<boolean>(false)

  const hasRole = data.role !== undefined 

  const isStaff = data.role === 'STAFF'

  const handleSetData = (key: keyof UserRegistration, value: string | number | boolean) => {
    setData({ ...data, [key]: value })
  }

  // const isReadytoSubmit = !!data.education && !!data.race && !!data.ageRange && !!data.jobCode && !!data.jobStatus && 
  //   data.yearsWithEmployer !== undefined && data.yearsWithEmployer !== null && data.isSupervisor !== undefined && 
  //   !!data.primaryLanguage && (data.primaryLanguage !== 'other' || !!data.primaryLanguageOther) &&
  //   data.activityCardio !== undefined && data.activityCognitive !== undefined && data.activityBreathing !== undefined && 
  //   data.activityCardio !== null && data.activityCognitive !== null && data.activityBreathing !== null &&     
  //   data.emailSubscription !== undefined &&
  //   data.agreeTerms === true

  const handleSubmit = () => {
    submit(data)
  }

  const hasError = (key: keyof UserRegistrationError): "error" | undefined => {
    if (error !== undefined && !!error[key]) {
      return 'error'
    }
  }

  const getErrorMessage = (messages?: string[]) =>
    messages && messages.map((message, index) => <span key={index}>{message}<br /></span>)

  const getFormSelectItem = (label: string, key: keyof UserRegisterOptions, searchable?: boolean) => (
    <Form.Item label={label} validateStatus={hasError(key)} help={getErrorMessage(error?.[key])}>
      <Select showSearch={searchable} disabled={processing} value={data[key]} onChange={(value: string) => handleSetData(key, value)}>
        {options && options[key].map(({ label, value }) => <Select.Option key={`${key}-${value}`} value={value}>{label}</Select.Option>)}
      </Select>
    </Form.Item>
  )

  const getFormRadioGroupItem = (label: string, key: keyof UserRegistration, options?: CheckboxOptionType[]) => (
    <Form.Item label={label} validateStatus={hasError(key)} help={getErrorMessage(error?.[key])}>
      <Radio.Group
        disabled={processing}
        options={options}
        value={data[key]}
        onChange={({ target: { value } }: RadioChangeEvent) => handleSetData(key, value)}
        optionType="button"
        buttonStyle="solid"
      />
    </Form.Item>
  )

  const getFormInputNumberItem = (label: string, key: keyof UserRegistration) => (
    <Form.Item label={label} validateStatus={hasError(key)} help={getErrorMessage(error?.[key])}>
      <InputNumber disabled={processing} value={data[key] as number} min={0} onChange={(value: number) => handleSetData(key, value)} />
    </Form.Item>
  )

  const getFormCheckBoxItem = (label: string | JSX.Element, key: keyof UserRegistration) => (
    <Form.Item validateStatus={hasError(key)} help={getErrorMessage(error?.[key])}>
      <Checkbox
        disabled={processing}
        checked={data[key] as boolean}
        onChange={({ target: { checked } }: CheckboxChangeEvent) => handleSetData(key, checked)}
      >
        {label}
      </Checkbox>
    </Form.Item>
  )

  const getFormOtherInputItem = (placeholder: string, key: keyof UserRegistration) => (
    <Form.Item validateStatus={hasError(key)} help={getErrorMessage(error?.[key])}>
      <Input
        disabled={processing}
        placeholder={placeholder}
        value={data[key] as string}
        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => handleSetData(key, value)}
      />
    </Form.Item>
  )

  const getCommoneErrorAlert = () => {
    if (error) {
      let message: JSX.Element[] | undefined = [<span>Please correct the following error(s)</span>]
      if (error.non_field_errors) {
        message = getErrorMessage(error.non_field_errors)
      }
      if (error.generalError) {
        message = [<span>System currently unavailable for registration.  Please try again later.</span>]
      }
      return (<Alert type="error" message={message} />)
    }
  }

  const yesNoOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }]

  const agreeTermsLabel = (<>I agree to the <Button onClick={() => setShowTerms(true)} type="link" style={{ padding: 0 }}><strong>terms of use</strong></Button>.</>)

  const termsModal = (
    <Modal
      title={<h5>WEBSITE TERMS OF USE</h5>}
      visible={showTerms && !processing}
      onCancel={() => setShowTerms(false)}
      okText="I Agree"
      onOk={() => {
        handleSetData('agreeTerms', true)
        setShowTerms(false)
      }}
    >
      <Terms>
        <TermsOfUse />
      </Terms>
    </Modal>
  )

  const registerForm = (
    <Form onFinish={handleSubmit} layout="vertical">
      <Row>
        <Col lg={6}>
          {getFormSelectItem('Tell us about yourself', 'role')}
          {hasRole && 
            <>          
              {getFormSelectItem('Education Level', 'education')}
              <Row>
                <Col sm={6}>{getFormSelectItem('Race', 'race')}</Col>
                <Col sm={6}>{getFormSelectItem('Age Range', 'ageRange')}</Col>
              </Row>
              {!isStaff && getFormSelectItem('State', 'state', true)}
              {isStaff && 
                <>
                  {getFormSelectItem('List of Job Code/Type/Disciplines', 'jobCode')}
                  {data.jobCode === 'other' && getFormOtherInputItem('Please specify your job code/type/disciplines', 'jobCodeOther')}
                  {getFormRadioGroupItem('Status', 'jobStatus', options && options.jobStatus)}
                  <Row>
                    <Col sm={6}>{getFormSelectItem('Years with Employer', 'yearsWithEmployer')}</Col>
                    <Col sm={6}>{getFormRadioGroupItem('Are you a manager or supervisor?', 'isSupervisor', yesNoOptions)}</Col>
                  </Row>            
                </>
              }
            </>
          }                    
        </Col>
        <Col lg={6}>
          {hasRole && 
            <>
              {isStaff && 
                <>
                  {getFormSelectItem('Please indicate your primary language', 'primaryLanguage')}
                  {data.primaryLanguage === 'other' && getFormOtherInputItem('Please specify your primary language', 'primaryLanguageOther')}
                  <Form.Item label="We want to understand your current activity level with the following (minutes per week)">
                    <Row>
                      <Col xs={6}>
                        {getFormInputNumberItem('Physical Exercise', 'activityCardio')}
                      </Col>
                      <Col xs={6}>
                        {getFormInputNumberItem('Brain Games ', 'activityCognitive')}
                      </Col>
                      <Col xs={12}>
                        {getFormInputNumberItem('Meditation ', 'activityBreathing')}
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              }
              {getFormCheckBoxItem(agreeTermsLabel, 'agreeTerms')}
              {getFormCheckBoxItem('Yes, I would like to receive free educational emails to improve my brain health and reduce stress. Your privacy is our priority!', 'emailSubscription')}
            </>
          }
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={8} md={6} lg={4}>
          <Button loading={processing} htmlType="submit" size="large" type="primary" block disabled={error?.generalError||!hasRole}>Submit</Button>
        </Col>
      </Row>
    </Form>
  )

  return (
    <Container>
      <Card>
        <Typography>
          <Title level={4}>Registration for 15 for Me&reg; </Title>
          {getCommoneErrorAlert()}
          {registerForm}
        </Typography>
      </Card>
      {termsModal}
    </Container>
  )
}

export default Register