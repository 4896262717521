import { shuffle, random } from "lodash";
import { ExerciseStatus, WT_NUM_CHOICES } from "../../constants/exercises";
import { baseUpdateAnswer } from "../common/exerciseHelper";
import { WtExercise, WtState, WtUserAnswer } from "./wtSlice";

export const updateAnswer = (answer: string, state: WtState) => {
  baseUpdateAnswer(
    answer,
    state,
    (answer, index) => state.userAnswers[index].sets.push(answer)
  )
}

export const getThemeChoices = (themes: string[], correctAnswer: string): string[] => {
  const choices: string[] = []
  let hasCorrectAnswer = false
  const shuffledThemes = shuffle(themes)
  const limit = WT_NUM_CHOICES - 1
  for(let i = 0; i < limit; i++) {
    const theme = shuffledThemes[i]
    if (theme === correctAnswer) {
      hasCorrectAnswer = true
    }
    choices.push(theme)
  }
  if (!hasCorrectAnswer) {
    choices.splice(random(choices.length - 1), 0, correctAnswer) 
  } else {
    choices.push(shuffledThemes[limit])
  }
  return choices
}

export interface WtResult {
  sets: boolean[]
  percentage: number
}

export const getResults = (status: ExerciseStatus, exercises: WtExercise[], userAnswers: WtUserAnswer[]): WtResult[] => {
  let results: WtResult[] = []
  if (status === 'completed' || status === 'check-point') {
    results = userAnswers.map((userAnswer) => {
      const { sets: questionSets } = exercises[userAnswer.index]
      const { sets: answerSets } = userAnswer
      const sets = questionSets.map((set, j) => answerSets[j] === set.correctAnswer)
      const correctCount = sets.reduce((acc, set) => set ? acc + 1 : acc, 0)
      const percentage = Math.round(correctCount * 100 / sets.length)
      return { sets, percentage }
    })
    results.reverse()
  }
  return results
}