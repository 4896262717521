import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, Form, Button, Typography, Input } from 'antd';
import AnchorLink from '../../common/AnchorLink';
import LoadingSpinner from '../../common/LoadingSpinner';

const { Title } = Typography;
export interface IForgetPasswordProps {
  processing: boolean
  processed: boolean
  forgetPassword: (email: string) => void
  goToSignIn: () => void
}

const ForgetPassword = ({ processing, processed, forgetPassword, goToSignIn}: IForgetPasswordProps) => {
  const [email, setEmail] = useState<string>('');
  const submitLabel = !processing ? 'Submit' : (<LoadingSpinner />);

  const handleSubmit = () => {
    if (email) {
      forgetPassword(email);
    }
  }

  const forgetPasswordForm = (
    <Form onFinish={handleSubmit} layout="vertical">
      <p>Please provide your email address to request a password reset.</p>
      <Form.Item label="Email">
        <Input
          type="email"
          placeholder="Enter email address"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          autoComplete="email"
        />
      </Form.Item>
      <Button htmlType="submit" size="large" type="primary" block disabled={processing || !email}>{submitLabel}</Button>
    </Form>
  );

  const forgetPasswordProcessed = (
    <>
      <p>Your request to reset password is submitted.  You will receive an email with instruction to reset your password.</p>
      <p>After reseting your password, you may try to <AnchorLink onClick={goToSignIn}>sign in</AnchorLink> again.</p>
    </>
  );

  const content = !processed ? forgetPasswordForm : forgetPasswordProcessed;

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={8} lg={6}>
          <Card>
            <Typography>
              <Title level={4}>Forgot Password</Title>
              {content}
            </Typography>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgetPassword;