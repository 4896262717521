import React, { useEffect } from 'react'
import { ExerciseStatus } from '../../../constants/exercises'
import ExerciseIntro from '../../common/components/ExerciseIntro'
import ExerciseComplete from '../../common/components/ExerciseComplete'
import FooterButtons from '../../common/components/FooterButtons'
import CeInProgress from './CeInProgress'
import { isReadyToStart, getTotalDuration } from '../../common/exerciseHelper'
import { CeExercise as CeExercisesType } from '../ceSlice'
import { IButton, pages } from '../../../constants/pages'
import { Skeleton } from 'antd'
import { Row, Col } from 'react-bootstrap'
import { WatchIntroVideo } from '../../common/components/WatchIntroVideo'
import { range } from 'lodash'

export interface ICeExerciseProps {
  status: ExerciseStatus
  exercises: CeExercisesType[]
  selectedExercise?: string
  durations: number[]
  next: (repeat?: boolean, redirect?: string) => void
  finish: (duration: number, startDate?: number) => void
  update: (duraction: number) => void
  selectExercise: (exercise?: string) => void
  goToManualEntry: () => void
}

const CeExercise = ({ status, exercises, selectedExercise, durations, next, finish, update, selectExercise, goToManualEntry }: ICeExerciseProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleJumpToCb = () => {
    window.scrollTo(0, 0)
    next(false, pages.centeredBreathing.route)
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    next(true)
  }

  const handlePickExercise = (value?: string) => {
    window.scrollTo(0, 0)
    selectExercise(value)
  }

  const handleFinish = (duration: number, startDate?: number) => {
    window.scrollTo(0, 0)
    finish(duration, startDate)
  }

  const handleUpdateDuration = (duration: number) => {
    update(duration)
  }

  const loading = status === 'start' && !isReadyToStart(status, exercises)

  const nextLabel: string = status === 'completed' ? 'Return to main menu' : 'Loading...'

  const totalDuration = getTotalDuration(durations)

  const totalMinutes = Math.round(totalDuration / 60)

  const footerButtons: IButton[] = [{ label: nextLabel, action: handleNext, buttonType: "primary", loading }]

  if (status === 'completed') {
    footerButtons.unshift(
      { label: 'Go to Centered Breathing', action: handleJumpToCb, buttonType: "default" },
      { label: 'Return to the Cardio Menu', action: handleRepeat, buttonType: "default" }
    )
  }

  const footerExtraText: string | undefined = status === 'completed' ? 'When you have completed your cardio exercise session, finish with a centered breathing exercise for additional stress relief' : undefined

  return (
    <>
      <ExerciseIntro status={status}>
        <Row>
          <Col>
            {range(0, 4).map((index) => 
              <Skeleton key={index} active />
            )}
          </Col>
        </Row>
      </ExerciseIntro>
      <CeInProgress
        status={status}
        exercises={exercises}
        pickExercise={handlePickExercise}
        finishExercise={handleFinish}
        updateDuration={handleUpdateDuration}
        selectedExercise={selectedExercise}
        goToManualEntry={goToManualEntry}
      />
      <ExerciseComplete status={status} category="cardio" totalMinutes={totalMinutes} />
      <FooterButtons
        extraText={footerExtraText}
        hidden={status === 'in-progress'}
        buttons={footerButtons}
      />
      {selectedExercise === undefined ? <WatchIntroVideo videoSlug="cardio" introText="Cardio Exercises" /> : null}
    </>
  )
}

export default CeExercise