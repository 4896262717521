import styled from "styled-components";

const AnchorLink = styled.span`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  cursor: pointer;
`;

export default AnchorLink