import React, { useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { Card, Form, Input, Button } from 'antd';
import styled from 'styled-components';
import AnchorLink from '../../common/AnchorLink';
import LoadingSpinner from '../../common/LoadingSpinner';

export interface ISignInProps {
  authenticating: boolean;
  invalidCredential?: boolean;
  signIn: (email: string, password: string) => void;
  goToSignUp: () => void;
  goToForgetPassword: () => void;
}

const OrTag = styled.div`
  text-align: center;
  color: #505050;
  margin: 0.5em;
  font-size: 1.3em;
`;

const SignIn = ({ authenticating, invalidCredential, signIn, goToSignUp, goToForgetPassword }: ISignInProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const signInLabel = !authenticating ? 'Sign In' : (<LoadingSpinner />);

  const handleSignIn = (values: any) => {
    if (email && password) {
      signIn(email, password);
    }
  }

  const invalidCredentailAlert = invalidCredential ? (
    <Alert variant="danger">Invalid Username and/or Password</Alert>
  ) : null;

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <Card>
              <Form layout="vertical" onFinish={handleSignIn}>
                <Form.Item label="Email" name="email">
                  <Input
                    type="email"
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    autoComplete="email"
                  />
                </Form.Item>
                <Form.Item label="Password" name="password">
                  <Input.Password
                    placeholder="Enter password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    autoComplete="current-password"
                  />
                </Form.Item>
                {invalidCredentailAlert}
                <Button htmlType="submit" type="primary" size="large" block disabled={authenticating || !email || !password}>{signInLabel}</Button>
              </Form>
              <p className="mt-2"><AnchorLink onClick={goToForgetPassword}>Forgot Password</AnchorLink></p>
            </Card>
          </Col>
          <Col sm={12}>
            <OrTag>or</OrTag>
          </Col>
          <Col md={8} lg={6}>
            <Card>
              <Button size="large" type="primary" block onClick={goToSignUp}>Sign Up</Button>
              <p className="text-center">with a registration code</p>              
              <p className="m-0">The 15 for Me&reg; Program requires a registration code. For more information, please contact <a href="mailto:15forme@enrichvisits.com">15forme@enrichvisits.com</a>. Thank you! </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignIn;