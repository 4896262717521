import { Button } from 'antd'
import styled from 'styled-components'

const ChoiceButton = styled(Button)`
  height: auto;
  white-space: normal;
  &.selected {
    border: solid 3px #1890ff;
  }
`

export default ChoiceButton