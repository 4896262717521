import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Card, Button, Modal } from 'antd'
import { ExerciseStatus } from '../../../constants/exercises'
import FooterButtons from '../../common/components/FooterButtons'
import classnames from 'classnames'
import styled from 'styled-components'

export interface IWtInProgressProps {
  status: ExerciseStatus
  currentExercise: number
  currentSet: number
  words: string[]
  themes: string[]
  answer?: string
  pickAnswer: (answer: string) => void
  next: () => void
}

const Choice = styled(Button)`
  height: auto;

  &.selected {
    border: solid 3px #1890ff;
  }
`

const WtInProgress = ({
  answer,
  currentExercise,
  currentSet,
  next,
  pickAnswer,
  status,
  themes,
  words
}: IWtInProgressProps) => {
  const [showInstruction, setShowInstruction] = useState<boolean>(false)

  if (status !== 'in-progress') { return null }
  
  const wordItems = words.map((word, index) => (
    <h5 key={`word-${index}`}><strong>{word}</strong></h5>
  ))

  const answerCols = themes.map((theme, index) => {
    const buttonClasses = classnames({ selected: answer === theme })
    return (
      <Col xs={10} sm={8} md={6} lg={4} key={`answer-${index}`} className="my-1">
        <Choice size="large" className={buttonClasses} block onClick={() => pickAnswer(theme)}>
          <strong>{theme}</strong>
        </Choice>
      </Col>
    )
  })

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>Exercise {currentExercise} </h4>
          </Col>
          <Col>
            <Button className='float-right' onClick={() => setShowInstruction(true)}>See Instruction</Button>            
          </Col>
        </Row>
        <Modal visible={showInstruction} footer={null} onCancel={() => setShowInstruction(false)}>
          <p>You will find a series of “scrambled” words. All but one word have something in common. Unscramble the words that share something in common. From the four categories below the word list, choose the best category they belong to. You may not have to unscramble all of the words to choose the correct category. For an added challenge, try to unscramble every word, including the “outlier” word.</p>
        </Modal>
        <Row className="mb-3 justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card className="text-center">
              <h6 className="text-left">{`Set ${currentSet}`}</h6>
              { wordItems }
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {answerCols}
        </Row>
      </Container>
      <FooterButtons
        buttons={[{ disabled: answer === undefined, label: "Next", action: next, buttonType: "primary" }]}
      />
    </>
  )
}

export default WtInProgress