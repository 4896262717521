import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExerciseStatus, MinMaxDefault } from "../../constants/exercises"
import { moveNext, quitExercise, unSelectExercise, updateDuration } from "./cbHelper"

export interface CbExercise {
  name: string
  slug: string
  blob: string | null
  description: string | null
  preview: boolean
  variant: string | null
  mp4: string | null
  webm: string | null
  poster: string | null
  children: CbExercise[]
}

export const cbLimits: MinMaxDefault = {
  min: 1, 
  max: 15, 
  default: 3
}

export interface CbState {
  status: ExerciseStatus  
  id?: string
  exercises: CbExercise[]
  selectedExercise?: string
  durations: number[]
  startDate?: number 
}

const initialState: CbState = {
  status: 'start', 
  exercises: [],
  durations: []
}

interface CbExercisesPayload {
  id: string
  exercises: CbExercise[]
  preSelectedExercise?: string
}

export interface CbExerciseFinishPayload {
  duration: number
  startDate?: number
}

export const cbSlice = createSlice({
  name: 'centeredBreathing',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<CbExercisesPayload>) => {
      state.id = action.payload.id
      state.exercises = action.payload.exercises
      if (action.payload.preSelectedExercise) {
        state.selectedExercise = action.payload.preSelectedExercise
      }
      moveNext(state)
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      moveNext(state, action.payload)
    },
    select: (state, action: PayloadAction<string>) => {
      state.selectedExercise = action.payload
    }, 
    unSelect: (state) => {
      state.status = 'start'      
      unSelectExercise(state)
      moveNext(state, true)
    },
    quit: (state) => {
      quitExercise(state)
    },   
    update: (state, action: PayloadAction<number>) => {
      updateDuration(state, action.payload)
    },
    finish: (state, action: PayloadAction<CbExerciseFinishPayload>) => {
      updateDuration(state, action.payload.duration)
      state.startDate = action.payload.startDate
      moveNext(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction('centeredBreathing/requestQuit')

export const { installExercises, next, select, quit, update, finish, reset, unSelect } = cbSlice.actions

export default cbSlice.reducer