import React, { useState, useEffect }  from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import { shuffle } from 'lodash'
import CardFlip from 'react-card-flip'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import { getCardCols, ICardCols } from '../../common/exerciseHelper'

export interface IMmGameProps {
  cards?: string[]
  cardBack?: string
  gameCompleted: (attempts: number) => void
}

const cardColsMin: ICardCols = {
  xs: 6,
  sm: 4,
  md: 3,
  lg: 2,
}

const MmGame = ({cards, cardBack, gameCompleted}: IMmGameProps) => {
  const [allCards, setAllCards] = useState<number[]>([])
  const [openedCards, setOpenedCards] = useState<number[]>([])
  const [currentCards, setCurrentCards] = useState<number[]>([])
  const [attempts, setAttempts] = useState<number>(0)

  useEffect(() => {
    if (cards) {
      const cardIndexes = Array.from(Array(cards.length).keys())
      setAllCards(shuffle(cardIndexes.concat(cardIndexes)))
    }
  }, [cards])

  useEffect(() => {
    if (allCards.length > 0 && openedCards.length === allCards.length) {
      setTimeout(() => {
        gameCompleted(attempts)
        setOpenedCards([])
        setCurrentCards([])
        setAttempts(0)
      }, 800)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedCards])

  useEffect(() => {
    if (currentCards.length === 2) {
      if (allCards[currentCards[0]] === allCards[currentCards[1]]) {
        setOpenedCards([...openedCards, ...currentCards])
        setCurrentCards([])
      } else {
        setTimeout(() => {
          setCurrentCards([])
        }, 1000)
      }
    }
  }, [currentCards, allCards, openedCards])

  if (cards === undefined || cardBack === undefined || cards.length === 0) { return null }

  const handleFlipCard = (index: number) => {
    if (currentCards.length < 2) {
      setCurrentCards([...currentCards, index])
      setAttempts(attempts + 1)
    }
  }

  const cardCols = allCards.map((card, index) => {
    const isFlipped = openedCards.indexOf(index) >= 0 || currentCards.indexOf(index) >= 0
    return (
      <Col key={`card-${index}`} {...getCardCols(allCards.length, cardColsMin)} className="my-3">
        <CardFlip isFlipped={isFlipped}>
          <Image draggable={false} src={`${APIMEDIA_HOST}${cardBack}`} fluid onClick={() => handleFlipCard(index)} className="text-center" width="500px" rounded />
          <Image draggable={false} src={`${APIMEDIA_HOST}${cards[card]}`} fluid className="text-center" width="500px" rounded />
        </CardFlip>
      </Col>
    )
  })

  return (
    <Container>
      <Row>
        {cardCols}
      </Row>
    </Container>
  )
}

export default MmGame