import { PayloadAction } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import { LOCATION_CHANGE, replace, RouterLocation } from "connected-react-router"
import { get, some } from "lodash"
import { put, takeLatest, call, select, debounce, all, delay } from "redux-saga/effects"
import {
  authenticate,
  checkCoupon,
  fetchContactUsSubjects,
  fetchM5URL,
  fetchUserDetails,
  forgetPassword,
  patchUserDetails,
  refreshToken,
  resetPassword,
  signUpAccount,
  submitContactUs,
  bulkUpdateExercise,
  checkEmailAddress,
  updateUserSettings,
  fetchUserSettings,
  changePassword,
  register,
  fetchRegisterOptions,
} from "../api/ApiManager"
import { RootState } from "../app/store"
import { pages, publicRoutes } from "../constants/pages"
import { MAX_FETCH_SETTINGS_TRIALS } from "../constants/user"
import { getDatesOfWeek } from "../features/goal/goalHelper"
import { resetGoalDetails } from "../features/goal/goalSlice"
import {
  AuthenticatePayload,
  getAuthToken,
  getImpersonateRefresh,
  getRefreshSignIn,
  getUserSignIn,
  isTokenExpired,
  isUserDetailsExpired,
  isUserRegistered,
  isUserSessionExpired,
  RefreshTokenPayload,
} from "../features/user/userHelper"
import {
  checkCoupon as checkCouponAction,
  checkUser as checkUserAction,
  forgetPassword as forgetPasswordAction,
  installUserSignIn,
  installUserSignUp,
  refresh as refreshAction,
  resetPassword as resetPasswordAction,
  ResetPassword,
  setProcessedPassword,
  signIn as signInAction,
  SignIn,
  signOut as signOutAction,
  signUp as signUpAction,
  checkEmailAddress as checkEmailAddressAction,
  CheckEmailAddress,
  SignUp,
  startProcess,
  UserSignUp,
  UserDetails,
  installUserDetails,
  getUserDetails as getUserDetailsAction,
  updateUserDetails,
  getM5Link,
  setM5Link,
  updateAge,
  contactUs,
  setProcessedContactUs,
  ContactUsPayload,
  requestContactUsSubjects,
  setContactUsSubjects,
  ContactUsSubjectsPayload,
  bulkUpdateExercise as bulkUpdateExerciseAction,
  setProcessedBulkUpdateExercise,
  BulkUpdateExercisePayload,
  BulkUpdateExerciseResponse,
  updateUserSettings as updateUserSettingsAction,
  UserSettings,
  installUserSettings,
  changePassword as changePasswordAction,
  ChangePassword,
  register as registerAction,
  UserRegistration,
  registerError,
  UserRegistrationError,
  UserRegisterOptions,
  installRegisterOptions,
} from "../features/user/userSlice"

function* checkUser() {
  const state: RootState = yield select()
  const { pathname, query } = state.router.location as RouterLocation<unknown>
  const { signIn, details } = state.user
  const userSessionExpired = isUserSessionExpired(signIn)
  const userRegistered = isUserRegistered(details)
  const isAdminPortal = details?.adminPortal
  if (some(publicRoutes, (route) => pathname.startsWith(route))) {
    if (pathname === pages.impersonate.route) {
      yield put(installUserSignIn(getImpersonateRefresh(query.token)))
      yield put(replace(pages.loading.route))
      return
    } 
    if (
      !userSessionExpired &&
      !pathname.startsWith(pages.passwordReset.route)
    ) {
      if (userRegistered) {
        // redirect user to loading page when they are in public page and a session is valid except when they are reseting the password
        yield put(replace(pages.loading.route))
      } else {
        yield put(replace(pages.userRegistration.route))
      }
    }
  } else {
    if (userSessionExpired) {
      // redirect user to sign in page if they are not in the public page and the user session is expired.
      yield put(replace(pages.signIn.route))
    } else {
      const { authExp, refreshExp, refreshToken } = state.user.signIn || {}
      if (
        isTokenExpired(authExp) &&
        refreshToken !== undefined &&
        !isTokenExpired(refreshExp)
      ) {
        yield put(refreshAction(refreshToken))
      }
      const { details } = state.user
      if (isUserDetailsExpired(details)) {
        if (query.next || pathname !== pages.loading.route) {
          yield put(replace(`${pages.loading.route}?next=${query.next ?? pathname}`))
        } else {
          yield put(replace(`${pages.loading.route}`))
        }
      } else {
        if (!userRegistered) {
          if (pathname !== pages.userRegistration.route) {
            yield put(replace(pages.userRegistration.route))
          }
          return
        }
        if (pathname === pages.userRegistration.route) {
          yield put(replace(pages.loading.route))
          return
        }
        if (!isAdminPortal && pathname === pages.adminDashboard.route) {
          yield put(replace(pages.loading.route))
          return
        } 
      }
    }
  }
}

function* processSignIn(action: PayloadAction<SignIn>) {
  yield put(startProcess())
  const { email, password } = action.payload
  try {
    const response = (yield call(
      authenticate,
      email,
      password
    )) as AxiosResponse
    const payload: AuthenticatePayload = get(response, "data")
    const userSignIn = getUserSignIn(payload)
    yield put(installUserSignIn(userSignIn))
    yield put(installUserSignUp({}))
    yield put(checkUserAction())
  } catch (err) {
    yield put(installUserSignIn({ invalidCredential: true }))
  }
}

function* processForgetPassword(action: PayloadAction<string>) {
  yield put(startProcess())
  const email = action.payload
  try {
    yield call(forgetPassword, email)
  } catch (err) {
    // continue regardless of error
  }
  yield put(setProcessedPassword())
}

function* processResetPassword(action: PayloadAction<ResetPassword>) {
  yield put(startProcess())
  const { uid, token, password } = action.payload
  try {
    yield call(resetPassword, uid, token, password)
  } catch (err) {
    const { data } = get(err, 'response') as AxiosResponse
    if (data.password) {
      yield put(
        installUserSignUp({ error: { invalidPassword: data.password } })
      )
      return        
    }
    yield put(installUserSignUp({ error: { invalidToken: true } }))
  }
  yield put(setProcessedPassword())
}

function* processRefresh(action: PayloadAction<string>) {
  const { user } = (yield select()) as RootState
  yield put(startProcess())
  const token = action.payload
  try {
    const response = (yield call(refreshToken, token)) as AxiosResponse
    const payload: RefreshTokenPayload = get(response, "data")
    const userSignIn = getRefreshSignIn(user, payload)
    yield put(installUserSignIn(userSignIn))
  } catch (err) {
    yield put(installUserSignIn({ invalidCredential: true }))
  }
}

function* processSignOut() {
  yield put(installUserSignIn(undefined))
  yield put(checkUserAction())
}

function* processCheckCoupon(action: PayloadAction<string>) {
  yield put(startProcess())
  const coupon = action.payload
  try {
    const response = (yield call(checkCoupon, coupon)) as AxiosResponse
    const userSignUp: UserSignUp = get(response, "data")
    yield put(installUserSignUp(userSignUp))
  } catch (err) {
    yield put(installUserSignUp({ error: { invalidCouponCode: true } }))
  }
}

function* processSignUp(action: PayloadAction<SignUp>) {
  const state: RootState = yield select()
  const { signUp } = state.user
  yield put(startProcess())
  const { coupon, email, password, firstName, lastName } = action.payload
  try {
    const response = (yield call(
      signUpAccount,
      coupon,
      email,
      password,
      firstName,
      lastName,
    )) as AxiosResponse
    const payload: AuthenticatePayload = get(response, "data")
    const userSignIn = getUserSignIn(payload)
    yield put(installUserSignIn(userSignIn))
    yield put(installUserSignUp({}))
    yield put(checkUserAction())
  } catch (err) {
    const { data } = get(err, 'response') as AxiosResponse
    if (data.password) {
      yield put(
        installUserSignUp({ ...signUp, error: { invalidPassword: data.password } })
      )
      return        
    }
    yield put(
      installUserSignUp({ ...signUp, error: { unavailableEmail: true } })
    )
  }
}

function* processCheckEmailAddress(action: PayloadAction<CheckEmailAddress>) {
  const state: RootState = yield select()
  const { signUp } = state.user
  yield put(startProcess())
  const { coupon, email } = action.payload
  try {
    yield call(checkEmailAddress, coupon, email)
    yield put(installUserSignUp({ ...signUp, error: undefined }))
  } catch (err) {
    yield put(
      installUserSignUp({ ...signUp, error: { unavailableEmail: true } })
    )
  }
}

function* processGetUserDetails() {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    try {
      const response = (yield call(
        fetchUserDetails,
        authToken
      )) as AxiosResponse
      const details: UserDetails = get(response, "data")
      if (!isUserDetailsExpired(details)) {
        yield put(installUserDetails(details))
      }
    } catch (err) {
      // continue regardless of error
    }
  }
}

function* processUpdateUserDetails(action: PayloadAction<UserDetails>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    try {
      const response = (yield call(
        patchUserDetails,
        authToken,
        action.payload
      )) as AxiosResponse
      const details: UserDetails = get(response, "data")
      yield put(installUserDetails(details))
    } catch (err) {
      yield put(installUserDetails(action.payload))
    }
  }
}

function* processGetM5Link() {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    try {
      const response = (yield call(fetchM5URL, authToken)) as AxiosResponse
      const { m5Link } = get(response, "data") as UserDetails
      yield put(setM5Link(m5Link))
    } catch (err) {
      yield put(setM5Link(undefined))
    }
  }
}

function* processUpdateAge(action: PayloadAction<number | null>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const userDetails: UserDetails = { age: action.payload }
    try {
      const response = (yield call(
        patchUserDetails,
        authToken,
        userDetails
      )) as AxiosResponse
      const details: UserDetails = get(response, "data")
      yield put(installUserDetails(details))
    } catch (err) {
      yield put(installUserDetails(userDetails))
    }
  }
}

function* processContactUs(action: PayloadAction<ContactUsPayload>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined || (action.payload.email && action.payload.name)) {
    const { subject, body, email, name, phone } = action.payload
    const url = window.location.href
    yield put(startProcess())
    try {
      yield call(submitContactUs, subject, body, url, authToken, email, name, phone)
      yield put(setProcessedContactUs('success'))
    } catch (err: any) {
      if (err.response.status < 500 && err.response.data.email) {
        yield put(setProcessedContactUs('emailError'))
      } else {
        yield put(setProcessedContactUs('fail'))
      }
    }
  }
}

function* processContactUsSubjects() {
  yield put(startProcess())
  try {
    const response = (yield call(fetchContactUsSubjects)) as AxiosResponse
    const payload: ContactUsSubjectsPayload = get(response, "data")
    yield put(setContactUsSubjects(payload))
  } catch (err) {
    yield put(setContactUsSubjects({ contactSubjects: []}))
  }
}

function* procesBulkUpdateExercise(action: PayloadAction<BulkUpdateExercisePayload>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const { exercise, entries } = action.payload
    yield put(startProcess())
    try {
      const response = (yield call(bulkUpdateExercise, authToken, exercise, entries)) as AxiosResponse
      const result: BulkUpdateExerciseResponse = get(response, 'data')
      yield put(setProcessedBulkUpdateExercise(result))
    } catch (err) {
      yield put(setProcessedBulkUpdateExercise({ success: false, count: 0 }))
    }
    const dates = entries.map(({ startDate }) => startDate)
    const datesOfWeek = getDatesOfWeek(dates)
    yield all(datesOfWeek.map(dateOfWeek => put(resetGoalDetails(dateOfWeek))))
  }
}

function* processUpdateUserSettings(action: PayloadAction<UserSettings>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const userSettings = action.payload
    yield put(startProcess())
    try {
      const response = (yield call(updateUserSettings, authToken, userSettings)) as AxiosResponse
      const result: UserSettings = get(response, 'data')
      yield put(installUserSettings(result))
    } catch (err) {
      const { settings } = state.user
      yield put(installUserSettings({ ...settings, error: { generalError: true } }))
    }
  }
}

function* getUserSettingsCall(trial: number): any {
  const state: RootState = yield select()
  const { settings } = state.user
  if (settings === undefined) {
    const authToken = getAuthToken(state)
    if (trial > 0) {
      if (authToken !== undefined) {
        try {
          const response = (yield call(fetchUserSettings, authToken)) as AxiosResponse
          const settings: UserSettings = get(response, 'data')
          yield put(installUserSettings(settings))  
        } catch (err) {
          yield delay(1000)
          yield getUserSettingsCall(trial - 1)
        }
      } else {
        yield delay(1000)
        yield getUserSettingsCall(trial - 1)
      }
    } else {
      yield put(installUserSettings({ error: { generalError: true } }))
    }
  }
}

function* getUserSettings() {
  const state: RootState = yield select()
  const router = state.router
  const pathname = get(router, 'location.pathname') as string | undefined
  if (pathname === pages.userSettings.route) {
    yield getUserSettingsCall(MAX_FETCH_SETTINGS_TRIALS)
  }
}

function* getRegisterOptionsCall(trial: number): any {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (trial > 0) {
    if (authToken !== undefined) {
      try {
        const response = (yield call(fetchRegisterOptions, authToken)) as AxiosResponse
        const options: UserRegisterOptions = get(response, 'data')
        yield put(installRegisterOptions(options))  
      } catch (err) {
        yield delay(1000)
        yield getRegisterOptionsCall(trial - 1)
      }
    } else {
      yield delay(1000)
      yield getRegisterOptionsCall(trial - 1)
    }
  } else {
    yield put(registerError({ generalError: true }))
  }
}

function* getRegisterOptions() {
  const state: RootState = yield select()
  const router = state.router
  const pathname = get(router, 'location.pathname') as string | undefined
  if (pathname === pages.userRegistration.route) {
    yield getRegisterOptionsCall(MAX_FETCH_SETTINGS_TRIALS)
  }
}

function* processChangePassword(action: PayloadAction<ChangePassword>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    const { settings } = state.user
    const passwords = action.payload
    yield put(startProcess())
    try {
      yield call(changePassword, authToken, passwords)
      yield put(installUserSettings({ ...settings, error: undefined }))
    } catch (err) {
      const { status, data } = get(err, 'response') as AxiosResponse
      if (status === 400 && data.oldPassword) {
        yield put(installUserSettings({ ...settings, error: { invalidOldPassword: true } }))
      } else if (status === 400 && data.newPassword) {
        yield put(installUserSettings({ ...settings, error: { invalidNewPassword: data.newPassword } }))
      } else if (status === 400 && data.non_field_errors) {
        yield put(installUserSettings({ ...settings, error: { samePasswords: true } }))
      } else {
        yield put(installUserSettings({ ...settings, error: { generalError: true } }))
      }
    }
  }
}

function* processRegistration(action: PayloadAction<UserRegistration>) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (authToken !== undefined) {
    yield put(startProcess())
    try {
      const response = (yield call(register, authToken, action.payload)) as AxiosResponse
      const details: UserDetails = get(response, "data")
      if (!isUserDetailsExpired(details)) {
        yield put(installUserDetails(details))
      }
      yield put(checkUserAction())
    } catch (err) {
      const { status, data } = get(err, 'response') as AxiosResponse
      if (status === 400) {
        yield put(registerError(data as UserRegistrationError))
      } else {
        yield put(registerError({ generalError: true }))
      }
    }    
  }
}

export function* userWatcher() {
  yield takeLatest(checkUserAction, checkUser)
  yield takeLatest(signInAction, processSignIn)
  yield takeLatest(refreshAction, processRefresh)
  yield takeLatest(signOutAction, processSignOut)
  yield takeLatest(forgetPasswordAction, processForgetPassword)
  yield takeLatest(resetPasswordAction, processResetPassword)
  yield takeLatest(checkCouponAction, processCheckCoupon)
  yield takeLatest(signUpAction, processSignUp)
  yield takeLatest(checkEmailAddressAction, processCheckEmailAddress)
  yield takeLatest(getUserDetailsAction, processGetUserDetails)
  yield takeLatest(updateUserDetails, processUpdateUserDetails)
  yield takeLatest(getM5Link, processGetM5Link)
  yield debounce(3000, updateAge, processUpdateAge)
  yield takeLatest(contactUs, processContactUs)
  yield takeLatest(requestContactUsSubjects, processContactUsSubjects)
  yield takeLatest(bulkUpdateExerciseAction, procesBulkUpdateExercise)
  yield takeLatest(updateUserSettingsAction, processUpdateUserSettings)
  yield takeLatest(LOCATION_CHANGE, getUserSettings)
  yield takeLatest(LOCATION_CHANGE, getRegisterOptions)
  yield takeLatest(changePasswordAction, processChangePassword)
  yield takeLatest(registerAction, processRegistration)
}
