import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Card, Button } from 'antd'
import { CeExercise, ceLimits } from '../ceSlice'
import CeTimer from './CeTimer'
import CeVideo from './CeVideo'
import FooterButtons from '../../common/components/FooterButtons'
import { IButton } from '../../../constants/pages'
import ExerciseManualEntry from '../../common/components/ExerciseManualEntry'

interface ICeExerciseDetailProps {
  exercise?: CeExercise
  goBack: () => void
  doneExercise: (duration: number, startDate?: number) => void
  updateDuration: (duration: number) => void
}

const CeExerciseDetail = ({ exercise, goBack, doneExercise, updateDuration }: ICeExerciseDetailProps) => {
  const [duration, setDuration] = useState<number>(0)
  const [timeStamp, setTimeStamp] = useState<number|undefined>(undefined)
  const [isCounting, setIsCounting] = useState<boolean>(false)
  const [manual, setManual] = useState<boolean>(false)
  const [minutes, setMinutes] = useState<number>(ceLimits.default)
  const [startDate, setStartDate] = useState<number|undefined>(undefined)

  useEffect(() => {
    let updateInterval: number|undefined = undefined
    if (isCounting) {      
      updateInterval = window.setInterval(() => timeStamp && updateDuration(Math.round((Date.now() - timeStamp) / 1000)), 3000)
    } else {
      window.clearInterval(updateInterval)
    }
    return () => { 
      window.clearInterval(updateInterval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting, timeStamp])

  if (exercise === undefined) { return null }

  const handlePauseResume = () => {
    setIsCounting(!isCounting)
    if (isCounting) {      
      if (timeStamp) {
        const newDuration = Date.now() - timeStamp
        setDuration(newDuration)
        updateDuration(Math.round(newDuration / 1000))  
      }
    } else {
      setTimeStamp(Date.now() - duration)
      updateDuration(Math.round(duration / 1000))
    }
  }

  const startCounting = () => {
    setIsCounting(true)
    setTimeStamp(Date.now() - duration)
  }
  
  const timerButtons: IButton[] = []
  
  const goBackButton: IButton = { label: 'Choose another cardio exercise', action: goBack, buttonType: 'default' }

  if (timeStamp === undefined) {
    timerButtons.push(goBackButton)
    timerButtons.push({ label: 'Record your session here', action: startCounting, buttonType: 'primary' })
  } else {
    const countLabel = isCounting ? 'Pause' : 'Resume'
    const countButtonType = isCounting ? 'danger' : 'success'
    timerButtons.push({ label: countLabel, action: handlePauseResume, buttonType: countButtonType })
    timerButtons.push({ label: 'Done', action: () => doneExercise(Math.round(duration / 1000)), buttonType: 'primary' })
  }

  const manualButtons: IButton[] = [
    goBackButton,
    { label: 'Add', action: () => doneExercise(minutes * 60, startDate), buttonType: 'primary' }
  ]

  const footerButtons = !manual ? timerButtons : manualButtons

  const modeButtonLabel = manual ? 'Use Timer' : 'Manual Entry'

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col>
            <Card>
              <Row>
                <Col>
                  <h5>{exercise.name}</h5>
                </Col>
                <Col>
                  {duration === 0 && <Button type="primary" className="float-right" onClick={() => setManual(!manual)}>{modeButtonLabel}</Button>}
                </Col>
              </Row>
              <CeVideo exercise={exercise} />
              <div className='my-4' dangerouslySetInnerHTML={{ __html: exercise.blob }} />
              <CeTimer hidden={manual} isCounting={isCounting} timeStamp={timeStamp} />
              <ExerciseManualEntry 
                hidden={!manual} 
                minutes={minutes} 
                setMinutes={setMinutes} 
                startDate={startDate} 
                setStartDate={setStartDate} 
                limits={ceLimits}
              />
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterButtons
        buttons={footerButtons}
      />
    </>
  )
}

export default CeExerciseDetail