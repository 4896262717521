import React from 'react'
import { useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { getPageFromLocation } from '../../constants/pages'
import { hasSuggestedExercises, isUserSessionExpired } from '../user/userHelper'
import { Header, IHeaderProps } from './components/Header'

const HeaderConnect = () => {
  const { location } = useAppSelector((state: RootState) => state.router)
  const { signIn, details } = useAppSelector((state: RootState) => state.user)
  const userSession = !isUserSessionExpired(signIn)
  const page = getPageFromLocation(location)
  const headerProps: IHeaderProps = {
    title: page?.title ?? '',
    pageKey: page?.key ?? '',
    firstName: signIn?.firstName,
    disabledExit: page?.title === undefined,
    exitConfirm: page?.exitConfirm,
    exitDispatch: page?.exitDispatch,
    userSession,
    hasSuggestions: hasSuggestedExercises(details),
  }
  return (
    <Header { ...headerProps } />
  )
}

export default HeaderConnect