import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import { ExerciseStatus } from "../../../constants/exercises";
import { pages } from '../../../constants/pages';
import ExerciseComplete from '../../common/components/ExerciseComplete';
import ExerciseIntro from '../../common/components/ExerciseIntro';
import FooterButtons from '../../common/components/FooterButtons';
import { getCenteredBreathingText, getCognitiveFooterButtons, getTotalMinutes } from '../../common/exerciseHelper';
import { getCurrent, getLastSelectedThemeAndLevel, getResults, isReadyToStart } from '../mmHelper';
import { MmExercises as MmExercisesType, MmUserScore } from '../mmSlice';
import MmInProgress from './MmInProgress';
export interface IMmExercisesProps {
  status: ExerciseStatus
  exercises?: MmExercisesType
  userScores: MmUserScore[]
  startTime?: number
  endTime?: number
  startExercise: (theme: string, level: number) => void
  endExercise: (finished: boolean, attemps: number) => void
  next: () => void
  backTo: (route: string) => void
}

const MmExercises = ({ status, exercises, userScores, startTime, endTime, next, startExercise, endExercise, backTo }: IMmExercisesProps) => {
  const [selectedTheme, setSelectedTheme] = useState<string | undefined>(undefined)
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>(undefined)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNext = () => {
    window.scrollTo(0, 0)
    next()
  }

  const handleRepeat = () => {
    window.scrollTo(0, 0)
    const themeAndLevel = getLastSelectedThemeAndLevel(userScores) 
    if (themeAndLevel) {
      const { theme, level } = themeAndLevel
      setSelectedTheme(theme)
      setSelectedLevel(level)
      startExercise(theme, level)
    }
  }

  const handlePickThemeLevel = (theme: string, level: number) => {
    window.scrollTo(0, 0)
    setSelectedTheme(theme)
    setSelectedLevel(level)
    startExercise(theme, level)
  }

  const handleFinishExercise = (attemps: number) => {
    window.scrollTo(0, 0)
    setSelectedTheme(undefined)
    setSelectedLevel(undefined)
    endExercise(true, attemps)
  }

  const { currentExercise } = getCurrent(exercises, userScores)

  const totalMinutes = getTotalMinutes(startTime, endTime)

  const results = getResults(status, exercises?.themes || [], userScores)

  const completedExercises = currentExercise - 1
  
  return (
    <>
      <ExerciseIntro status={status}>
        <h4>Instructions</h4>
        <p>Memory Match activities provide a fun way to improve attention and memory skills. Each exercise will display several cards and you will be asked to find the matched pairs. As you turn the cards over, try to remember where you saw specific images, so you can find the “match” or the other card in the set. As you progress through the levels, the exercises become more challenging. You can keep score of the time it takes to complete the exercise and the number of attempts needed. </p>
        <p>These exercises are a great way to reduce stress and improve brain health!</p>
      </ExerciseIntro>
      <MmInProgress
        status={status}
        currentExercise={currentExercise}
        themes={exercises?.themes || []}
        pickThemeAndLevel={handlePickThemeLevel}
        finishExercise={handleFinishExercise}
        selectedLevel={selectedLevel}
        selectedTheme={selectedTheme}
      />
      <ExerciseComplete status={status} category="cognitive" totalMinutes={totalMinutes} completedExercises={completedExercises}>
        <h4>Result</h4>
        {results.map((result, i) => (
          <div key={`sec-${i}`}>
            <h5>Exercise {results.length - i}</h5>
            <Table striped>
              <tbody>
                <tr><td>Theme</td><td className="text-right">{result.theme}</td></tr>
                <tr><td>Level</td><td className="text-right">{result.level}</td></tr>
                <tr><td>Time</td><td className="text-right">{result.time}</td></tr>
                {result.attempts ? (<tr><td>Attempts</td><td className="text-right">{result.attempts}</td></tr>) : null}
              </tbody>
            </Table>
          </div>
        ))}
      </ExerciseComplete>
      <FooterButtons
        extraText={getCenteredBreathingText(status)}
        hidden={status === 'in-progress'}
        buttons={getCognitiveFooterButtons(
          status,
          pages.memoryMatch.title!,
          isReadyToStart(status, exercises),
          backTo,
          handleNext,
          handleRepeat,
          'Go to the next exercise',
          'Select a different level or theme'
        )}
      />
    </>
  )
}

export default MmExercises