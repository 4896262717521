import React, { useState, useEffect, useCallback } from 'react'
import { pullAt, filter, findLast } from 'lodash'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { DatePicker, InputNumber, Button, Result, notification } from 'antd'
import { ExerciseType, exerciseTypeMap } from '../../../constants/exercises'
import { DeleteOutlined } from '@ant-design/icons'
import { Moment } from 'moment'
import styled from 'styled-components'
import { useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'

export interface IManualExerciseEntryFormProps {
  exercise: ExerciseType
  submitEntries: (entries: Required<IManualEntry>[]) => void
  done: () => void
}

export interface IManualEntry {
  date?: Moment
  minutes?: number
}

type FormState = 'submitted' | 'confirming' | 'entry'

const FormContainer = styled(Container)`
  table thead td { 
    font-weight: bold;
  }
`

const ManualExerciseEntryForm = ({ exercise, submitEntries, done }: IManualExerciseEntryFormProps) => {
  const { processing, processedBulkUpdateExercise } = useAppSelector((state: RootState) => state.user)
  const [entries, setEntries] = useState<IManualEntry[]>([])
  const [formState, setFormState] = useState<FormState>('entry')

  useEffect(() => {
    if (entries.length) {
      const { date, minutes } = entries[entries.length - 1]
      if (!date && !minutes) { return }
    }
    setEntries([...entries, {}])
  }, [entries])

  useEffect(() => {
    if (formState === 'submitted' && !processing && processedBulkUpdateExercise && !processedBulkUpdateExercise.success) {
      notification.error({ 
        message: 'Manual Execrise Update Unsuccessful',
        description: 'Please try again later.'
      })
      setFormState('entry')
    }
  }, [formState, processedBulkUpdateExercise, processing])

  const getCompactEntries = useCallback((): Required<IManualEntry>[] =>
    filter(entries, ({ date, minutes }) => !!date && !!minutes) as Required<IManualEntry>[], [entries]) 

  if (exercise !== exerciseTypeMap.cardioExercises && exercise !== exerciseTypeMap.centeredBreathing) { return null }


  const handleUpdateValue = (index: number, value: IManualEntry) => {
    const newEntries = [...entries]
    newEntries[index] = { ...entries[index], ...value }
    setEntries(newEntries)
  }

  const handleRemove = (index: number) => {
    const newEntries = [...entries]
    pullAt(newEntries, index)
    setEntries(newEntries)
  }

  const getSingleEntryForm = (entry: IManualEntry, index: number) => (
    <tr key={`entry-${index}`}>
      <td>
        <DatePicker
          value={entry.date}
          onChange={(date) => handleUpdateValue(index, { date: date || undefined })}
          defaultPickerValue={findLast(entries, ({ date }) => !!date)?.date}
        />
      </td>
      <td>
        <InputNumber value={entry.minutes} onChange={(minutes) => handleUpdateValue(index, { minutes })} />
      </td>
      <td>
        <Button danger onClick={() => handleRemove(index)} icon={<DeleteOutlined />}>Remove</Button>
      </td>
    </tr>
  )

  const getSingleEntryConfirm = (entry: IManualEntry, index: number) => (
    <tr key={`confirm-${index}`}>
      <td>{entry.date?.format('ll')}</td>
      <td>{entry.minutes}</td>
      <td></td>
    </tr>
  )

  const nextLabel = formState === 'confirming' ? 'Submit' : formState === 'entry' ? 'Next' : 'Done'

  const handleNext = () => {
    if (formState === 'confirming') {
      submitEntries(getCompactEntries())
      setFormState('submitted')
    } else if (formState === 'submitted') {
      done()
    } else {
      setFormState('confirming')
    }
  }

  const beforeSubmitContent = (formState !== 'submitted' &&
    <Table striped>
      <thead>
        <tr><td>Date</td><td>Minutes</td><td></td></tr>
      </thead>
      <tbody>
        {formState === 'entry' && entries.map(getSingleEntryForm)}
        {formState === 'confirming' && getCompactEntries().map(getSingleEntryConfirm)}
      </tbody>
    </Table>
  )

  const afterSubmitContent = ((formState === 'submitted' && processedBulkUpdateExercise && processedBulkUpdateExercise.success) &&
    <Row>
      <Col>
        <Result
          status="success"
          title={`Total Exercise Added: ${processedBulkUpdateExercise.count}`}
        />
      </Col>
    </Row>
  )

  return (
    <FormContainer>
      <h4>Manual Entry Form</h4>
      {beforeSubmitContent}
      {afterSubmitContent}
      <Row className="justify-content-center">
        {formState === 'confirming' &&
          <Col xs={12} md={6} lg={4}>
            <Button disabled={processing} block onClick={() => setFormState('entry')}>Back</Button>
          </Col>
        }
        <Col xs={12} md={6} lg={4}>
          <Button disabled={getCompactEntries().length === 0} loading={processing} block type="primary" onClick={handleNext}>{nextLabel}</Button>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default ManualExerciseEntryForm