import { map, join } from 'lodash'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { API_HOST } from '../conf/hosts'
import { commonHeaders, QueryParams } from './ApiManager'

const COMPANY_URL = `${API_HOST}companies/`

export const fetchReportOptions = (authToken: string): Promise<AxiosResponse> => {
  const url = `${COMPANY_URL}report-options/`
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
  }
  return axios(request)
}

const fetchChart = (name: string, authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => {
  let url = `${COMPANY_URL}charts/${name}/`
  if (queryParams) {
    url += `?${join(map(queryParams, (value, key) => `${key}=${value}`), '&')}`
  }
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}` },
    method: 'GET',
    url,
    signal: cancelSignal
  }
  return axios(request)
}

export const fetchAppUtilization = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('utilization', authToken, queryParams, cancelSignal)

export const fetchManagersVsNonManagers = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('managers', authToken, queryParams, cancelSignal)

export const fetchThreeCsUtilization = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('three-cs', authToken, queryParams, cancelSignal)

export const fetchCategoryUtilization = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('category-utilization', authToken, queryParams, cancelSignal)

export const fetchGoalUtilization = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('goal-utilization', authToken, queryParams, cancelSignal)

export const fetchAllCharts = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => fetchChart('all', authToken, queryParams, cancelSignal)

export const fetchRawData = (authToken: string, queryParams?: QueryParams, cancelSignal?: AbortSignal): Promise<AxiosResponse> => {
  let url = `${COMPANY_URL}charts/raw-data/`
  if (queryParams) {
    url += `?${join(map(queryParams, (value, key) => `${key}=${value}`), '&')}`
  }
  const request: AxiosRequestConfig = {
    headers: { ...commonHeaders, Authorization: `Bearer ${authToken}`, Accept: '*/*' },
    method: 'GET',
    url,
    responseType: 'blob',
    signal: cancelSignal,
  }
  return axios(request)

}

