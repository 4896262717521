import React, { useEffect, useState } from 'react'
import { padStart } from 'lodash'
import styled from 'styled-components'
// import CeMaxHeartRate from './CeMaxHeartRate'

interface ICeTimerProps {
  hidden: boolean
  isCounting: boolean
  timeStamp?: number
}

const Counter = styled.h1`
  font-family: 'Roboto Mono', monospace;
`

const CeTimer = ({ hidden, isCounting, timeStamp }: ICeTimerProps) => {
  const [duration, setDuration] = useState<number>(0)

  useEffect(() => {
    let incInterval: number|undefined = undefined
    if (isCounting) {      
      incInterval = window.setInterval(() => timeStamp && setDuration(Date.now() - timeStamp), 100)
    } else {
      window.clearInterval(incInterval)
    }
    return () => { 
      window.clearInterval(incInterval)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting, timeStamp])

  if (hidden) { return null }

  const countingTime = () => {
    const countingHour = padStart((duration / 3600000 | 0).toString(), 2, '0')
    const countingMin = padStart(((duration / 60000) % 60 | 0).toString(), 2, '0')
    const countingSec = padStart(((duration / 1000) % 60 | 0).toString(), 2, '0')
    const countingMillieSec = ((duration / 100) % 10 | 0).toString()  
    return `${countingHour}:${countingMin}:${countingSec}.${countingMillieSec}`
  }
  
  return (
    <>
      {/* <CeMaxHeartRate /> */}
      <Counter className="text-center">{ countingTime() }</Counter>
    </>
  )
}

export default CeTimer