import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { IEntry } from "../common/exerciseHelper"
import { getNextSuggestionDue, restoreState } from "./userHelper"
import { CategoryType } from "../../constants/exercises"

export interface RefreshSignIn {
  firstName?: string
  lastName?: string
  email?: string
  userID?: number
  authToken?: string
  authExp?: number
  invalidCredential?: boolean
}

export interface UserSignIn extends RefreshSignIn {
  refreshToken?: string
  refreshExp?: number
}

export interface SignUpError {
  invalidCouponCode?: boolean
  unavailableEmail?: boolean
  invalidToken?: boolean
  invalidPassword?: string[]
}

export interface UserSignUp {
  coupon?: string
  company?: string
  error?: SignUpError
}

interface Video {  
  webm: string
  mp4: string
  poster: string
}

export type videoKey = 'introduction' | 'cognitive' | 'cardio' | 'breathing'

export type Videos = {
  [key in videoKey]: Video
}

export interface SuggestedExercise {
  type: CategoryType
  name: string
  url: string
  poster?: string
}

export interface UserDetails {
  skipWelcome?: boolean
  suggestM5?: boolean
  exp?: number
  m5Link?: string
  videos?: Videos
  age?: number | null
  firstWeek?: string
  registered?: boolean
  adminPortal?: boolean
  adminBackend?: string | null
  suggestedExercises?: SuggestedExercise[]
  suggestionFrequency?: number
  version?: number
}

export type ContactUsResponse = 'success' | 'fail' | 'emailError'

export interface BulkUpdateExerciseResponse {
  success: boolean
  count: number
}

export interface UserSettingsError {
  invalidOldPassword?: boolean
  invalidNewPassword?: string[]
  samePasswords?: boolean
  generalError?: boolean
}

export interface UserSettings {
  email?: string
  firstName?: string
  lastName?: string
  emailSubscription?: boolean
  skipWelcome?: boolean
  error?: UserSettingsError
}

export interface UserRegistration {
  role?: string
  education?: string
  race?: string
  ageRange?: string
  state?: string
  jobCode?: string
  jobCodeOther?: string
  jobStatus?: string
  yearsWithEmployer?: string
  isSupervisor?: boolean
  primaryLanguage?: string
  primaryLanguageOther?: string
  activityCardio?: number
  activityCognitive?: number
  activityBreathing?: number
  emailSubscription?: boolean
  agreeTerms?: boolean
}

export interface UserRegistrationError {
  role?: string[]
  education?: string[]
  race?: string[]
  ageRange?: string[]
  state?: string[]
  jobCode?: string[]
  jobCodeOther?: string[]
  jobStatus?: string[]
  yearsWithEmployer?: string[]
  isSupervisor?: string[]
  primaryLanguage?: string[]
  primaryLanguageOther?: string[]
  activityCardio?: string[]
  activityCognitive?: string[]
  activityBreathing?: string[]
  emailSubscription?: string[]
  agreeTerms?: string[]
  non_field_errors?: string[]
  generalError?: boolean
}

export interface RegisterOption {
  label: string
  value: string
}

export interface UserRegisterOptions {
  role: RegisterOption[]
  education: RegisterOption[]
  race: RegisterOption[]
  ageRange: RegisterOption[]
  state: RegisterOption[]
  jobCode: RegisterOption[]
  jobStatus: RegisterOption[]
  yearsWithEmployer: RegisterOption[]
  primaryLanguage: RegisterOption[]
}

export interface UserState {
  processing: boolean  
  signUp?: UserSignUp
  signIn?: UserSignIn
  processedPassword?: boolean
  details?: UserDetails
  showContactUs: boolean
  processedContactUs?: ContactUsResponse
  contactSubjects?: string[]
  processedBulkUpdateExercise?: BulkUpdateExerciseResponse
  settings?: UserSettings
  registerError?: UserRegistrationError
  registerOptions?: UserRegisterOptions
  suggestionDue?: number
}

const initialState: UserState = {
  processing: false,
  showContactUs: false
}

export interface ContactUsPayload {
  name?: string
  email?: string
  phone?: string
  subject: string
  body: string  
}

export interface ContactUsSubjectsPayload {
  contactSubjects: string[]
}

export interface BulkUpdateExercisePayload {
  exercise: string
  entries: IEntry[]
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startProcess: (state) => {
      state.processing = true
    },
    installUserSignUp: (state, action: PayloadAction<UserSignUp>) => {
      state.signUp = action.payload
      state.processing = false
      state.details = undefined
    },
    installUserSignIn: (state, action: PayloadAction<UserSignIn | undefined>) => {
      state.signIn = action.payload
      state.processing = false
      state.details = undefined
      state.settings = undefined
      state.registerError = undefined
      state.registerOptions = undefined
      state.suggestionDue = 0
    },
    setProcessedPassword: (state) => {
      state.processedPassword = true
      state.processing = false
    },
    resetProcessedPassword: (state) => {
      state.processedPassword = false
      state.processing = false
    },
    restoreUser: (state, action: PayloadAction<UserState>) => {
      restoreState(state, action.payload)
    },
    installUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.details = action.payload
    },
    setM5Link: (state, action: PayloadAction<string | undefined>) => {
      if (state.details) {
        state.details.m5Link = action.payload
        state.details.suggestM5 = false
      }
    },
    updateAge: (state, action: PayloadAction<number|null>) => {
      if (state.details) {
        state.details.age = action.payload  
      }
    },
    setShowContactUs: (state, action: PayloadAction<boolean>) => {
      state.showContactUs = action.payload
    },
    setProcessedContactUs: (state, action: PayloadAction<ContactUsResponse|undefined>) => {
      state.processing = false
      state.processedContactUs = action.payload
    },
    setContactUsSubjects: (state, action: PayloadAction<ContactUsSubjectsPayload|undefined>) => {
      state.processing = false
      state.contactSubjects = action.payload?.contactSubjects
    },
    setProcessedBulkUpdateExercise: (state, action: PayloadAction<BulkUpdateExerciseResponse|undefined>) => {
      state.processing = false
      state.processedBulkUpdateExercise = action.payload
    },
    installUserSettings: (state, action: PayloadAction<UserSettings>) => {
      state.processing = false
      state.settings = action.payload
      if (state.signIn) {
        state.signIn.firstName = action.payload.firstName
        state.signIn.lastName = action.payload.lastName
      }
      if (state.details) {
        state.details.skipWelcome = action.payload.skipWelcome
      }
    },
    registerError: (state, action: PayloadAction<UserRegistrationError>) => {
      state.processing = false
      state.registerError = action.payload
    },
    installRegisterOptions: (state, action: PayloadAction<UserRegisterOptions>) => {
      state.processing = false
      state.registerOptions = action.payload
    },
    clearCouponCode: (state) => {
      if (state.signUp) {
        state.signUp.coupon = undefined
      }
    },
    setNextSuggestionDue: (state) => {
      state.suggestionDue = getNextSuggestionDue(state)
    },
    showSuggestionNow: (state) => {
      state.suggestionDue = 0
    }
  }
})

export interface ResetPassword {
  uid: string
  token: string
  password: string
}

export interface SignIn {
  email: string
  password: string
}

export interface CheckEmailAddress {
  coupon: string
  email: string
}

export interface SignUp extends CheckEmailAddress {
  password: string
  firstName: string
  lastName: string
}

export interface ChangePassword {
  oldPassword: string
  newPassword: string
}

export const signIn = createAction<SignIn>('user/signIn')

export const signOut = createAction('user/signOut')

export const resetPassword = createAction<ResetPassword>('user/resetPassword')

export const forgetPassword = createAction<string>('user/forgetPassword')

export const refresh = createAction<string>('user/refresh')

export const checkUser = createAction('user/checkUser')

export const checkCoupon = createAction<string>('user/checkCoupon')

export const signUp = createAction<SignUp>('user/signUp')

export const checkEmailAddress = createAction<CheckEmailAddress>('user/checkEmailAddress')

export const getUserDetails = createAction('user/getUserDetails')

export const updateUserDetails = createAction<UserDetails>('user/updateUserDetails')

export const getM5Link = createAction('user/getM5Link')

export const contactUs = createAction<ContactUsPayload>('user/contactUs')

export const requestContactUsSubjects = createAction('user/requestContactUsSubjects')

export const bulkUpdateExercise = createAction<BulkUpdateExercisePayload>('user/bulkUpdateExercise')

export const updateUserSettings = createAction<UserSettings>('user/updateUserSettings')

export const changePassword = createAction<ChangePassword>('user/changePassword')

export const register = createAction<UserRegistration>('user/register')

export const contactSubjects = (state: RootState) => state.user.contactSubjects

export const contactUsStatus = (state: RootState) => ({ processing: state.user.processing, processed: state.user.processedContactUs })

export const adminBackend = (state:RootState) => state.user.details?.adminBackend

export const adminPortal = (state: RootState) => state.user.details?.adminPortal

export const {
  installUserSignIn,
  installUserSignUp,
  resetProcessedPassword,
  restoreUser,
  setProcessedPassword,
  startProcess,
  setM5Link,
  installUserDetails,
  updateAge,
  setShowContactUs,
  setProcessedContactUs,
  setContactUsSubjects,
  setProcessedBulkUpdateExercise,
  installUserSettings,
  registerError,
  installRegisterOptions,
  clearCouponCode,
  setNextSuggestionDue,
  showSuggestionNow,
} = userSlice.actions

export default userSlice.reducer
