import { Button, Collapse } from 'antd'
import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { APIMEDIA_HOST } from '../../../conf/hosts'
import FooterButtons from '../../common/components/FooterButtons'
import { CeExercise } from '../ceSlice'
// import CeMaxHeartRate from './CeMaxHeartRate'
import ChoiceButton from '../../common/components/ChoiceButton'
import { setCommonMaxHeightButtons } from '../../common/exerciseHelper'
import { WarningTwoTone } from '@ant-design/icons'

const { Panel } = Collapse;

interface ICeExerciseChooserProps {
  hidden: boolean
  exercises: CeExercise[]
  pickExercise: (value: string) => void
  goToManualEntry: () => void
}

const CeExerciseChooser = ({
  hidden,
  exercises,
  pickExercise,
  goToManualEntry,
}: ICeExerciseChooserProps) => {
  const [exerciseSlug, setExerciseSlug] = useState<string | undefined>(
    undefined
  )
  const choices = useRef(null)

  useEffect(() => {
    setCommonMaxHeightButtons(choices.current)
  }, [exercises, hidden])

  if (hidden) {
    return null
  }

  const getExerciseName = (exercise: CeExercise): JSX.Element => {
    if (exercise.preview) {
      return (
        <p>{exercise.name}<br />Coming soon!</p>
      )
    } 
    return <p>{exercise.name}</p>
  }

  const exerciseCols = exercises.map((exercise) => {
    const buttonType = exercise.slug === exerciseSlug ? 'primary' : 'default'
    return (
      <Col xs={6} sm={6} md={4} lg={3} key={exercise.slug} className="mb-4">
        <ChoiceButton 
          disabled={exercise.preview}
          className="py-3"
          size="large" 
          block
          type={buttonType}
          onClick={() => setExerciseSlug(exercise.slug)}
        >
          {exercise.thumbnail && <Image draggable={false} src={`${APIMEDIA_HOST}${exercise.thumbnail}`} fluid />}
          <div className="my-1">{getExerciseName(exercise)}</div>
        </ChoiceButton>
      </Col>
    )
  })

  const readyToSubmit = exerciseSlug !== undefined

  const handleNext = () => {
    if (readyToSubmit && exerciseSlug) {
      pickExercise(exerciseSlug)
      setExerciseSlug(undefined)
    }
  }

  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <h5>Choose a Cardio Exercise </h5>
          </Col>
          <Col>
            <Button onClick={goToManualEntry} className="float-right" type="primary">Manual Entry</Button>
          </Col>
        </Row>
        <Row ref={choices}>{exerciseCols}</Row>
        <div className="my-4">
          <Collapse>
            <Panel key="1" header="What to do before beginning an exercise program?">
              <p>If you are new to exercising or have not exercised in a while, consider seeing your health care provider before you begin. Underlining health conditions can increase your risk for injury.&nbsp;</p>
              <ul>
                <li>Always complete a proper warm up and cool down as it will increase blood flow, prevent injury, and increase flexibility. Warming up may also help reduce muscle soreness.&nbsp;</li>
                <li>If able, wear comfortable clothes and shoes (i.e., tennis shoes) to provide good support during your workout</li>
                <li>Focus on doing the exercise the right way as poor technique can lead to injury</li>
                <li>Choose activities that you can enjoy and sustain over time</li>
              </ul>

            </Panel>
            <Panel key="2" header="How do you know if your exercise effort is intense enough?">
              <p>There are two methods for measuring your exercise intensity. One is simple, while the other is more scientific.&nbsp;</p>
              <ul>
                <li className='my-4'>The simple method involves rating how hard the exercise <strong><em>feels</em></strong>.&nbsp;
                  <Button target="_blank" href='https://www.thebcat.com/sites/default/files/2022-06/15%20for%20Me_Measuring%20Exercise%20Intensity_Simple%20Method_FINAL_5.22.pdf'>Learn More</Button>
                </li>
                <li className='my-4'>The scientific method involves <strong><em>identifying a target heart rate </em></strong>range and comparing it with your actual heart rate.&nbsp;
                  <Button target="_blank" href='https://www.thebcat.com/sites/default/files/2022-06/15%20for%20Me_Measuring%20Exercise%20Intensity_Scientific%20Method_FINAL.pdf'>Learn More</Button>
                </li>
              </ul>
            </Panel>
            <Panel key="3" header={<><WarningTwoTone twoToneColor="#faad14" /><span>Caution</span></>}>
              <p>Stopping exercise abruptly can cause light-headedness, as your heart rate and blood pressure may drop too rapidly. Transitioning from exercise into centered breathing allows for a more gradual transition from intense exercise back into your normal physical state. This will help you to feel ready to jump back into your usual routine feeling refreshed.&nbsp;</p>
            </Panel>
          </Collapse>
        </div>
        {/* <CeMaxHeartRate /> */}
      </Container>
      <FooterButtons
        buttons={[
          {
            disabled: !readyToSubmit,
            label: "Start",
            action: handleNext,
            buttonType: "primary",
          },
        ]}
      />
    </>
  )
}

export default CeExerciseChooser
