import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import PasswordReset from './components/PasswordReset'
import { installUserSignIn, resetPassword, resetProcessedPassword } from './userSlice'
import { pages } from '../../constants/pages'
import { push } from 'connected-react-router'

const PasswordResetConnect = () => {
  const dispatch = useAppDispatch()
  const { location } = useAppSelector((state: RootState) => state.router)
  const { processing, signUp, processedPassword } = useAppSelector((state: RootState) => state.user)
  const pathnames = get(location, 'pathname', '').split('/').filter(path => path.length > 0)
  const uid  = pathnames[1]
  const token = pathnames[2]

  useEffect(() => {
    dispatch(resetProcessedPassword())
    dispatch(installUserSignIn({}))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetPassword = (uid: string, token: string, password: string) => {
    dispatch(resetPassword({ uid, token, password }))
  }

  const handleGoToSignIn = () => {
    dispatch(push(pages.signIn.route))
  }

  return (
    <PasswordReset 
      uid={uid}
      token={token}
      processing={processing}
      processed={processedPassword}
      error={signUp?.error}
      resetPassword={handleResetPassword}
      goToSignIn={handleGoToSignIn}
    />
  )
}

export default PasswordResetConnect