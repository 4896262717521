import { pull, union } from 'lodash'
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface GenericData {
  label: string
  value: number
}

export interface UtilizationData {
  total: number
  data: GenericData[]
}

export type UtilizationKeys = 'count' | 'sessions' | 'minutes'

export type Utilization = {
  [key in UtilizationKeys]: UtilizationData
}

export interface ManagersVsNonManagers {
  month: string
  manager: number
  nonManager: number
}

export interface ThreeCsUtilization {
  month: string
  cardio: number
  centeredBreathing: number
  cognitive: number
}

export type CategoryUtilizationKeys = 'cardio' | 'centeredBreathing' | 'cognitive'

export type CategoryUtilization = {
  [key in CategoryUtilizationKeys]: GenericData[]
}

export interface GoalUtilization {
  usersCount: number
  setGoals: number
  metGoals: number
}

export interface Charts {
  utilization?: Utilization
  managers?: ManagersVsNonManagers[]
  threeCs?: ThreeCsUtilization[]
  categoryUtilization?: CategoryUtilization
  goalUtilization?: GoalUtilization | null
  rawData?: Blob
  exp?: number
  error?: boolean
}

export interface CustomRange {
  alpha: string | null
  omega: string | null
}

export interface Filters {
  useCustomRange: boolean
  lastNumOfMonths: number
  customRange: CustomRange
  industries: string[]
  sizes: string[]
  companies: string[]
  fetched: boolean
}

export interface Option {
  label: string
  value: string
}

export interface FilterOptions {
  companies?: Option[]
  sizes?: Option[]
  industries?: Option[]
  exp?: number
  error?: boolean
}

export interface PortalAdminState {
  filters: Filters
  filterOptions?: FilterOptions
  charts?: Charts
}

const initialState: PortalAdminState = {
  filters: {
    useCustomRange: false,
    lastNumOfMonths: 3,
    customRange: {
      alpha: null,
      omega: null,
    },
    industries: [],
    sizes: [],
    companies: [],
    fetched: false,
  }
}

export const portalAdminSlice = createSlice({
  name: 'portalAdmin',
  initialState,
  reducers: {
    setUseCustomeRange: (state, action: PayloadAction<boolean>) => {
      state.filters.useCustomRange = action.payload
      state.filters.fetched = false
    },
    setLastNumOfMonths: (state, action: PayloadAction<number>) => {
      state.filters.lastNumOfMonths = action.payload
      state.filters.fetched = false
    },
    setCustomRange: (state, action: PayloadAction<CustomRange>) => {
      state.filters.customRange = action.payload
      state.filters.fetched = false
    },
    addIndustry: (state, action: PayloadAction<string>) => {
      state.filters.industries = union(state.filters.industries, [action.payload])
      state.filters.fetched = false
    },
    removeIndustry: (state, action: PayloadAction<string>) => {
      pull(state.filters.industries, action.payload)
      state.filters.fetched = false
    },
    addSize: (state, action: PayloadAction<string>) => {
      state.filters.sizes = union(state.filters.sizes, [action.payload])
      state.filters.fetched = false
    },
    removeSize: (state, action: PayloadAction<string>) => {
      pull(state.filters.sizes, action.payload)
      state.filters.fetched = false
    },
    addCompany: (state, action: PayloadAction<string>) => {
      state.filters.companies = union(state.filters.companies, [action.payload])
      state.filters.fetched = false
    },
    removeCompany: (state, action: PayloadAction<string>) => {
      pull(state.filters.companies, action.payload)
      state.filters.fetched = false
    },
    installFilterOptions: (state, action: PayloadAction<FilterOptions>) => {
      state.filterOptions = action.payload
    },
    installCharts: (state, action: PayloadAction<Charts|undefined>) => {
      state.charts = { ...state.charts, ...action.payload }
      state.filters.fetched = true
    },
    startFetchingCharts: (state) => {
      state.charts = undefined
    },
    startFetcchingRawData: (state) => {
      if (state.charts) {
        state.charts.rawData = undefined
      }
    },
    installRawData: (state, action: PayloadAction<Blob>) => {
      if (state.charts) {
        state.charts.rawData = action.payload
      }
    },
    reset: () => {
      return initialState
    }
  }
})

export const getCharts = createAction('portalAdmin/getCharts')

export const getFilterOptions = createAction('portalAdmin/getFilterOptions')

export const getRawData = createAction('portalAdmin/getRawData')

export const cancelGetRawData = createAction('portalAdmin/cancelGetRawData')

export const { 
  setUseCustomeRange,
  setLastNumOfMonths,
  setCustomRange,
  addIndustry,
  removeIndustry,
  addSize,
  removeSize,
  addCompany,
  removeCompany,
  installFilterOptions,
  installCharts,
  startFetchingCharts,
  startFetcchingRawData,
  installRawData,
  reset,
} = portalAdminSlice.actions

export default portalAdminSlice.reducer
