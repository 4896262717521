import { Result } from 'antd'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { CategoryType, ExerciseStatus } from '../../../constants/exercises'
import { getAffirmation } from '../exerciseHelper'

export interface IExerciseCompleteProps {
  status: ExerciseStatus
  category: CategoryType
  totalMinutes?: number
  completedExercises?: number
  children?: React.ReactNode
}

const ExerciseComplete = ({ status, category, totalMinutes, completedExercises, children }: IExerciseCompleteProps) => {
  if (status !== 'completed' && status !== 'check-point') { return null }

  return (
    <Container>
      <Row>
        <Col>
          <Result
            status="success"
            title={getAffirmation(category)}
            subTitle={`Total Minutes: ${totalMinutes}`}
          />        
        </Col>
      </Row>
      {children && (
        <Row className="my-4 justify-content-center">
          <Col xs={12} md={10} lg={8}>
            {children}
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default ExerciseComplete

