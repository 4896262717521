import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Slider, DatePicker } from 'antd'
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons'
import moment, { Moment } from 'moment'
import { MinMaxDefault } from '../../../constants/exercises'

interface ICeManualEntryProps {
  hidden: boolean
  minutes: number
  setMinutes: (value: number) => void
  startDate?: number
  setStartDate: (value?: number) => void
  limits: MinMaxDefault
}

const ExerciseManualEntry = ({ hidden, minutes, startDate, limits, setMinutes, setStartDate }: ICeManualEntryProps) => {

  if (hidden) { return null }

  const handleIncreaseDecrease = (value: number) => {
    const nextValue = minutes + value
    if (limits.min <= nextValue && nextValue <= limits.max) {
      setMinutes(nextValue)
    }
  }

  const handleSetStartDate = (date: Moment|null) => {
    if (date !== null) {
      const normalizedDate = Math.round(date.startOf('d').add(12, 'h').valueOf() / 1000)
      setStartDate(normalizedDate)
    } else {
      setStartDate(undefined)
    }
  }

  const minutesSlider = (
    <Slider
      key="minutes-slider"
      min={limits.min}
      max={limits.max}
      tipFormatter={(value) => `${value} minutes`}
      value={minutes}
      tooltipVisible={false}
      onChange={(value: number) => setMinutes(value)}
    />
  )

  const plusButton = <PlusCircleFilled style={{ fontSize: "40px" }} className="float-left" onClick={() => handleIncreaseDecrease(1)} />

  const minusButton = <MinusCircleFilled style={{ fontSize: "40px" }} className="float-right" onClick={() => handleIncreaseDecrease(-1)} />

  return (
    <>
      <Row>
        <Col>
          <DatePicker
            size="large"
            inputReadOnly
            disabledDate={(date: Moment): boolean => moment().isBefore(date)}
            value={startDate !== undefined ? moment.utc(startDate * 1000).local() : undefined}
            onChange={handleSetStartDate}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="text-center"><span className="h2">{minutes}</span>{' minutes'}</p>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xs={2}>
          {minusButton}
        </Col>
        <Col xs={8}>
          {minutesSlider}
        </Col>
        <Col xs={2}>
          {plusButton}
        </Col>
      </Row>
    </>
  )
}

export default ExerciseManualEntry