import { Card, Button } from 'antd'
import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export interface IM5TestProps {
  m5Link?: string
  gotoHome?: () => void
}

const M5Test = ({ m5Link, gotoHome }: IM5TestProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <Row className='mb-4'>
        <Col xs={12} md={6}>
          <p>Getting Started: Measuring Stress</p>
          <ul>
            <li>It can be helpful for participants to determine their current stress level at the beginning of the program, to track progress.</li>
            <li>The 15 For Me&reg; program uses the Mood-5&reg; (M5&reg;) scale.</li>
            <li>The M5&reg; consists of 5 questions and can be completed in less than one minute.</li>
            <li>Scoring is based on a 0-10 scale and participants receive an immediate measure of their current stress level.</li>
          </ul>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <p>If you complete the Mood-5&reg; (M5&reg;), your information will be kept private.</p>
            <Button href={m5Link} target="_blank" onClick={gotoHome} loading={m5Link === undefined} block type="primary" size="large">Take the Mood-5&reg; (M5&reg;) Now</Button>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default M5Test