import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { MinMaxDefault } from '../../constants/exercises';
import { getCurrentWeek, getSelectedGoalDetails } from './goalHelper';

export interface ExerciseDetail {
  category: string
  name: string
  minutes: number
}

export interface Measure {
  count?: number
  minutes?: number
}

export interface CountMinutes {
  count: number
  minutes: number
}

export interface DailyDetails {
  total: CountMinutes
  cognitive: CountMinutes
  cardio: CountMinutes
  centeredBreathing: CountMinutes
  exercises?: ExerciseDetail[]
}

export type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'

export type MeasureType = 'count' | 'minutes'

export type Weekly = {
  [key in DayOfWeek]?: DailyDetails;
};

export interface GoalDetails {
  type: MeasureType | null
  dateOfWeek: string
  goal?: Measure
  weekly: Weekly
}

export type GoalLimits = {
  [key in MeasureType]: MinMaxDefault
}

export const goalLimits: GoalLimits = {
  count: { min: 1, max: 50, default: 21 },
  minutes: { min: 5, max: 500, default: 105 }
}

export interface GoalState {
  selectedDateOfWeek?: string
  details: {
    [dateOfWeek: string]: GoalDetails | null
  }
}

const initialState: GoalState = {
  details: {} 
}

export const goalSlice = createSlice({
  name: 'goal',
  initialState,
  reducers: {
    installGoalDetails: (state, action: PayloadAction<GoalDetails | null>) => {
      const goalDetails = action.payload
      if (goalDetails) {
        const { dateOfWeek } = goalDetails
        state.details[dateOfWeek] = goalDetails
        state.selectedDateOfWeek = dateOfWeek
      } else if (state.selectedDateOfWeek) {
        state.details[state.selectedDateOfWeek] = null
      }
    },
    resetGoalDetails: (state, action: PayloadAction<string|undefined>) => {
      let dateOfWeek = action.payload
      if (dateOfWeek === undefined) {
        dateOfWeek = getCurrentWeek()
      } 
      delete state.details[dateOfWeek]
    },
    resetAllGoalDetails: () => {
      return initialState
    },
    setDateOfWeek: (state, action: PayloadAction<string>) => {
      state.selectedDateOfWeek = action.payload
    }
  }
})

export interface UpdateGoalPayload {
  type: MeasureType
  count?: number
  minutes?: number
}

export const updateGoal = createAction<UpdateGoalPayload>('goal/updateGoal')

export const { installGoalDetails, resetGoalDetails, setDateOfWeek, resetAllGoalDetails } = goalSlice.actions

export const goalDetails = (state: RootState) => getSelectedGoalDetails(state.goal)

export const currentGoalDetails = (state: RootState) => getSelectedGoalDetails(state.goal, true)

export default goalSlice.reducer
