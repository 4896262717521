import React from 'react'
import { Container } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import { MmTheme } from '../mmSlice'
import MmGame from './MmGame'
import MmThemeLevelChooser from './MmThemeLevelChooser'
import { getThemeAndLevel } from '../mmHelper'

export interface IMmInProgressProps {
  status: ExerciseStatus
  currentExercise: number
  themes: MmTheme[]
  selectedTheme?: string
  selectedLevel?: number
  pickThemeAndLevel: (theme: string, level: number) => void
  finishExercise: (attempts: number) => void
}

const MmInProgress = ({
  currentExercise,
  finishExercise,
  pickThemeAndLevel,
  selectedLevel,
  selectedTheme,
  status,
  themes
}: IMmInProgressProps) => {
  if (status !== 'in-progress') { return null }

  const hasSelectedThemeAndLevel = selectedTheme !== undefined && selectedLevel !== undefined

  const { theme, level } = getThemeAndLevel(themes, selectedTheme, selectedLevel)

  return (
    <>
      {
        hasSelectedThemeAndLevel && <Container>
          <h4>Exercise {currentExercise}</h4>      
        </Container>
      }
      <MmThemeLevelChooser 
        hidden={hasSelectedThemeAndLevel}
        themes={themes} 
        pickThemeAndLevel={pickThemeAndLevel} 
      />
      <MmGame 
        cardBack={theme?.cardBack}
        cards={hasSelectedThemeAndLevel ? level?.cards : undefined}
        gameCompleted={finishExercise}
      />
    </>
  )
}

export default MmInProgress