import React from "react"
import { Route, Switch, Redirect } from "react-router"
import Header from "./features/common/HeaderConnect"
import Footer from "./features/common/FooterConnect"
import { pages } from "./constants/pages"
import SignIn from "./features/user/SignInConnect"
import ForgetPassword from "./features/user/ForgetPasswordConnect"
import SignUp from "./features/user/SignUpConnect"
import Home from "./features/common/HomeConnect"
import GoalDetails from "./features/goal/GoalDetailsConnect"
import UpdateGoal from "./features/goal/UpdateGoalConnect"
import FtiExercises from "./features/flipTheImage/FtiExercisesConnect"
import NsExercises from "./features/numberSymbol/NsExercisesConnect"
import WtExercises from "./features/wordTheme/WtExercisesConnect"
import MmExercises from "./features/memoryMatch/MmExercisesConnect"
import CiExercises from "./features/colorIllusion/CiExercisesConnect"
import CbExercise from "./features/centeredBreathing/CbExerciseConnect"
import CbManualEntry from "./features/centeredBreathing/CbManualEntryConnect"
import Welcome from "./features/common/WelcomeConnect"
import PasswordReset from "./features/user/PasswordResetConnect"
import LoadStates from "./features/user/LoadStatesConnect"
import Loading from "./features/common/LoadingConnect"
import M5Test from "./features/common/M5TestConnect"
import CognitiveExercises from "./features/common/CognitiveExercisesConnect"
import CeExercise from "./features/cardioExercises/CeExerciseConnect"
import CeManualEntry from "./features/cardioExercises/CeManualEntryConnect"
import UserSettings from "./features/user/UserSettingsConnect"
import Register from "./features/user/RegisterConnect"
import AdminDashboard from "./features/portalAdmin/AdminDashboardConnect"
import ContactUsModal from "./features/user/ContactUsModalConnect"
import Subheader from "./features/common/SubheaderConnect"
import Subfooter from "./features/common/SubfooterConnect"

const App = () => {
  return (
    <div id="fifteen" onContextMenu={(event) => { event.preventDefault()}}>
      <LoadStates />
      <Header />
      <Subheader />
      <div className="content-wrapper py-4">
        <Switch>
          <Route exact path={pages.signIn.route}>
            <SignIn />
          </Route>
          <Route exact path={pages.signUp.route}>
            <SignUp />
          </Route>
          <Route exact path={pages.forgetPassword.route}>
            <ForgetPassword />
          </Route>
          <Route path={pages.passwordReset.route}>
            <PasswordReset />
          </Route>
          <Route exact path={pages.loading.route}>
            <Loading />
          </Route>
          <Route exact path={pages.home.route}>
            <Home />
          </Route>
          <Route exact path={pages.welcome.route}>
            <Welcome />
          </Route>
          <Route exact path={pages.goal.route}>
            <GoalDetails />
          </Route>
          <Route exact path={pages.setGoal.route}>
            <UpdateGoal />
          </Route>
          <Route exact path={pages.cognitiveExercises.route}>
            <CognitiveExercises />
          </Route>
          <Route exact path={pages.flipTheImage.route}>
            <FtiExercises />
          </Route>
          <Route exact path={pages.numberSymbol.route}>
            <NsExercises />
          </Route>
          <Route exact path={pages.wordTheme.route}>
            <WtExercises />
          </Route>
          <Route exact path={pages.memoryMatch.route}>
            <MmExercises />
          </Route>
          <Route exact path={pages.colorIllusion.route}>
            <CiExercises />
          </Route>
          <Route exact path={pages.cardioExercises.route}>
            <CeExercise />
          </Route>
          <Route exact path={pages.cardioExercisesManualEntry.route}>
            <CeManualEntry />
          </Route>
          <Route exact path={pages.centeredBreathing.route}>
            <CbExercise />
          </Route>
          <Route exact path={pages.centeredBreathingManualEntry.route}>
            <CbManualEntry />
          </Route>
          <Route exact path={pages.m5Test.route}>
            <M5Test />
          </Route>
          <Route exact path={pages.userSettings.route}>
            <UserSettings />
          </Route>
          <Route exact path={pages.userRegistration.route}>
            <Register />
          </Route>
          <Route exact path={pages.adminDashboard.route}>
            <AdminDashboard />
          </Route>
          <Route exact path={pages.impersonate.route}>    
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </div>
      <ContactUsModal />
      <Subfooter />
      <Footer />
    </div>
  );
}

export default App;
