import React from 'react'
import { Container } from 'react-bootstrap'
import { ExerciseStatus } from '../../../constants/exercises'
import { FtiFlipDirection, FtiExerciseSet, FtiExercise as FtiExerciseType } from '../ftiSlice'
import FtiLevelChooser from './FtiLevelChooser'
import FtiExercise from './FtiExercise'

export interface IFtiProgressProps {
  status: ExerciseStatus
  exercises: FtiExerciseType[]
  level?: number
  kind?: FtiFlipDirection
  currentExercise: number
  currentSet: number
  currentExerciseSet?: FtiExerciseSet
  answer?: number
  pickAnswer: (choice: number) => void
  next: () => void
  start: (level: number) => void
}


const FtiProgress = ({ status, exercises, level, kind, currentExercise, currentSet, currentExerciseSet, answer, pickAnswer, next, start }: IFtiProgressProps) => {

  if (status !== 'in-progress' ) { return null }

  const hasSelectedLevel = kind !== undefined && currentExerciseSet !== undefined

  return (
    <>
      <Container>
        <h3>Exercise {currentExercise}</h3>
      </Container>
      <FtiLevelChooser
        hidden={hasSelectedLevel}
        exercises={exercises}
        pickLevel={start}
      />
      <FtiExercise
        currentSet={currentSet}
        level={level}
        kind={kind}
        exerciseSet={currentExerciseSet}
        answer={answer}
        pickAnswer={pickAnswer}
        next={next}
      />
    </>
  )
}

export default FtiProgress