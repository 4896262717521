export type ExerciseStatus = 'start' | 'in-progress' | 'completed' | 'check-point'

export const WT_NUM_CHOICES = 4

export const CB_DURATION = 21

type ExerciseKey = 'flipTheImage' | 'memoryMatch' | 'colorIllusion' | 'numberSymbol' | 'wordTheme' | 'centeredBreathing' |
  'cardioExercises'

export type ExerciseType = 'flip-the-image' | 'memory-match' | 'color-illusion' | 'number-symbol' | 'word-theme' |
  'centered-breathing' | 'cardio'

export type ExerciseTypeMap = {
  [key in ExerciseKey]: ExerciseType
}

export type CategoryType = 'cognitive' | 'cardio' | 'centered-breathing'

export type CategoryTypeMap = {
  [key in ExerciseKey]: CategoryType
}

export type CategoryName = {
  [key in CategoryType]: string
}

export const categoryName: CategoryName = {
  cognitive: 'Cognitive Exercise',
  cardio: 'Cardio Exercise',
  'centered-breathing': 'Centered Breathing',
}

export const exerciseTypeMap: ExerciseTypeMap = {
  flipTheImage: 'flip-the-image',
  memoryMatch: 'memory-match',
  colorIllusion: 'color-illusion',
  numberSymbol: 'number-symbol',
  wordTheme: 'word-theme',
  centeredBreathing: 'centered-breathing',
  cardioExercises: 'cardio',
}

export const categoryTypeMap: CategoryTypeMap = {
  flipTheImage: 'cognitive',
  memoryMatch: 'cognitive',
  colorIllusion: 'cognitive',
  numberSymbol: 'cognitive',
  wordTheme: 'cognitive',
  centeredBreathing: 'centered-breathing',
  cardioExercises: 'cardio',
}

export interface MinMaxDefault {
  min: number
  max: number
  default: number
}

export const MAX_FETCH_EXERCISE_TRIALS = 3

export type Affirmation = {
  [key in CategoryType]: string[]
}

export const AFFIRMATION: Affirmation = {
  'cognitive': [
    'Nice work. Your brain is getting stronger!',
    'Congratulations! You\'re making your mind better!',
    'Keep going! You\'re building mental strength! ',
    'You are getting stronger!',
    'Be proud of what your body can do!',
    'Awesome work! Exercise is good for your mind and body!',
    'You are closer to your goal!',
    'You got this!',
    'You have taken another step forward!',
    'You can do hard things!',
    'You are committed and focused!',    
  ],
  'cardio': [
    'You are getting stronger!', 
    'You should be proud!', 
    'Great job! It was worth it!', 
    'Be proud of what your body can do!', 
    'Awesome work! Exercise is good for your mind and body!', 
    'You are closer to your goal!', 
    'You got this!', 
    'Today you became stronger!', 
    'You have taken another step forward!', 
    'Today is going to be an awesome day!', 
    'You can do hard things!',     
  ],
  'centered-breathing': [
    'Great job finding time for yourself!', 
    'You are committed and focused!', 
    'You are strong and powerful!', 
    'You are enough!', 
    'You can do anything!',
    'You are ready!', 
    'You make a difference! ', 
    'You deserve to be happy!', 
    'You can get through anything! ', 
    'You are amazing!', 
    'Be grateful for this day!',     
  ]
}