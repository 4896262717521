import { push } from 'connected-react-router'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import FtiExercises from './components/FtiExercises'
import { next, answer as submitAnswer, requestQuit, start } from './ftiSlice'

const FtiExercisesConnect = () => {
  const dispatch = useAppDispatch()
  const { status, exercises, userAnswers, startTime, endTime } = useAppSelector((state: RootState) => state.flipTheImage)

  const handleNext = (repeat?: boolean) => {
    dispatch(next(repeat))
    if (status === 'completed' && !repeat) {
      dispatch(push(pages.home.route))
    }
  }

  const handleStart = (level: number) => {
    dispatch(start(level))
  }

  const handleSubmitAnswer = (answer: number) => {
    dispatch(submitAnswer(answer))
  }

  const handleQuit = (route: string) => {
    dispatch(requestQuit(route))
  }

  return (
    <FtiExercises
      status={status}
      submitAnswer={handleSubmitAnswer}
      next={handleNext} 
      exercises={exercises} 
      userAnswers={userAnswers}
      startTime={startTime}
      endTime={endTime}
      start={handleStart}
      backTo={handleQuit}
    />
  )
}

export default FtiExercisesConnect