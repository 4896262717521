export const CHART_COLORS: string[] = [
  'rgba(94, 175, 131, 0.9)',
  'rgba(244, 173, 63)',
  'rgba(237, 98, 64)',
  'rgba(36, 104, 246)',
  'rgba(96, 108, 130)',
  'rgba(98, 87, 186)',
  'rgba(82, 182, 215)',
]

export const chartTitleConfig = {
  display: true,
  position: 'top' as const,
  align: 'start' as const,
  font: { size: 20 },
  padding: { bottom: 5 }
}

export const chartSubtitleConfig = {
  display: true,
  position: 'top' as const,
  align: 'start' as const,
  font: { size: 16 },
  padding: { bottom: 15 }
}
