import React from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap';
import styled from 'styled-components'
import CognitiveIcon from '../../../imgs/cognitive-icon.svg'
import CognitiveShadow from '../../../imgs/cognitive-shadow.svg'
import CardioIcon from '../../../imgs/cardio-icon.svg'
import CardioShadow from '../../../imgs/cardio-shadow.svg'
import BreathingIcon from '../../../imgs/breathing-icon.svg'
import BreathingShadow from '../../../imgs/breathing-shadow.svg'
import { Typography } from 'antd';

export interface IThreeCSubheaderProps {
  pageKey: string
}

const { Title } = Typography;

const SubheaderWrapper = styled.div`
  height: 128px;
  h2 {
    margin-bottom: 0;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    font-size: 36px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`;

const ThreeCSubheader = ({ pageKey }: IThreeCSubheaderProps) => {

  let title: string = ''
  let icon: string = ''
  let shadow: string = ''
  switch (pageKey) {
    case 'cognitiveExercises':
      title = 'Cognitive Exercises'
      icon = CognitiveIcon
      shadow = CognitiveShadow
      break;
    case 'cardioExercises':
      title = 'Cardio Exercises'
      icon = CardioIcon
      shadow = CardioShadow
      break;
    case 'centeredBreathing':
      title = 'Centered Breathing'
      icon = BreathingIcon
      shadow = BreathingShadow
      break;
  }

  return (
    <SubheaderWrapper>
      <Container>
        <Row className="align-items-center">
          <Col xs={4} sm={3} md={2}>
            <Image src={icon} />
          </Col>
          <Col xs={8} sm={9} md={8}>
            <Title level={2}>{title}</Title>
          </Col>
          <Col className="d-none d-md-block" md={2}>
            <Image src={shadow} />
          </Col>
        </Row>
      </Container>
    </SubheaderWrapper>
  )
}

export { ThreeCSubheader }