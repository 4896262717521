import { replace } from 'connected-react-router'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { pages } from '../../constants/pages'
import { getM5Link } from '../user/userSlice'
import M5Test from './components/M5Test'

const M5TestConnect = () => {
  const dispatch = useAppDispatch()
  const { details, signIn } = useAppSelector((state: RootState) => state.user)
  const authToken = signIn?.authToken

  useEffect(() => {
    dispatch(getM5Link())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  return (
    <M5Test 
      m5Link={details?.m5Link}
      gotoHome={() => dispatch(replace(pages.home.route))}
    />
  )
}

export default M5TestConnect