import React from 'react'
import { Image, Row, Col } from 'react-bootstrap'
import styled from 'styled-components';
import GradientShadowBottom from '../../../imgs/gradient-shadow-bottom.svg'
import GradientShadowTop from '../../../imgs/gradient-shadow-top.svg'
import TimerIcon from '../../../imgs/timer-icon.svg'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { CategoryProgress } from './CategoryProgress';
import { getGoalAndArchived, getSelectedGoalDetails, hasGoal as hasGoalHelper } from '../goalHelper';
import { Skeleton, Empty } from 'antd';
import { GoalBar } from './GoalBar';
import { push } from 'connected-react-router';
import { pages } from '../../../constants/pages';
import pluralize from 'pluralize';

const WeeklyGlanceBox = styled.div`
  cursor: pointer;
  .header {
    height: 80px;
    background: url(${GradientShadowBottom}) no-repeat center bottom / contain;
    h3 {
      color: #001E57;
      font-size: 24px;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;  
      text-align: center;    
      width: 100%;
    }
  }
  .footer {
    height: 40px;
    background: url(${GradientShadowTop}) no-repeat center top / contain;
    width: 100%;
  }
  .goal-bar {
    h3 {
      color: #001E57;
      font-size: 18px;
      font-family: Poppins, sans-serif;
      text-transform: uppercase;
      text-align: center;
    }
  }
`;

const GoalBarWrapper = styled.div`
  width: 100%;
  height: 87px;
  padding-top: 10px;
  background-color: #FFFFFF;
  border-radius: 2rem;
  p {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    color: #1679BB;
    text-transform: uppercase;
    margin-bottom: 0;
  }
`;

const WeekAtAGlance = () => {
  const goalState = useAppSelector(state => state.goal)
  const dispatch = useAppDispatch()
  
  const goalDetails = getSelectedGoalDetails(goalState, true)

  if (goalDetails === undefined) { return (<Skeleton active />) }

  const hasGoal = hasGoalHelper(goalDetails)

  const { type, goal, archived, percentage, threeCPercentage } = getGoalAndArchived(goalDetails!)
  
  const weeklyProgress = hasGoal ? (
    <Row className="my-4">
      <Col xs={4}>
        <CategoryProgress category="cognitive" progress={threeCPercentage?.cognitive || 0} />
      </Col>
      <Col xs={4}>
        <CategoryProgress category="cardio" progress={threeCPercentage?.cardio || 0} />
      </Col>
      <Col xs={4}>
        <CategoryProgress category="centeredBreathing" progress={threeCPercentage?.centeredBreathing || 0} />
      </Col>
    </Row>
  ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)

  const goalNumber = pluralize(type === 'count' ? `${goal} exercise` : `${goal} minute`, goal)

  let toGoNumber = goal ? goal - archived : undefined
  if (toGoNumber && toGoNumber < 0) { toGoNumber = 0 }

  const toGoLabel = pluralize(type === 'count' ? `${toGoNumber} exercise` : `${toGoNumber} minute`, toGoNumber)

  let toGoMessage = toGoNumber !== undefined ? `${toGoLabel} to go!` : null
  if (toGoNumber === 0) { toGoMessage = 'Goal achieved!' }

  return (
    <WeeklyGlanceBox onClick={ () => dispatch(push(pages.goal.route)) }>
      <div className="header d-flex align-items-center">
        <Image src={TimerIcon} alt="timer-icon" className="float-left mr-2" />
        <h3>This week at a <br className="d-sm-none d-lg-block" />glance</h3>
      </div>
      {weeklyProgress}
      <div className="footer">    
      </div>
      <div className="goal-bar">
        <h3><strong>Goal:</strong> {goalNumber} per week</h3>
        <GoalBarWrapper>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <GoalBar percent={percentage} style={ { width: "90%" }} />
            </Col>
            <Col xs={12}>
              <p className="py-2">{toGoMessage}</p>
            </Col>
          </Row>
        </GoalBarWrapper>
      </div>
    </WeeklyGlanceBox>
  )
}

export { WeekAtAGlance }