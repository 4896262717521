import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExerciseStatus } from "../../constants/exercises";
import { BaseExercise, BaseUserAnswer, moveNext, quitExercise } from "../common/exerciseHelper";
import { updateAnswer } from "./nsHelper";

export interface NsExercise extends BaseExercise<number[]> {
  keys: string[]
  symbols: string[]
}

export interface NsUserAnswer extends BaseUserAnswer<string[]> {}

export interface NsState {
  status: ExerciseStatus
  id?: string
  exercises: NsExercise[]
  userAnswers: NsUserAnswer[]
  startTime?: number
  endTime?: number
}

export interface NsCurrent {
  totalExercise: number
  currentExercise: number
  currentSet: number
}

const initialState: NsState = {
  status: 'start',
  exercises: [],
  userAnswers: [],
}

interface NsExercisesPayload {
  id: string
  exercises: NsExercise[]
}

export const nsSlice = createSlice({
  name: 'numberSymbol',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<NsExercisesPayload>) => {
      state.id = action.payload.id
      state.exercises = action.payload.exercises
    },
    next: (state, action: PayloadAction<boolean | undefined>) => {
      moveNext(state, action.payload)
    },
    answer: (state, action: PayloadAction<string[]>) => {
      updateAnswer(action.payload, state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('numberSymbol/requestQuit')

export const { installExercises, next, answer, quit, reset } = nsSlice.actions

export default nsSlice.reducer



