import React, { useEffect, useState } from 'react'
import { GoalCategory } from '../goalHelper'
import { Row, Col } from 'react-bootstrap'
import { Progress } from 'antd'
import styled from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

export interface ICategoryProgressProps {
  category: GoalCategory
  progress: number
}

interface ICategoryTitles {
  [key: string]: JSX.Element
}

interface ICategoryAttributes {
  [key: string]: string
}

interface ICategoryRanges {
  [key: string]: {
    from: string
    to: string
  }
}

const categoryTitles: ICategoryTitles = {
  'cognitive': (<span>Cognitive <br />Exercises</span>),
  'cardio': (<span>Cardio <br />Exercises</span>),
  'centeredBreathing': (<span>Centered <br />Breathing</span>),
} 
const categoryColors: ICategoryAttributes = {
  'cognitive': '#FF7F00',
  'cardio': '#4051A0',
  'centeredBreathing': '#35A5B4',
}
const categoryColorRanges: ICategoryRanges = {
  'cognitive': {
    from: '#FCD383',
    to: '#FCA249',
  },
  'cardio': {
    from: '#AD7EC1',
    to: '#4051A0',
  },
  'centeredBreathing': {
    from: '#6AE5D0',
    to: '#47ABED',
  },
}

const CategoryProgressRow = styled(Row)`
  h4 {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
  }
  .ant-progress-text {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: ${props => categoryColors[props.category]};
  }
`;

const CategoryProgress = ({ category, progress }: ICategoryProgressProps) => {
  const [showProgress, setShowProgress] = useState<number>(0)

  useEffect(() => {
    if (progress !== undefined) {
      setTimeout(() => {
        setShowProgress(progress)
      }, 500)
    }
  }, [progress])

  const onChangeProgressVisible = (isVisible: boolean) => {
    if (isVisible && progress) {
      setTimeout(() => {
        setShowProgress(progress)
      }, 500)
    }
  }

  return (
    <VisibilitySensor onChange={onChangeProgressVisible} >
      <CategoryProgressRow category={category}>
        <Col xs={12} className="d-flex justify-content-center py-2">
          <Progress 
            type="circle"
            percent={showProgress}
            strokeWidth={15}
            strokeColor={{ '0%': categoryColorRanges[category].from, '100%': categoryColorRanges[category].to }} 
          />
        </Col>
        <Col xs={12} className="d-flex justify-content-center py-2">
          <h4 style={ { color: categoryColors[category] } }>{categoryTitles[category]}</h4>
        </Col>
      </CategoryProgressRow>
    </VisibilitySensor>
  )
}

export { CategoryProgress }