import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExerciseStatus } from "../../constants/exercises"
import { BaseExercise } from "../common/exerciseHelper"
import { updateAnswer, moveNext, quitExercise, startExercise } from "./ftiHelper"

export interface FtiExerciseSet {
  question: string
  answers: string[]
  correctAnswer: number
}

export type FtiFlipDirection = 'vertical' | 'horizontal'

export interface FtiExercise extends BaseExercise<FtiExerciseSet> {
  level: number
  kind: FtiFlipDirection
  numOfQuestions: number
}

export interface FtiUserAnswer {
  level: number
  selectedQuestions: number[]
  answers: number[]
}

export interface FtiState {
  status: ExerciseStatus
  id?: string
  exercises: FtiExercise[]
  userAnswers: FtiUserAnswer[]
  startTime?: number
  endTime?: number
}

const initialState: FtiState = {
  status: 'start',
  exercises: [],
  userAnswers: []
}

interface FtiExercisesPayload {
  id: string
  exercises: FtiExercise[]
}

export const ftiSlice = createSlice({
  name: 'flipTheImage',
  initialState,
  reducers: {
    installExercises: (state, action: PayloadAction<FtiExercisesPayload>) => {
      state.exercises = action.payload.exercises
      state.id = action.payload.id
    },
    next: (state, action: PayloadAction<boolean|undefined>) => {
      if (action.payload !== undefined) {
        startExercise(state)
      }
      moveNext(state, action.payload)
    },
    start: (state, action: PayloadAction<number>) => {
      startExercise(state, action.payload)
      moveNext(state, true)
    },
    answer: (state, action: PayloadAction<number>) => {
      updateAnswer(action.payload, state)
      moveNext(state)
    },
    quit: (state) => {
      quitExercise(state)
    },
    reset: () => {
      return initialState
    }
  }
})

export const requestQuit = createAction<string|undefined>('flipTheImage/requestQuit')

export const { installExercises, next, answer, quit, reset, start } = ftiSlice.actions

export default ftiSlice.reducer
