import { LOCATION_CHANGE, push } from 'connected-react-router'
import { get } from 'lodash'
import { takeLatest, select, put, call, delay } from 'redux-saga/effects'
import { RootState } from '../app/store'
import { pages } from '../constants/pages'
import {
  fetchCenteredBreathingExercises,
  fetchFlipTheImageExercises,
  fetchMemoryMatchExercises,
  fetchColorIllusionExercises,
  fetchNumberSymbolExercises,
  fetchWordThemeExercises,
  fetchCardioExercises,
  updateExercise as updateExerciseApiCall,
  QueryParams
} from '../api/ApiManager'
import { AxiosResponse } from 'axios'
import { installExercises as installFtiExercises, next as ftiNext, answer as ftiAnswer, requestQuit as ftiQuit, reset as ftiReset } from '../features/flipTheImage/ftiSlice'
import { installExercises as installNsExercises, next as nsNext, answer as nsAnswer, requestQuit as nsQuit, reset as nsReset } from '../features/numberSymbol/nsSlice'
import { installExercises as installWtExercises, next as wtNext, answer as wtAnswer, requestQuit as wtQuit, reset as wtReset } from '../features/wordTheme/wtSlice'
import { installExercises as installMmExercises, next as mmNext, start as mmStart, end as mmEnd, requestQuit as mmQuit, reset as mmReset } from '../features/memoryMatch/mmSlice'
import { installExercises as installCiExercises, next as ciNext, start as ciStart, end as ciEnd, requestQuit as ciQuit, reset as ciReset } from '../features/colorIllusion/ciSlice'
import { installExercises as installCbExercises, next as cbNext, requestQuit as cbQuit, finish as cbFinish, update as cbUpdate, reset as cbReset } from '../features/centeredBreathing/cbSlice'
import { installExercises as installCeExercises, next as ceNext, requestQuit as ceQuit, finish as ceFinish, update as ceUpdate, reset as ceReset } from '../features/cardioExercises/ceSlice'
import { resetGoalDetails } from '../features/goal/goalSlice'
import { ActionCreatorWithPayload, AnyAction } from '@reduxjs/toolkit'
import { getAuthToken } from '../features/user/userHelper'
import { CategoryType, categoryTypeMap, ExerciseStatus, ExerciseType, exerciseTypeMap, MAX_FETCH_EXERCISE_TRIALS } from '../constants/exercises'
import { getTotalDuration } from '../features/common/exerciseHelper'
import { getDateOfWeek } from '../features/goal/goalHelper'
import { signOut } from '../features/user/userSlice'

function* getBaseExercises<Exercises>(fetchEndpoint: (authToken: string, queryParams?: QueryParams) => Promise<AxiosResponse>, action: ActionCreatorWithPayload<Exercises, string>, trial: number, queryParams?: QueryParams): any {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  if (trial > 0) {
    if (authToken !== undefined) {
      try {
        const response = (yield call(fetchEndpoint, authToken, queryParams)) as AxiosResponse
        const exercises: Exercises = get(response, 'data')
        yield put(action(exercises))
      } catch (err) {
        yield delay(1000)
        yield getBaseExercises(fetchEndpoint, action, trial - 1, queryParams)
      }
    } else {
      yield delay(1000)
      yield getBaseExercises(fetchEndpoint, action, trial - 1, queryParams)
    }
  }
}

function* getFlipTheImageExercises() {
  yield getBaseExercises(fetchFlipTheImageExercises, installFtiExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getNumberSymbolExercises() {
  yield getBaseExercises(fetchNumberSymbolExercises, installNsExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getWordThemeExercises() {
  yield getBaseExercises(fetchWordThemeExercises, installWtExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getMemoryMatchExercises() {
  yield getBaseExercises(fetchMemoryMatchExercises, installMmExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getColorIllusionExercises() {
  yield getBaseExercises(fetchColorIllusionExercises, installCiExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getCardioExercises() {
  yield getBaseExercises(fetchCardioExercises, installCeExercises, MAX_FETCH_EXERCISE_TRIALS)
}

function* getCenteredBreathingExercises(exercise: string | undefined) {
  const queryParams = exercise ? { exercise } : undefined
  yield getBaseExercises(fetchCenteredBreathingExercises, installCbExercises, MAX_FETCH_EXERCISE_TRIALS, queryParams)
}

function* getExercise() {
  const state: RootState = yield select()
  const router = state.router;
  const pathname = get(router, 'location.pathname') as string | undefined;
  if (pathname === pages.flipTheImage.route) {
    yield getFlipTheImageExercises()
  }
  if (pathname === pages.numberSymbol.route) {
    yield getNumberSymbolExercises()
  }
  if (pathname === pages.wordTheme.route) {
    yield getWordThemeExercises()
  }
  if (pathname === pages.memoryMatch.route) {
    yield getMemoryMatchExercises()
  }
  if (pathname === pages.colorIllusion.route) {
    yield getColorIllusionExercises()
  }
  if (pathname === pages.cardioExercises.route) {
    yield getCardioExercises()
  }
  if (pathname === pages.centeredBreathing.route) {
    const exercise = get(router, 'location.query.exercise') as string | undefined
    yield getCenteredBreathingExercises(exercise)
  }
}

function* updateExercise(action: AnyAction) {
  const state: RootState = yield select()
  const authToken = getAuthToken(state)
  const [slice, theAction] = action.type.split('/')
  const status = theAction === 'requestQuit' ? 'completed' : get(state, `${slice}.status`) as ExerciseStatus
  const id = get(state, `${slice}.id`) as string | undefined
  const categoryType: string = get(categoryTypeMap, slice) as CategoryType
  let exerciseType: string = get(exerciseTypeMap, slice) as ExerciseType
  let duration: number | undefined = undefined
  let startDate: number | undefined = undefined
  if (categoryType === 'cardio') {
    exerciseType = get(state, 'cardioExercises.selectedExercise', 'cardio') as string
    duration = getTotalDuration(get(state, 'cardioExercises.durations', []))
    startDate = get(state, 'cardioExercises.startDate') as number | undefined
  }
  if (categoryType === 'centered-breathing') {
    exerciseType = get(state, 'centeredBreathing.selectedExercise', 'centered-breathing') as string
    duration = getTotalDuration(get(state, 'centeredBreathing.durations', []))
    startDate = get(state, 'centeredBreathing.startDate') as number | undefined
  }
  if (authToken !== undefined && id !== undefined) {
    try {
      yield call(updateExerciseApiCall, authToken, id, categoryType, exerciseType, status, duration, startDate)
    } catch (err) {
      // continue regardless of error
    }
    yield put(resetGoalDetails(getDateOfWeek(startDate)))
  }
  if (theAction === 'requestQuit') {
    yield put({ type: `${slice}/quit`})
    if (action.payload) {
      yield put(push(action.payload))
    } else {
      yield put(push(pages.home.route))
    }
  }
}

function* processResetExercises() {
  yield put(ftiReset())
  yield put(nsReset())
  yield put(mmReset())
  yield put(ciReset())
  yield put(wtReset())
  yield put(cbReset())
  yield put(ceReset())
}

export function* exercisesWatcher() {
  yield takeLatest(LOCATION_CHANGE, getExercise)
  yield takeLatest(signOut, processResetExercises)

  yield takeLatest(ftiNext, updateExercise)
  yield takeLatest(ftiAnswer, updateExercise)
  yield takeLatest(ftiQuit, updateExercise)

  yield takeLatest(nsNext, updateExercise)
  yield takeLatest(nsAnswer, updateExercise)
  yield takeLatest(nsQuit, updateExercise)

  yield takeLatest(mmStart, updateExercise)
  yield takeLatest(mmNext, updateExercise)
  yield takeLatest(mmEnd, updateExercise)
  yield takeLatest(mmQuit, updateExercise)

  yield takeLatest(ciStart, updateExercise)
  yield takeLatest(ciNext, updateExercise)
  yield takeLatest(ciEnd, updateExercise)
  yield takeLatest(ciQuit, updateExercise)

  yield takeLatest(wtNext, updateExercise)
  yield takeLatest(wtAnswer, updateExercise)
  yield takeLatest(wtQuit, updateExercise)

  yield takeLatest(cbNext, updateExercise)
  yield takeLatest(cbUpdate, updateExercise)
  yield takeLatest(cbFinish, updateExercise)
  yield takeLatest(cbQuit, updateExercise)

  yield takeLatest(ceNext, updateExercise)
  yield takeLatest(ceUpdate, updateExercise)
  yield takeLatest(ceFinish, updateExercise)
  yield takeLatest(ceQuit, updateExercise)  
}
