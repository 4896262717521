import { Affix, Button } from 'antd'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { IButton } from '../../../constants/pages'
import classnames from 'classnames'

export interface IFooterButtonsProps {  
  buttons: IButton[]
  hidden?: boolean
  extraText?: string
}

const FooterButtons = ({ buttons, hidden, extraText }: IFooterButtonsProps) => {
  if (hidden === true) { return null }
  const buttonCols = buttons.map((button, index) => {
    const buttonType = button.buttonType !== 'danger' && button.buttonType !== 'success' ? button.buttonType : 'primary'
    const buttonClass = classnames({ 'bg-danger': button.buttonType === 'danger', 'bg-success': button.buttonType === 'success' })
    return (
      <Col className="mb-2" key={`footer-button-${index}`} xs={12} md={6}>
        <Button className={buttonClass} loading={button.loading} disabled={button.disabled} block size="large" type={buttonType} onClick={button.action}>{button.label}</Button>
      </Col>
    )
  })

  return (
    <Affix offsetBottom={0}>
      <Container className="py-3" style={{ background: "#EEEEEE" }}>
        {extraText && <Row className='pb-3 justify-content-center'>
          <Col xs={12} md={8}>
            {extraText}
          </Col>
        </Row>}
        <Row className="pb-3 justify-content-center">
          {buttonCols}
        </Row>
      </Container>
    </Affix>
  )
}

export default FooterButtons