import { RouterLocation } from "connected-react-router"
import { find, filter } from 'lodash'
import { ButtonType } from 'antd/lib/button'
import { requestQuit as quitFti } from "../features/flipTheImage/ftiSlice"
import { requestQuit as quitNs } from '../features/numberSymbol/nsSlice'
import { requestQuit as quitWt } from '../features/wordTheme/wtSlice'
import { requestQuit as quitMm } from '../features/memoryMatch/mmSlice'
import { requestQuit as quitCi } from '../features/colorIllusion/ciSlice'
import { requestQuit as quitCb } from '../features/centeredBreathing/cbSlice'
import { requestQuit as quitCe } from '../features/cardioExercises/ceSlice'

const root = '/'

type pageKey = 'signIn' | 'signUp' | 'forgetPassword' | 'passwordReset' | 'impersonate' | 'loading' | 'home' | 'welcome' | 'goal' | 'setGoal' | 
  'memoryMatch' | 'colorIllusion' | 'flipTheImage' | 'numberSymbol' | 'wordTheme' | 'centeredBreathing' | 'm5Test' | 'cognitiveExercises' |
  'cardioExercises' | 'cardioExercisesManualEntry' | 'centeredBreathingManualEntry' | 'userSettings' | 'userRegistration' | 'adminDashboard'

interface IPage {
  key: pageKey
  route: string
  title?: string
  public?: boolean
  exitConfirm?: boolean
  exitDispatch?: any
  showUserMenu?: boolean
  portalAdmin?: boolean
}


type Pages = {
  [key in pageKey]: IPage
}

export const pages: Pages = {
  signIn: { key: 'signIn', route: `${root}sign-in`, public: true },
  signUp: { key: 'signUp', route: `${root}sign-up`, public: true },
  forgetPassword: { key:'forgetPassword', route: `${root}forget-password`, public: true },
  passwordReset: { key:'passwordReset', route: `${root}password-reset`, public: true },
  impersonate: { key:'impersonate', route: `${root}impersonate`, public: true },
  loading: { key:'loading', route: `${root}` },
  home: { key:'home', route: `${root}home`, showUserMenu: true  },
  welcome: { key: 'welcome', route: `${root}welcome` },
  goal: { key:'goal', route: `${root}goal`, title: "My Weekly Goal" },
  setGoal: { key:'setGoal', route: `${root}goal/update`, title: "My Weekly Goal", exitConfirm: true },
  cognitiveExercises: { key:'cognitiveExercises', route: `${root}cognitive`, title: "Cognitive Exercises", exitConfirm: true },
  flipTheImage: { key:'flipTheImage', route: `${root}cognitive/flip-the-image`, title: "Flip The Image", exitConfirm: true, exitDispatch: quitFti },
  memoryMatch: { key:'memoryMatch', route: `${root}cognitive/memory-match`, title: "Memory Match", exitConfirm: true, exitDispatch: quitMm },
  colorIllusion: { key:'colorIllusion', route: `${root}cognitive/color-illusion`, title: "Color Illusion", exitConfirm: true, exitDispatch: quitCi },
  numberSymbol: { key:'numberSymbol', route: `${root}cognitive/number-symbol`, title: "Number Symbol", exitConfirm: true, exitDispatch: quitNs },
  wordTheme: { key:'wordTheme', route: `${root}cognitive/word-theme`, title: "Word Theme", exitConfirm: true, exitDispatch: quitWt },
  cardioExercises: { key:'cardioExercises', route: `${root}cardio`, title: "Cardio Exercises", exitConfirm: true, exitDispatch: quitCe },
  cardioExercisesManualEntry: { key:'cardioExercisesManualEntry', route: `${root}cardio/manual-entry`, title: "Cardio Exercises", exitConfirm: true },  
  centeredBreathing: { key:'centeredBreathing', route: `${root}centered-breathing`, title: "Centered Breathing", exitConfirm: true, exitDispatch: quitCb },
  centeredBreathingManualEntry: { key:'centeredBreathingManualEntry', route: `${root}centered-breathing/manual-entry`, title: "Centered Breathing", exitConfirm: true },
  m5Test: { key:'m5Test', route: `${root}m5-test`, title: "Mood-5® (M5®)", exitConfirm: true },
  userSettings: { key:'userSettings', route: `${root}settings`, title: "", exitConfirm: true },
  userRegistration: { key:'userRegistration', route: `${root}register`},
  adminDashboard: { key:'adminDashboard', route: `${root}admin/dashboard`, title: "Engagement Reports", exitConfirm: true, portalAdmin: true },
}

export const getPageFromLocation = (location: RouterLocation<unknown>): IPage | undefined => {
  return find(pages, page => page.route === location.pathname)
}

export interface IButton {
  label: string
  action: () => void
  buttonType?: ButtonType | "danger" | "success"
  disabled?: boolean
  loading?: boolean
  cancel?: boolean
}

export const publicRoutes = filter(pages, page => !!page.public).map(page => page.route)
