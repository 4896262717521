import React from 'react'
import { ExerciseStatus } from '../../../constants/exercises'
import { findExercise } from '../cbHelper'
import { CbExercise } from '../cbSlice'
import CbExerciseChooser from './CbExerciseChooser'
import CbExerciseDetail from './CbExerciseDetail'

export interface ICbInProgressProps {
  status: ExerciseStatus
  exercises: CbExercise[]
  selectedExercise?: string
  pickExercise: (value: string) => void
  goBackToChoose: () => void
  finishExercise: (duration: number, startDate?: number) => void
  updateDuration: (duration: number) => void
  goToManualEntry: () => void
}

const CbInProgress = ({ status, exercises, selectedExercise, pickExercise, finishExercise, updateDuration, goBackToChoose, goToManualEntry }: ICbInProgressProps) => {
  
  if (status !== 'in-progress') { return null }

  const exercise =  findExercise(exercises, selectedExercise)

  const hasSelectedExercise = selectedExercise !== undefined && !!exercise && exercise.children.length === 0

  const exerciseCandidates = exercise ? exercise.children : exercises

  return (
    <>
      <CbExerciseChooser
        hidden={hasSelectedExercise}
        exercises={exerciseCandidates}  
        selectedExercise={exercise}       
        pickExercise={pickExercise}
        goBack={goBackToChoose}
        goToManualEntry={goToManualEntry}
      />
      <CbExerciseDetail 
        exercise={exercise}
        doneExercise={finishExercise}
        updateDuration={updateDuration}
        goBack={goBackToChoose}
      />
    </>
  )
}

export default CbInProgress