import React from 'react'
import Loadable from 'react-loadable'
import Loading from '../common/components/Loading'

const LoadingScreen = () => {
  return (<Loading />)
}

const LoadableAdminDashboard = Loadable({
  loader: () => import('./components/AdminDashboard'),
  loading: LoadingScreen,
})

const AdminDashboardConnect = () => {
  return (
    <LoadableAdminDashboard />
  )
}

export default AdminDashboardConnect
